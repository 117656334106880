import moment from 'moment';
import React from 'react';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { utcDateToString } from '@utils/helpers/timestamp.helpers';

export function Legend(props) {
    const points = [];
    let i = 0;
    const start = props.x < props.width * 0.6 ? props.x : props.x - 270;
    let isBoxplot = false;
    try {
        props.activePoints?.forEach((p) => {
            if (!isNullOrUndefined(p.name)) {
                const color = `rgb(${
                    p.style?.data?.stroke?.split('(')[1]?.split(',')[0] ??
                    p.style?.max?.stroke?.split('(')[1]?.split(',')[0]
                }, ${
                    p.style?.data?.stroke?.split('(')[1]?.split(',')[1] ??
                    p.style?.max?.stroke?.split('(')[1]?.split(',')[1]
                }, ${
                    p.style?.data?.stroke?.split('(')[1]?.split(',')[2] ??
                    p.style?.max?.stroke?.split('(')[1]?.split(',')[2]
                })`;
                if (isNullOrUndefined(p.style?.data?.stroke)) {
                    isBoxplot = true;
                    points.push(
                        <g>
                            <rect
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: color,
                                    fillOpacity: 1,
                                }}
                                rx="3"
                                ry="3"
                                height="5"
                                width="15"
                                y={50 + i * 105}
                                x={start + 20}
                            />
                            <text
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: '#fff',
                                    fillOpacity: 1,
                                }}
                                fontSize="smaller"
                                x={start + 50}
                                y={57 + i * 105}>
                                {p.name}
                            </text>
                            <text
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: '#fff',
                                    fillOpacity: 1,
                                }}
                                fontSize="smaller"
                                x={start + 50}
                                y={57 + i * 105 + 15}>
                                Max:
                            </text>
                            <text
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: '#fff',
                                    fillOpacity: 1,
                                }}
                                fontSize="smaller"
                                width="250"
                                textAnchor="end"
                                x={start + 250}
                                y={57 + i * 105 + 15}>
                                {p.max?.toFixed(2) ?? ''}
                            </text>
                            <text
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: '#fff',
                                    fillOpacity: 1,
                                }}
                                fontSize="smaller"
                                x={start + 50}
                                y={57 + i * 105 + 30}>
                                Q3:
                            </text>
                            <text
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: '#fff',
                                    fillOpacity: 1,
                                }}
                                fontSize="smaller"
                                width="250"
                                textAnchor="end"
                                x={start + 250}
                                y={57 + i * 105 + 30}>
                                {p.q3?.toFixed(2) ?? ''}
                            </text>
                            <text
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: '#fff',
                                    fillOpacity: 1,
                                }}
                                fontSize="smaller"
                                x={start + 50}
                                y={57 + i * 105 + 45}>
                                Median:
                            </text>
                            <text
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: '#fff',
                                    fillOpacity: 1,
                                }}
                                fontSize="smaller"
                                width="250"
                                textAnchor="end"
                                x={start + 250}
                                y={57 + i * 105 + 45}>
                                {p.median?.toFixed(2) ?? ''}
                            </text>
                            <text
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: '#fff',
                                    fillOpacity: 1,
                                }}
                                fontSize="smaller"
                                x={start + 50}
                                y={57 + i * 105 + 60}>
                                Q1:
                            </text>
                            <text
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: '#fff',
                                    fillOpacity: 1,
                                }}
                                fontSize="smaller"
                                width="250"
                                textAnchor="end"
                                x={start + 250}
                                y={57 + i * 105 + 60}>
                                {p.q1?.toFixed(2) ?? ''}
                            </text>
                            <text
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: '#fff',
                                    fillOpacity: 1,
                                }}
                                fontSize="smaller"
                                x={start + 50}
                                y={57 + i * 105 + 75}>
                                Min:
                            </text>
                            <text
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: '#fff',
                                    fillOpacity: 1,
                                }}
                                fontSize="smaller"
                                width="250"
                                textAnchor="end"
                                x={start + 250}
                                y={57 + i * 105 + 75}>
                                {p.min?.toFixed(2) ?? ''}
                            </text>
                        </g>
                    );
                } else {
                    points.push(
                        <g>
                            <rect
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: color,
                                    fillOpacity: 1,
                                }}
                                rx="3"
                                ry="3"
                                height="5"
                                width="15"
                                y={50 + i * 15}
                                x={start + 20}
                            />
                            <text
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: '#fff',
                                    fillOpacity: 1,
                                }}
                                fontSize="smaller"
                                x={start + 50}
                                y={57 + i * 15}>
                                {p.name}:
                            </text>
                            <text
                                style={{
                                    stroke: 'none',
                                    fillRule: 'nonzero',
                                    fill: '#fff',
                                    fillOpacity: 1,
                                }}
                                fontSize="smaller"
                                width="250"
                                textAnchor="end"
                                x={start + 250}
                                y={57 + i * 15}>
                                {p.y?.toFixed(2) ?? ''}
                            </text>
                        </g>
                    );
                }
                i++;
            }
        });
    } catch (error) {
        // ignore it
    }

    return !(props.activePoints[0]?.x instanceof Date) &&
        !moment.isMoment(props.activePoints[0]?.x) ? (
        <></>
    ) : (
        <g>
            <line
                x1={props.x}
                y1={0}
                x2={props.x}
                y2={props.height ?? 250}
                style={{ stroke: 'black', height: '100%', width: '100%' }}
                strokeWidth="2"
                strokeLinecap="round"
                strokeDasharray="1, 5"></line>
            <g>
                <rect
                    style={{
                        stroke: 'none',
                        fillRule: 'nonzero',
                        fill: 'rgb(20%,20%,20%)',
                        fillOpacity: 1,
                    }}
                    rx="3"
                    ry="3"
                    height={
                        isBoxplot
                            ? 50 + 105 * points.length
                            : 50 + 15 * points.length
                    }
                    width="250"
                    y="0"
                    x={start + 10}
                />
                <text
                    style={{
                        stroke: 'none',
                        fillRule: 'nonzero',
                        fill: '#fff',
                        fillOpacity: 1,
                    }}
                    textAnchor="middle"
                    width="250"
                    x={start + 135}
                    y="20">
                    {utcDateToString(props.activePoints[0]?.x)}
                </text>
                <g>
                    <rect
                        style={{
                            stroke: 'none',
                            fillRule: 'nonzero',
                            fill: 'rgb(100%,100%,100%)',
                            fillOpacity: 1,
                        }}
                        height="1"
                        width="250"
                        y="30"
                        x={start + 10}
                    />
                </g>
                {points}
            </g>
        </g>
    );
}
