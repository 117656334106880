import TemperatureApi from '@aurum/nucleus-client-api/dist/api/TemperatureApi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { convertStringUtcToLocaleString } from '@utils/helpers/timestamp.helpers';
import { CustomBreadcrumbs, FilesTable } from '@components';

export default function DiagnosticsSensorPage(props) {
    const [sensor, setSensor] = useState([]);
    const [showPage, setShowPage] = useState(false);
    const { id } = useParams();

    const { t } = useTranslation();

    useEffect(() => {
        if (!isNullOrUndefined(props.user)) {
            if (!userIsAtLeast(props.user, 'admin')) {
                window.location.href = getProperRoute(`sites`);
                return;
            }
            setShowPage(true);
            new TemperatureApi().temperatureGetMeasuringElementForDiagnostic(
                id,
                function (_, data, response) {
                    if (response.ok) {
                        if (isNullOrUndefined(data?.uuid)) {
                            window.location.href =
                                getProperRoute(`diagnostics`);
                        } else {
                            setSensor(response.body);
                        }
                    } else {
                        window.location.href = getProperRoute(`diagnostics`);
                    }
                }
            );
        }
    }, [props.user]);

    const gwRows = [
        [
            {
                link: null,
                text: sensor?.uuid,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        UUID: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {sensor?.uuid}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: sensor?.description,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_devices_devicepage_acid')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {sensor?.description}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: sensor?.name,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_devices_devicepage_name')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {sensor?.name}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text:
                    sensor?.status === 2
                        ? t('pages_devices_devicepage_active')
                        : sensor?.status === 1
                        ? t('pages_devices_devicepage_inactive')
                        : t('pages_devices_devicepage_unassigned'),
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_devices_devicepage_status')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {sensor?.status === 2
                                ? t('pages_devices_devicepage_active')
                                : sensor?.status === 1
                                ? t('pages_devices_devicepage_inactive')
                                : t('pages_devices_devicepage_unassigned')}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: isNullOrUndefined(sensor?.lastRX)
                    ? '-'
                    : convertStringUtcToLocaleString(
                          sensor.lastRX,
                          props.user?.timeZone ?? 'Europe/London'
                      ),
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_diagnostics_diagnosticssensorpage_lastrx')}:
                        &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {isNullOrUndefined(sensor?.lastRX)
                                ? '-'
                                : convertStringUtcToLocaleString(
                                      sensor.lastRX,
                                      props.user?.timeZone ?? 'Europe/London'
                                  )}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: isNullOrUndefined(sensor?.lastJoin)
                    ? '-'
                    : convertStringUtcToLocaleString(
                          sensor.lastJoin,
                          props.user?.timeZone ?? 'Europe/London'
                      ),
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_diagnostics_diagnosticssensorpage_lastjoin')}:
                        &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {isNullOrUndefined(sensor?.lastJoin)
                                ? '-'
                                : convertStringUtcToLocaleString(
                                      sensor.lastJoin,
                                      props.user?.timeZone ?? 'Europe/London'
                                  )}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: isNullOrUndefined(sensor?.measurementPointAssigned)
                    ? '-'
                    : sensor.measurementPointAssigned,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        Measurement Point: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {isNullOrUndefined(sensor?.measurementPointAssigned)
                                ? '-'
                                : sensor.measurementPointAssigned}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: isNullOrUndefined(sensor?.battery) ? '-' : sensor.battery,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_diagnostics_diagnosticspage_battery')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {isNullOrUndefined(sensor?.battery)
                                ? '-'
                                : sensor.battery}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: isNullOrUndefined(sensor?.value) ? '-' : sensor.value,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_diagnostics_diagnosticspage_value')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {isNullOrUndefined(sensor?.value)
                                ? '-'
                                : sensor.value}
                        </p>
                    </div>
                ),
            },
        ],
    ];

    const rssiRows = [];
    sensor?.signalQuality?.forEach((sq) => {
        rssiRows.push([
            { link: null, text: sq.gateway, value: sq.gateway },
            { link: null, text: sq.rssi, value: sq.rssi },
            { link: null, text: sq.datr, value: sq.datr },
        ]);
    });

    const healthAlerts = [];
    sensor?.healthAlerts?.forEach((ha) => {
        healthAlerts.push([{ link: null, text: ha, value: ha }]);
    });

    return showPage ? (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: getProperRoute(`admin/customers`),
                            text: 'Admin Area',
                        },
                        {
                            link: getProperRoute(`diagnostics`),
                            text: t(
                                'pages_diagnostics_diagnosticsgatewaypage_diagnostics'
                            ),
                        },
                        {
                            link: getProperRoute('/'),
                            text: sensor?.description,
                        },
                    ]}
                />
            </div>
            <hr />
            <br />
            <div className="row">
                <div className="col-md-6">
                    <div style={{ width: '100%', marginTop: '20px' }}>
                        <FilesTable
                            columns={[
                                t(
                                    'pages_diagnostics_diagnosticsgatewaypage_properties'
                                ),
                            ]}
                            rows={gwRows}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div style={{ width: '100%', marginTop: '20px' }}>
                        <FilesTable
                            columns={[
                                t(
                                    'pages_diagnostics_diagnosticssensorpage_gateway'
                                ),
                                'RSSI',
                                'Data Rate',
                            ]}
                            rows={rssiRows}
                        />
                    </div>
                    <div style={{ width: '100%', marginTop: '20px' }}>
                        <FilesTable
                            columns={[
                                t(
                                    'pages_diagnostics_diagnosticssensorpage_halerts'
                                ),
                            ]}
                            rows={healthAlerts}
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
}
