import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import {
    createContainer,
    VictoryArea,
    VictoryAxis,
    VictoryBrushContainer,
    VictoryChart,
    VictoryLine,
    VictoryScatter,
} from 'victory';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { Legend, MovableLegend, NoData } from '@components';

import './graphstyles.css';

export function LineGraph(props) {
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [hiddenSeries, setHiddenSeries] = useState(new Set());
    const [timestamps, setTimestamps] = useState([]);
    const [toAdd, setToAdd] = useState([]);
    const [allData, setAllData] = useState([]);
    const [visibleData, setVisibleData] = useState([]);
    const [absoluteMin, setAbsoluteMin] = useState(0);
    const [absoluteMax, setAbsoluteMax] = useState(0);
    const [absoluteMinX, setAbsoluteMinX] = useState(undefined);
    const [absoluteMaxX, setAbsoluteMaxX] = useState(undefined);
    const [minData, setMinData] = useState(0);
    const [maxData, setMaxData] = useState(0);
    const [maxX, setMaxX] = useState(undefined);
    const [minX, setMinX] = useState(undefined);

    const svgRef = useRef();
    const { width, height } = props;

    useEffect(() => {
        if (!isNullOrUndefined(props.series)) {
            setAllData(props.series);
            let min =
                Math.min(
                    ...[].concat.apply(
                        [],
                        props.series.map((dp) => dp.datapoints.map((d) => d.y))
                    )
                ) - 0.1;
            let max =
                Math.max(
                    ...[].concat.apply(
                        [],
                        props.series.map((dp) => dp.datapoints.map((d) => d.y))
                    )
                ) + 0.1;
            const maxX = new Date(
                Math.max.apply(
                    null,
                    [].concat.apply(
                        [],
                        props.series.map((dp) => dp.datapoints.map((d) => d.x))
                    )
                )
            );
            const minX = new Date(
                Math.min.apply(
                    null,
                    [].concat.apply(
                        [],
                        props.series.map((dp) => dp.datapoints.map((d) => d.x))
                    )
                )
            );
            setMaxX(maxX);
            setAbsoluteMaxX(maxX);
            setMinX(minX);
            setAbsoluteMinX(minX);
            max =
                max === Number.POSITIVE_INFINITY ||
                max === Number.NEGATIVE_INFINITY
                    ? 1
                    : max;
            min =
                min === Number.POSITIVE_INFINITY ||
                min === Number.NEGATIVE_INFINITY
                    ? 0
                    : min;
            setMinData(min);
            setAbsoluteMin(min);
            setMaxData(max);
            setAbsoluteMax(max);
            setVisibleData(props.series);
            const ta = [];
            let index = 0;
            props.series.forEach((x) => {
                ta.push({ idx: index++, dt: new Date() });
            });
            setToAdd(ta);
        }
    }, [props.series]);

    const handleBrush = (domain) => {
        const visible = [];
        allData.forEach((d, idx) => {
            visible.push({
                name: d.name,
                color: d.color,
                datapoints: d.datapoints?.filter(
                    (dp) => dp.x >= domain.x[0] && dp.x <= domain.x[1]
                ),
            });
        });
        setMinData(
            Math.min(
                ...[].concat.apply(
                    [],
                    visible.map((dp) => dp.datapoints.map((d) => d.y))
                )
            ) - 0.1
        );
        setMaxData(
            Math.max(
                ...[].concat.apply(
                    [],
                    visible.map((dp) => dp.datapoints.map((d) => d.y))
                )
            ) + 0.1
        );

        setMaxX(
            new Date(
                Math.max.apply(
                    null,
                    [].concat.apply(
                        [],
                        visible.map((dp) => dp.datapoints.map((d) => d.x))
                    )
                )
            )
        );
        setMinX(
            new Date(
                Math.min.apply(
                    null,
                    [].concat.apply(
                        [],
                        visible.map((dp) => dp.datapoints.map((d) => d.x))
                    )
                )
            )
        );

        setVisibleData(visible);
        setSelectedDomain(domain);
    };

    const buildEvents = () => {
        return allData.map((_, idx) => {
            return {
                childName: ['legend'],
                target: 'data',
                eventKey: String(idx),
                eventHandlers: {
                    onClick: () => {
                        return [
                            {
                                childName: ['area-' + idx],
                                target: 'data',
                                eventKey: 'all',
                                mutation: () => {
                                    // if we want to hide the item
                                    if (
                                        !hiddenSeries.has(idx) &&
                                        toAdd.find((x) => x.idx === idx) !==
                                            undefined &&
                                        new Date() -
                                            toAdd.find((x) => x.idx === idx)
                                                .dt >
                                            300
                                    ) {
                                        // Was not already hidden => add to set
                                        hiddenSeries.add(idx);
                                        const ts = toAdd
                                            .map((item) => item.idx)
                                            .indexOf(idx);
                                        ~ts && toAdd.splice(ts, 1);
                                        setToAdd(toAdd);
                                        setTimestamps([
                                            ...timestamps,
                                            { idx: idx, dt: new Date() },
                                        ]);
                                        // if we want to reveal the item
                                    } else if (
                                        timestamps.find(
                                            (x) => x.idx === idx
                                        ) !== undefined &&
                                        new Date() -
                                            timestamps.find(
                                                (x) => x.idx === idx
                                            ).dt >
                                            300
                                    ) {
                                        hiddenSeries.delete(idx);
                                        const ts = timestamps
                                            .map((item) => item.idx)
                                            .indexOf(idx);
                                        ~ts && timestamps.splice(ts, 1);
                                        setTimestamps(timestamps);
                                        setToAdd([
                                            ...toAdd,
                                            { idx: idx, dt: new Date() },
                                        ]);
                                    }

                                    setHiddenSeries(new Set(hiddenSeries));
                                    return null;
                                },
                            },
                        ];
                    },
                    onMouseOver: () => {
                        return [
                            {
                                childName: ['area-' + idx],
                                target: 'data',
                                eventKey: 'all',
                                mutation: (props) => {
                                    return {
                                        style: {
                                            ...props.style,
                                            strokeWidth: 4,
                                            fillOpacity: 0.5,
                                        },
                                    };
                                },
                            },
                        ];
                    },
                    onMouseOut: () => {
                        return [
                            {
                                childName: ['area-' + idx],
                                target: 'data',
                                eventKey: 'all',
                                mutation: () => {
                                    return null;
                                },
                            },
                        ];
                    },
                },
            };
        });
    };

    const toVictoryData = (line, name = true) => {
        const datapoints = line.datapoints;
        const filtered = datapoints?.sort(function (a, b) {
            return a.x - b.x;
        });

        let domain = selectedDomain;
        if (isNullOrUndefined(selectedDomain) && filtered?.length > 0) {
            domain = { x: [filtered[0].x, filtered[filtered.length - 1].x] };
        }

        const intervalLength =
            !isNullOrUndefined(domain) &&
            domain.x[0].getTime &&
            domain.x[1].getTime
                ? Math.ceil(
                      (domain.x[1].getTime() - domain.x[0].getTime()) /
                          (1000 * 3600)
                  )
                : 1;
        let precision =
            props?.measurement === 'chloride' ? 1 * 60 * 1000 : 10 * 60 * 1000;
        if (intervalLength > 24 * 90) {
            precision = 60 * 24 * 60 * 1000;
        } else if (intervalLength > 24 * 30) {
            precision = 60 * 12 * 60 * 1000;
        } else if (intervalLength > 24 * 15) {
            precision = 360 * 60 * 1000;
        } else if (intervalLength > 24 * 7) {
            precision = 120 * 60 * 1000;
        } else if (intervalLength > 24 * 4) {
            precision = 40 * 60 * 1000;
        } else if (intervalLength > 24 * 2) {
            precision = 20 * 60 * 1000;
        }
        const groupedResults = _.groupBy(filtered, (item) =>
            moment(Math.floor(item.x / precision) * precision)
        );
        const newdata = [];
        Object.entries(groupedResults)?.forEach((entry) => {
            const yvalues = entry[1]?.map((e) => e.y);
            newdata.push({
                x: new Date(entry[0]),
                y:
                    yvalues?.reduce(function (sum, value) {
                        return sum + value;
                    }, 0) / yvalues.length,
            });
        });
        if (name) {
            return newdata?.map((dp) => ({
                name: line.name,
                x: dp.x,
                y: dp.y,
            }));
        }
        return newdata?.map((dp) => ({ x: dp.x, y: dp.y }));
    };

    const toVictoryDataRaw = (line, name = true) => {
        line.datapoints?.sort(function (a, b) {
            return a.x - b.x;
        });
        if (name) {
            return line.datapoints?.map((dp) => ({
                name: line.name,
                x: dp.x,
                y: dp.y,
            }));
        }
        return line.datapoints?.map((dp) => ({ x: dp.x, y: dp.y }));
    };

    const toVictoryAll = (line) => {
        line.datapoints.sort(function (a, b) {
            return a.x - b.x;
        });
        const k = Math.ceil(line.datapoints.length / 30);
        return line.datapoints
            ?.filter((dp, idx) => idx % k === 0)
            ?.map((dp) => ({ name: line.name, x: dp.x, y: dp.y }));
    };

    const onGraphRef = (gRef) => {
        props.onGraphRef && props.onGraphRef(gRef);
    };

    const mouseEnterGraph = () => {
        document.body.classList.add('prevent-scroll');
    };

    const mouseLeaveGraph = () => {
        document.body.classList.remove('prevent-scroll');
    };

    const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi');
    return allData?.length === 0 ? (
        <NoData />
    ) : (
        <>
            <div
                onMouseEnter={() => mouseEnterGraph()}
                onMouseLeave={() => mouseLeaveGraph()}
                className="chart-wrapper">
                <VictoryChart
                    style={{ zIndex: 99999 }}
                    width={width}
                    domain={{ y: [minData ?? 0, maxData ?? 0] }}
                    height={height * 0.7}
                    scale={{ x: 'time' }}
                    padding={{ top: 10, left: 50, right: 50, bottom: 30 }}
                    containerComponent={
                        <VictoryZoomVoronoiContainer
                            labels={({ datum }) =>
                                `${datum.name}:   ${datum.y} ºC`
                            }
                            voronoiDimension="x"
                            labelComponent={
                                <Legend width={width} heigth={height * 0.9} />
                            }
                            containerRef={(ref) => onGraphRef(ref)}
                            responsive={false}
                            zoomDimension="x"
                            zoomDomain={{
                                x: [minX ?? new Date(), maxX ?? new Date()],
                                y: [minData ?? 0, maxData ?? 1],
                            }}
                        />
                    }>
                    <VictoryAxis
                        tickFormat={(t) => moment(t).format('DD.MM HH:mm')}
                        style={{
                            grid: { stroke: '#818e99', strokeWidth: 0.5 },
                        }}
                    />
                    <VictoryAxis
                        style={{
                            grid: { stroke: '#818e99', strokeWidth: 0.5 },
                        }}
                        dependentAxis
                    />
                    {visibleData.map((s, idx) => {
                        if (hiddenSeries.has(idx)) {
                            return undefined;
                        }
                        return props.customStyle?.fill ? (
                            <VictoryArea
                                key={'area-' + idx}
                                name={'area-' + idx}
                                data={
                                    props.customStyle?.usePoints
                                        ? toVictoryDataRaw(s, false)
                                        : toVictoryData(s)
                                }
                                interpolation={
                                    props.customStyle?.interpolation ?? 'linear'
                                }
                                style={{
                                    data: {
                                        fill: s.color,
                                        fillOpacity: 0.2,
                                        stroke: s.color,
                                        strokeWidth:
                                            props.customStyle?.strokeWidth ?? 2,
                                    },
                                    labels: { fill: s.color },
                                }}
                            />
                        ) : (
                            <VictoryLine
                                key={'area-' + idx}
                                name={'area-' + idx}
                                data={
                                    props.customStyle?.usePoints
                                        ? toVictoryDataRaw(s, false)
                                        : toVictoryData(s)
                                }
                                interpolation={
                                    props.customStyle?.interpolation ?? 'linear'
                                }
                                groupComponent={<g />}
                                style={{
                                    data: {
                                        fillOpacity: 0.2,
                                        stroke: s.color,
                                        strokeWidth:
                                            props.customStyle?.strokeWidth ?? 2,
                                    },
                                    labels: { fill: s.color },
                                }}
                            />
                        );
                    })}
                    {props.customStyle?.usePoints &&
                        visibleData.map((s, idx) => {
                            return (
                                <VictoryScatter
                                    data={toVictoryDataRaw(s)}
                                    size={props.customStyle?.pointWidth ?? 2}
                                    style={{
                                        data: {
                                            fill: s.color,
                                            stroke: s.color,
                                        },
                                        labels: { fill: s.color },
                                    }}
                                />
                            );
                        })}
                    {props.customStyle.hline &&
                    (minData <= parseFloat(props.customStyle.hlineValue) ||
                        maxData >= parseFloat(props.customStyle.hlineValue)) ? (
                        <VictoryLine
                            domain={{
                                x: [minX ?? new Date(), maxX ?? new Date()],
                                y: [
                                    parseFloat(props.customStyle.hlineValue),
                                    parseFloat(props.customStyle.hlineValue),
                                ],
                            }}
                            style={{
                                data: {
                                    stroke:
                                        props.customStyle?.hlineColor ??
                                        '#000000',
                                    strokeWidth:
                                        props.customStyle?.strokeWidth ?? 2,
                                },
                            }}
                            y={() => parseFloat(props.customStyle.hlineValue)}
                        />
                    ) : (
                        <></>
                    )}
                    {props.customStyle.hline2 &&
                    (minData <= parseFloat(props.customStyle.hline2Value) ||
                        maxData >=
                            parseFloat(props.customStyle.hline2Value)) ? (
                        <VictoryLine
                            domain={{
                                x: [minX ?? new Date(), maxX ?? new Date()],
                                y: [
                                    parseFloat(props.customStyle.hline2Value),
                                    parseFloat(props.customStyle.hline2Value),
                                ],
                            }}
                            style={{
                                data: {
                                    stroke:
                                        props.customStyle?.hline2Color ??
                                        '#000000',
                                    strokeWidth:
                                        props.customStyle?.strokeWidth ?? 2,
                                },
                            }}
                            y={() => parseFloat(props.customStyle.hline2Value)}
                        />
                    ) : (
                        <></>
                    )}
                </VictoryChart>
            </div>
            <VictoryChart
                style={{ zIndex: 0 }}
                domain={{
                    x: [absoluteMinX ?? new Date(), absoluteMaxX ?? new Date()],
                    y: [absoluteMin ?? 0, absoluteMax ?? 0],
                }}
                width={width}
                height={height * 0.3}
                scale={{ x: 'time' }}
                padding={{ top: 0, left: 50, right: 50, bottom: 30 }}
                containerComponent={
                    <VictoryBrushContainer
                        responsive={false}
                        brushDimension="x"
                        brushDomain={{
                            x: [
                                absoluteMinX ?? new Date(),
                                absoluteMaxX ?? new Date(),
                            ],
                            y: [absoluteMin ?? 0, absoluteMax ?? 1],
                        }}
                        onBrushDomainChange={handleBrush}
                    />
                }>
                <VictoryAxis tickValues={[]} tickFormat={(x) => {}} />

                {allData.map((s, idx) => {
                    if (hiddenSeries.has(idx)) {
                        return undefined;
                    }
                    return (
                        <VictoryLine
                            groupComponent={<g />}
                            key={'area-' + idx}
                            name={'area-' + idx}
                            data={toVictoryAll(s)}
                            style={{
                                data: {
                                    fillOpacity: 0.2,
                                    stroke: s.color,
                                    strokeWidth:
                                        props.customStyle?.strokeWidth ?? 2,
                                },
                            }}
                        />
                    );
                })}
            </VictoryChart>
            {props.showLegend ? (
                <MovableLegend
                    series={props.series}
                    allData={allData}
                    hiddenSeries={hiddenSeries}
                    buildEvents={buildEvents}
                />
            ) : (
                <></>
            )}
        </>
    );
}
