import { GroupApi } from '@aurum/nucleus-client-api';
import AccountApi from '@aurum/nucleus-client-api/dist/api/AccountApi';
import SiteApi from '@aurum/nucleus-client-api/dist/api/SiteApi';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';

import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { alphabetPosition } from '@utils/helpers/text.helpers';
import { dateToString } from '@utils/helpers/timestamp.helpers';
import { defaultDateTime } from '@utils/constants/timestamp.constants';
import { getAvatarColor } from '@utils/helpers/colors.helpers';
import { CustomBreadcrumbs, FilesTable, MoreSettingsMenu } from '@components';
import ResetPasswordModal from '@views/users/reset-password/ResetPasswordModal';

const useStyles = makeStyles(() => ({
    defaultAvatar: {
        borderRadius: '20%',
        border: 0,
        height: '60px',
        width: '60px',
        textAlign: 'center',
        margin: 0,
        alignSelf: 'center',
        backgroundColor: (props) =>
            isNullOrUndefined(props.user?.email)
                ? getAvatarColor(1)
                : getAvatarColor(alphabetPosition(props.user.email[0])),
        fontSize: '30px',
        lineHeight: '60px',
        marginLeft: '10px',
        marginTop: '10px',
    },
}));

export default function UserPage(props) {
    const [user, setUser] = useState(null);
    const [groups, setGroups] = useState([]);
    const [sites, setSites] = useState([]);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const { userId } = useParams();
    const styles = useStyles({ user: user });

    const { t } = useTranslation();

    useEffect(() => {
        new AccountApi().accountGetUser(userId, function (_, data, response) {
            if (response.ok) {
                setUser(response.body);
            } else {
                window.location.href = getProperRoute(`users`);
            }
        });
        new GroupApi().groupGetGroups(function (_, data, response) {
            if (response.ok) {
                setGroups(data);
            }
        });
        new SiteApi().siteGetSites(1, function (_, data, response) {
            if (response.ok) {
                setSites(data);
            }
        });
    }, []);

    const lockUser = (isLocked) => {
        if (isNullOrUndefined(user)) {
            return;
        }
        new AccountApi().accountLockUser(
            user.id,
            { lockUser: !isLocked },
            function (_, data, response) {
                if (response.ok) {
                    window.location.reload();
                } else if (response.unauthorized) {
                    swal({
                        text: `You're not authorized to ${
                            isLocked ? 'unlock' : 'lock'
                        } this user`,
                        icon: 'error',
                    });
                }
            }
        );
    };

    const deleteUser = () => {
        if (isNullOrUndefined(user)) {
            return;
        }
        swal({
            title: t('swal_sure'),
            text: t('swal_cannot_recover_resource'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult) {
                new AccountApi().accountDelete(
                    user.id,
                    { password: null },
                    function (_, data, response) {
                        if (response.ok) {
                            window.location.reload();
                        } else if (response.unauthorized) {
                            swal({
                                text: `You're not authorized to delete this user`,
                                icon: 'error',
                            });
                        }
                    }
                );
            }
        });
    };

    const resetPassword = (password, confirmPassword) => {
        // cannot reset password for himself. Use profile page for that.
        if (props.user.id === user.id) {
            return;
        }

        new AccountApi().accountChangePassword(
            user.id,
            {
                oldPassword: 'null',
                newPassword: password,
                confirm: confirmPassword,
            },
            function (_, data, response) {
                if (response.ok) {
                    swal({
                        text: `The password has been successfully reset`,
                        icon: 'ok',
                    });
                } else {
                    swal({
                        text: t(pages_profile_profilepage_cannotchange),
                        icon: 'error',
                    });
                }
            }
        );
    };

    const rows = [
        [
            {
                link: null,
                text: user?.userName[0]?.toUpperCase(),
                value: (
                    <div className={styles.defaultAvatar}>
                        {user?.userName[0]?.toUpperCase()}
                    </div>
                ),
            },
        ],
    ];
    const createdDate =
        isNullOrUndefined(user?.createdAt) ||
        user?.createdAt === defaultDateTime
            ? t('pages_devices_devicepage_never')
            : dateToString(new Date(user.createdAt), props.user?.timeZone);
    const lastLoginDate =
        isNullOrUndefined(user?.lastLogin) ||
        user?.lastLogin === defaultDateTime
            ? t('pages_devices_devicepage_never')
            : dateToString(new Date(user.lastLogin), props.user?.timeZone);
    const diffDays = isNullOrUndefined(user?.lockedUntil)
        ? 1
        : Math.ceil(
              Math.abs(new Date(user.lockedUntil) - new Date()) /
                  (1000 * 60 * 60 * 24)
          );
    const locked = user?.locked && diffDays > 365;

    const profileRows = [
        [
            {
                link: null,
                text: user?.userName,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_profile_profilepage_username')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {user?.userName}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: user?.email,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_profile_profilepage_email')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {user?.email}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: user?.id,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        ID: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {user?.id}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: createdDate,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_users_userpage_membersince')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {createdDate}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: locked ? t('swal_yes') : t('swal_no'),
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        Locked: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {locked ? t('swal_yes') : t('swal_no')}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: lastLoginDate,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_users_userpage_lastsignin')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {lastLoginDate}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: isNullOrUndefined(user?.timeZone)
                    ? t('pages_users_userentrytable_unknown')
                    : user.timeZone,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_profile_profilepage_tz')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {isNullOrUndefined(user?.timeZone)
                                ? t('pages_users_userentrytable_unknown')
                                : user.timeZone}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: isNullOrUndefined(user?.language)
                    ? t('pages_users_userentrytable_unknown')
                    : user.language,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_profile_profilepage_lang')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {isNullOrUndefined(user?.language)
                                ? t('pages_users_userentrytable_unknown')
                                : user.language}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: isNullOrUndefined(user?.createdBy)
                    ? '-'
                    : user.createdBy.userName,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_users_userpage_createdby')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {isNullOrUndefined(user?.createdBy)
                                ? '-'
                                : user.createdBy.userName}
                        </p>
                    </div>
                ),
            },
        ],
    ];

    const groupsRecursive = (rows, group) => {
        const subgroups =
            groups?.filter((g) => g.parent?.id === group.id) ?? [];
        let total = 0;
        subgroups.forEach((g) => {
            const newTotal = groupsRecursive(rows, g) + g.sites?.length ?? 0;
            total = total + newTotal;
            rows.push([
                {
                    link: null,
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            <a href={getProperRoute(`groups/${g.id}/settings`)}>
                                {g.name}
                            </a>{' '}
                            &nbsp; - {t('pages_users_userpage_accessto')}{' '}
                            {newTotal} {t('pages_users_userpage_projects')}
                        </div>
                    ),
                },
            ]);
        });
        return total;
    };

    const groupRows = [];
    // add all subgroups as groups that can be accessed by the user
    user?.groups?.forEach((gr) => {
        let projects = 0;
        projects = groupsRecursive(groupRows, gr);
        projects +=
            groups?.filter((g) => g.id === gr.id)[0]?.sites?.length ?? 0;
        groupRows.push([
            {
                link: null,
                text:
                    gr.id === 1
                        ? `${t(
                              'pages_users_userpage_accessto'
                          )} ${projects} ${t('pages_users_userpage_projects')}`
                        : `${gr.name} - ${t(
                              'pages_users_userpage_accessto'
                          )} ${projects} ${t('pages_users_userpage_projects')}`,
                value:
                    gr.id === 1 ? (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            {' '}
                            {t('pages_users_userpage_accessto')} {projects}{' '}
                            {t('pages_users_userpage_projects')}
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            {' '}
                            <a
                                href={getProperRoute(
                                    `groups/${gr.id}/settings`
                                )}>
                                {gr.name}
                            </a>{' '}
                            &nbsp; - {t('pages_users_userpage_accessto')}{' '}
                            {projects} {t('pages_users_userpage_projects')}
                        </div>
                    ),
            },
        ]);
    });
    // add projects that are part of the root group
    user?.sites?.forEach((s) => {
        const site = sites?.filter((si) => si.id === s.id)[0];
        if (!isNullOrUndefined(site)) {
            groupRows.push([
                {
                    link: null,
                    text: `${s.name}`,
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            <a
                                href={getProperRoute(
                                    `groups/1/sites/${s.id}/settings`
                                )}>
                                {s.name}
                            </a>
                        </div>
                    ),
                },
            ]);
        }
    });

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <div style={{ marginLeft: '5%', width: '85%' }}>
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: getProperRoute(`users`),
                            text: t('pages_users_userpage_users'),
                        },
                        { link: getProperRoute('/'), text: user?.userName },
                    ]}
                />
                <MoreSettingsMenu
                    disabled={
                        !userIsAtLeast(props.user, 'maintainer') ||
                        props.user?.id === user?.id
                    }>
                    {userIsAtLeast(props.user, 'maintainer') &&
                    props.user?.id !== user?.id ? (
                        <>
                            {userIsAtLeast(props.user, 'admin') ? (
                                <>
                                    <MenuItem onClick={() => lockUser(locked)}>
                                        {locked ? 'Unlock User' : 'Lock User'}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            setResetPasswordModal(true)
                                        }>
                                        Reset Password
                                    </MenuItem>
                                </>
                            ) : (
                                <></>
                            )}
                            <MenuItem onClick={() => deleteUser()}>
                                Delete User
                            </MenuItem>
                        </>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <hr />
            <br />
            <div
                style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                }}>
                <div style={{ width: isMobile ? '100%' : '50%' }}>
                    <div style={{ width: '100%' }}>
                        <FilesTable columns={[user?.userName]} rows={rows} />
                    </div>
                    <div style={{ width: '100%', marginTop: '20px' }}>
                        <FilesTable
                            columns={[t('pages_users_userpage_profile')]}
                            rows={profileRows}
                        />
                    </div>
                </div>
                <div
                    style={{
                        width: isMobile ? '100%' : '50%',
                        marginLeft: isMobile ? '0px' : '20px',
                        marginTop: isMobile ? '20px' : '0px',
                    }}>
                    <div style={{ width: '100%' }}>
                        <FilesTable
                            columns={[t('pages_users_userpage_gp')]}
                            rows={groupRows}
                        />
                    </div>
                </div>
            </div>
            <ResetPasswordModal
                open={resetPasswordModal}
                handleClose={() => setResetPasswordModal(false)}
                confirm={(password, confirmPassword) =>
                    resetPassword(password, confirmPassword)
                }
            />
        </div>
    );
}
