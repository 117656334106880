import { SingleSelect, TextEdit } from '@components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function CreateSiteMainPageModal(props) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [group, setGroup] = useState(1);
    const [type, setType] = useState('temperature');
    const [errorMsg, setErrorMsg] = useState(null);

    const { t } = useTranslation();

    const nameChanged = (txt) => {
        setName(txt);
    };

    const descriptionChanged = (txt) => {
        setDescription(txt);
    };

    const groupChanged = (gr) => {
        setGroup(gr);
    };

    const typeChanged = (tp) => {
        setType(tp);
    };

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onOkClick();
        }
    };

    const onOkClick = () => {
        if (!isNullOrUndefined(name) && name.trim() !== '') {
            props.confirm(name, description, group, type);
        } else {
            setErrorMsg(
                t('pages_sitesettings_createsitemainpagemodal_errortxt')
            );
        }
    };

    const groupsToAttach = [];
    props.groups?.forEach((group) => {
        if (group.id !== 1) {
            groupsToAttach.push({ value: group.id, description: group.name });
        }
    });
    if (groupsToAttach.length > 0 && group === 1) {
        setGroup(groupsToAttach[0].value);
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={() => props.handleClose && props.handleClose()}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {t('pages_sitesettings_createsitemainpagemodal_new')}
            </DialogTitle>
            <DialogContent>
                {isNullOrUndefined(errorMsg) ? (
                    <></>
                ) : (
                    <div>
                        <Alert
                            data-cy="new-project-alert"
                            onClose={() => setErrorMsg(null)}
                            severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    <TextEdit
                        datacy="new-project-name"
                        label={t(
                            'pages_sitesettings_createsitemainpagemodal_namelbl'
                        )}
                        placeholder={t('pages_groups_creategroupmodal_name')}
                        value={name}
                        onChange={nameChanged}
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <TextEdit
                        datacy="new-project-descr"
                        label={t(
                            'pages_sitesettings_createsitemainpagemodal_descrlbl'
                        )}
                        placeholder={t(
                            'pages_sitesettings_createsitemainpagemodal_descr'
                        )}
                        value={description}
                        onChange={descriptionChanged}
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <SingleSelect
                        datacy="new-project-type"
                        title={t('pages_devices_devicepage_type')}
                        data={[
                            {
                                value: 'temperature',
                                description: t(
                                    'pages_addgraph_settingstab_temperature'
                                ),
                            },
                            {
                                value: 'chloride',
                                description: t(
                                    'pages_addgraph_settingstab_chlorine'
                                ),
                            },
                            {
                                value: 'filter',
                                description: 'AquaFilter',
                            },
                            {
                                value: 'flowrate',
                                description: t(
                                    'pages_addgraph_settingstab_flowrate'
                                ),
                            },
                        ]}
                        onChange={typeChanged}
                    />
                </div>
                {groupsToAttach.length > 0 ? (
                    <div style={{ marginTop: '20px' }}>
                        <SingleSelect
                            title={t(
                                'pages_sitesettings_createsitemainpagemodal_attach'
                            )}
                            data={groupsToAttach}
                            onChange={groupChanged}
                        />{' '}
                    </div>
                ) : (
                    <></>
                )}
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    data-cy="new-project-cancel"
                    aria-label="Cancel"
                    onClick={() => props.handleClose && props.handleClose()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    data-cy="new-project-ok"
                    aria-label="Ok"
                    onClick={() => props.confirm && onOkClick()}
                    color="primary">
                    {t('modal_create')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
