import { GroupApi } from '@aurum/nucleus-client-api';
import GatewayApi from '@aurum/nucleus-client-api/dist/api/GatewayApi';
import TemperatureApi from '@aurum/nucleus-client-api/dist/api/TemperatureApi';
import { makeStyles, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { FilesTable, MoreSettingsMenu } from '@components';
import CreateGatewayModal from '@views/devices/create-gateway/CreateGatewayModal';
import CreateSensorModal from '@views/devices/create-sensor/CreateSensorModal';
import DeviceTableEntry from '@views/devices/device-entry/DeviceTableEntry';

const useStyles = makeStyles(() => ({
    link: {
        color: 'inherit',
        '&:hover': {
            color: 'inherit',
            textDecoration: 'none',
        },
    },
}));

export default function DevicesPage(props) {
    const [gateways, setGateways] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [sensorCreationError, setSensorCreationError] = useState(null);
    const [groups, setGroups] = useState([]);
    const [createGatewayModal, setCreateGatewayModal] = useState(false);
    const [createSensorModal, setCreateSensorModal] = useState(false);

    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        new GatewayApi().gatewayGetAllGateways(function (_, data, response) {
            if (response.ok) {
                setGateways(data);
            }
        });
        new TemperatureApi().temperatureGetSensors(function (
            _,
            data,
            response
        ) {
            if (response.ok) {
                setSensors(data);
            }
        });
        new GroupApi().groupGetGroups(function (_, data, response) {
            if (response.ok) {
                setGroups(data);
            }
        });
    }, []);

    const confirmGatewayCreation = (uuid, name) => {
        if (
            !isNullOrUndefined(name) &&
            name.trim() !== '' &&
            !isNullOrUndefined(uuid) &&
            uuid.trim() !== ''
        ) {
            new GatewayApi().gatewayAddGateway(
                uuid,
                { uuid: uuid, description: name, name: name },
                function (_, data, response) {
                    window.location.href = window.location.href;
                }
            );
        }
    };

    const confirmSensorCreation = (uuid, name, type) => {
        if (
            !isNullOrUndefined(name) &&
            name.trim() !== '' &&
            !isNullOrUndefined(uuid) &&
            uuid.trim() !== ''
        ) {
            new TemperatureApi().temperatureAddSensor(
                uuid,
                { uuid: uuid, description: name, name: name, type: type },
                function (_, data, response) {
                    if (response.ok) {
                        setSensorCreationError(null);
                    } else if (response.statusCode === 400) {
                        setSensorCreationError(response.body);
                    } else if (response.statusCode === 500) {
                        setSensorCreationError(
                            t('pages_devices_devicespage_formaterror')
                        );
                    }
                }
            );
        }
    };

    const rows = [];
    gateways?.forEach((gw) => {
        rows.push([
            {
                link: getProperRoute(`devices/gateways/${gw.uuid}`),
                text: gw.name,
                value: <DeviceTableEntry gateway name={gw.name} />,
            },
            {
                link: isNullOrUndefined(gw.groupId)
                    ? null
                    : getProperRoute(`groups/${gw.groupId}/settings`),
                text: groups?.filter((g) => g.id === gw.groupId)[0]?.name,
                value: groups?.filter((g) => g.id === gw.groupId)[0]?.name,
            },
        ]);
    });
    sensors?.forEach((sensor) => {
        rows.push([
            {
                link: getProperRoute(`devices/sensors/${sensor.uuid}`),
                text: sensor.name,
                value: <DeviceTableEntry sensor name={sensor.name} />,
            },
            {
                link: isNullOrUndefined(sensor.groupId)
                    ? null
                    : getProperRoute(`groups/${sensor.groupId}/settings`),
                text: groups?.filter((g) => g.id === sensor.groupId)[0]?.name,
                value: groups?.filter((g) => g.id === sensor.groupId)[0]?.name,
            },
        ]);
    });

    const sensorTypes = [
        {
            value: 'temperature',
            description: t('pages_devices_devicespage_temperature'),
        },
        {
            value: 'chlorine',
            description: t('pages_devices_devicespage_chlorine'),
        },
        {
            value: 'digitalInput',
            description: t('pages_devices_devicespage_dinput'),
        },
        {
            value: 'flowrate',
            description: t('pages_addgraph_settingstab_flowrate'),
        },
    ];

    return (
        <div style={{ marginLeft: '5%', width: '85%' }}>
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <span style={{ width: 'inherit', justifySelf: 'flex-start' }}>
                    {t('pages_devices_devicepage_devices')}
                </span>
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'admin')}>
                    {userIsAtLeast(props.user, 'admin') ? (
                        <>
                            <MenuItem
                                onClick={() => setCreateGatewayModal(true)}>
                                {t('pages_devices_devicespage_creategw')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => setCreateSensorModal(true)}>
                                {t('pages_devices_devicespage_createsensor')}
                            </MenuItem>
                        </>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <hr />
            <br />
            <FilesTable
                responsive
                pagination
                search
                columns={[
                    t('common_filestable_namecol'),
                    t('pages_devices_devicespage_assigned'),
                ]}
                rows={rows}
            />
            <CreateGatewayModal
                open={createGatewayModal}
                handleClose={() => setCreateGatewayModal(false)}
                confirm={confirmGatewayCreation}
            />
            <CreateSensorModal
                creationError={sensorCreationError}
                open={createSensorModal}
                handleClose={() => setCreateSensorModal(false)}
                confirm={confirmSensorCreation}
                dataType={sensorTypes}
            />
        </div>
    );
}
