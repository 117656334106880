import DashboardApi from '@aurum/nucleus-client-api/dist/api/DashboardApi';
import TemperatureApi from '@aurum/nucleus-client-api/dist/api/TemperatureApi';
import { TemperatureAppGraphContainer } from '@components';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { useWindowSize } from '@utils/hooks/useWindowSize';
import React, { useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

const Wrapper = React.forwardRef((props, ref) => {
    let newChildren = React.Children.map(props.children, function (child) {
        return React.cloneElement(child, { forceRender: props.forceRender });
    });
    return (
        <div ref={ref} {...props}>
            {newChildren}
        </div>
    );
});

export function TemperatureAppDashboard(props) {
    const [width] = useWindowSize();
    const [graphData, setGraphData] = useState([]);
    const [forceRender, setForceRender] = useState(0);
    const [lastRefresh, setLastRefresh] = useState(-1);

    const ref = React.createRef();
    const { groupId, id, dbId } = useParams();

    const onLayoutChange = (layout) => {
        saveLayout(layout);
    };

    useEffect(() => {
        if (props.refreshCounter === lastRefresh) {
            return;
        }
        setLastRefresh(props.refreshCounter);

        let from = props.from;
        let to = props.to;
        from.setMilliseconds(0);
        to.setMilliseconds(0);
        new TemperatureApi().temperatureGetMeasurementsFromMps(
            parseInt(id, 10),
            {
                windowPeriod: props.windowPeriod,
                startTimestamp: from,
                endTimestamp: to,
            },
            function (_, data, response) {
                fetchingProgress(-1);
                setGraphData(data);
            }
        );

        if (!props.dashboard.configOptions?.secured) {
            const allmps = [];
            props.dashboard?.graphs?.forEach((graph) => {
                graph.measurementPoints?.forEach((mp) => {
                    allmps.push(mp);
                });
            });

            const uniqueMps = [...new Set(allmps.map((mp) => mp.id))];
            if (uniqueMps?.length === 0) {
                fetchingProgress(-1);
            }
        }
    }, [
        props.refreshCounter,
        props.from,
        props.to,
        props.windowPeriod,
        props.mps,
    ]);

    const groupsRecursive = (groups, id) => {
        const grps = [];
        const children = groups?.filter((g) => g.parent?.id === id);
        if (children?.length > 0) {
            grps.push(...children);
        }
        children?.forEach((g) => {
            grps.push(...groupsRecursive(groups, g.id));
        });
        return grps;
    };

    const saveLayout = (value) => {
        if (!isNullOrUndefined(props.dashboard)) {
            if (isNullOrUndefined(props.dashboard.configOptions)) {
                props.dashboard.configOptions = {};
            }

            let resized = false;
            let moved = false;
            props.dashboard.configOptions.layout?.forEach((l, ind) => {
                if (l.h !== value[ind].h || l.w !== value[ind].w) {
                    resized = true;
                }
                if (l.x !== value[ind].x || l.y !== value[ind].y) {
                    moved = true;
                }
            });
            props.dashboard.configOptions.layout = value;
            props.dashboard.configOptions.layout?.forEach((l) => {
                if (l.h === 1 && l.w === 1) {
                    l.h = 12;
                    l.w = 12;
                }
            });
            if (resized) {
                setForceRender(forceRender + 1);
            }
            if (moved || resized) {
                new DashboardApi().dashboardUpdateDashboard(
                    parseInt(id, 10),
                    parseInt(dbId, 10),
                    props.dashboard,
                    function (_, data, response) {}
                );
            }
        }
    };

    const fetchingProgress = (counter, total) => {
        props.fetchingProgress && props.fetchingProgress(counter, total);
    };

    const graphs = [];
    let index = 0;

    props.dashboard?.graphs?.forEach((graph) => {
        if (props.dashboard.configOptions?.secured) {
            graphs.push(
                <Wrapper ref={ref} key={`${++index}`} forceRender={forceRender}>
                    <TemperatureAppGraphContainer
                        user={props.user}
                        data={graphData}
                        protected={props.dashboard.configOptions?.secured}
                        graph={graph}
                        groups={props.groups}
                        mps={props.mps}
                    />
                </Wrapper>
            );
        } else {
            graphs.push(
                <Wrapper ref={ref} key={`${++index}`} forceRender={forceRender}>
                    <TemperatureAppGraphContainer
                        user={props.user}
                        data={graphData.filter((gd) =>
                            graph?.measurementPoints
                                ?.map((mp) => mp.id)
                                ?.includes(parseInt(gd.mpId, 10))
                        )}
                        protected={props.dashboard.configOptions?.secured}
                        graph={graph}
                        groups={props.groups}
                        mps={props.mps}
                    />
                </Wrapper>
            );
        }
    });

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return isNullOrUndefined(props.dashboard) ? (
        <></>
    ) : (
        <GridLayout
            className="layout"
            onResizeStop={onLayoutChange}
            onDragStop={onLayoutChange}
            layout={props.dashboard?.configOptions?.layout}
            minH={275}
            minW={275}
            cols={12}
            rowHeight={30}
            width={isMobile ? width * 0.8 + 30 : width * 0.8}
            draggableHandle=".DraggableDashboard">
            {graphs}
        </GridLayout>
    );
}
