import { TextEdit } from '@components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function CreateGatewayModal(props) {
    const [uuid, setUuid] = useState('');
    const [name, setName] = useState(props.name ?? '');
    const [errorMsg, setErrorMsg] = useState(null);
    const uuidRef = useRef();

    const { t } = useTranslation();

    const uuidChanged = (txt) => {
        setUuid(txt);
    };

    const nameChanged = (txt) => {
        const aquacloudQrContent =
            /AquaCloud,PN=[a-zA-Z0-9]{1,}-[a-zA-Z0-9]{1,}-[a-zA-Z0-9]{1,},SN=[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}/gm;
        const aquacloudReplace =
            /AquaCloud,PN=[a-zA-Z0-9]{1,}-[a-zA-Z0-9]{1,}-[a-zA-Z0-9]{1,},SN=/gm;
        if (new RegExp(aquacloudQrContent).test(txt)) {
            setName(txt.replace(aquacloudReplace, ''));
        } else {
            setName(txt);
        }
    };

    const onOkClick = () => {
        if (
            !isNullOrUndefined(name) &&
            name.trim() !== '' &&
            !isNullOrUndefined(uuid) &&
            uuid.trim() !== ''
        ) {
            props.confirm(uuid, name);
            setUuid('');
            setName('');
            setErrorMsg(null);
            uuidRef?.current?.lastChild?.lastChild?.focus();
        } else {
            setErrorMsg(t('pages_devices_creategatewaymodal_errormsg'));
        }
    };

    const keyDown = (evt) => {
        if (evt.key === 'Enter') {
            onOkClick();
        }
    };

    const closeModal = () => {
        setErrorMsg(null);
        setName('');
        setUuid('');
        props.handleClose && props.handleClose();
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={() => closeModal()}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {props.edit
                    ? `${t('modal_edit')} '${props.name}'`
                    : t('pages_devices_creategatewaymodal_newgw')}
            </DialogTitle>
            <DialogContent>
                {isNullOrUndefined(errorMsg) ? (
                    <></>
                ) : (
                    <div>
                        <Alert
                            onClose={() => setErrorMsg(null)}
                            severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                )}
                {props.edit ? (
                    <></>
                ) : (
                    <div style={{ marginTop: '20px' }}>
                        <TextEdit
                            reference={uuidRef}
                            label={t(
                                'pages_devices_creategatewaymodal_uuidlbl'
                            )}
                            placeholder="UUID"
                            value={uuid}
                            onKeyDown={(evt) => keyDown(evt)}
                            onChange={uuidChanged}
                        />
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    <TextEdit
                        label={t('pages_devices_creategatewaymodal_gwsnlbl')}
                        placeholder={t('pages_devices_creategatewaymodal_gwsn')}
                        value={name}
                        onKeyDown={(evt) => keyDown(evt)}
                        onChange={nameChanged}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => closeModal()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Create"
                    onClick={() => props.confirm && onOkClick()}
                    color="primary">
                    {props.edit ? t('modal_edit') : t('modal_create')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
