import i18next from 'i18next';
import moment from 'moment';
import {
    UNIT_TO_APPROX_MS,
    NUMBER_OF_DESIRED_POINTS,
} from '@utils/constants/timestamp.constants';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';

import i18n from '@/i18n';

export function msToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return hours + ':' + minutes + ':' + seconds;
}

export function convertDateToUtc(date) {
    if (!(date instanceof Date)) {
        throw new Error('ConvertDateToUtc: Provided parameter is not a date');
    }
    return new Date(
        Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        )
    );
}

export function convertStringToUtc(date) {
    if (date instanceof Date) {
        return convertDateToUtc(date);
    } else if (!(date instanceof String) && typeof date !== 'string') {
        throw new Error(
            'convertStringToUtc: Provided parameter is not a string nor a date'
        );
    }
    return convertDateToUtc(new Date(date));
}

export function convertStringUtcToLocaleString(date, timezone) {
    if (!(date instanceof String) && typeof date !== 'string') {
        throw new Error(
            'convertStringUtcToLocaleString: Provided parameter is not a string'
        );
    }
    const utcDate = new Date(date);
    return utcDateToString(utcDate, timezone);
}

export function utcDateToString(date, timezone, locale = 'de-DE') {
    if (!(date instanceof Date) && !moment.isMoment(date)) {
        throw new Error('dateToString: Provided parameter is not a date');
    }
    if (isNullOrUndefined(timezone)) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return date.toLocaleString(locale ?? 'de-DE', {
        timeZone: timezone,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });
}

export function dateToString(date, timezone, locale = 'de-DE') {
    if (!(date instanceof Date) && !moment.isMoment(date)) {
        throw new Error('dateToString: Provided parameter is not a date');
    }
    if (isNullOrUndefined(timezone)) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    date = new Date(
        `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
            '0' + date.getDate()
        ).slice(-2)}T${('0' + date.getHours()).slice(-2)}:${(
            '0' + date.getMinutes()
        ).slice(-2)}:${('0' + date.getSeconds()).slice(
            -2
        )}.${date.getMilliseconds()}Z`
    );
    return date.toLocaleString(locale ?? 'de-DE', {
        timeZone: timezone,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });
}

export function dateToDateString(date, timezone) {
    if (!(date instanceof Date) && !moment.isMoment(date)) {
        throw new Error('dateToString: Provided parameter is not a date');
    }
    if (isNullOrUndefined(timezone)) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    date = new Date(
        `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
            '0' + date.getDate()
        ).slice(-2)}T${('0' + date.getHours()).slice(-2)}:${(
            '0' + date.getMinutes()
        ).slice(-2)}:${('0' + date.getSeconds()).slice(
            -2
        )}.${date.getMilliseconds()}Z`
    );
    return date.toLocaleString('de-DE', {
        timeZone: timezone,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
}

export function dateToLocal(date, timezone) {
    if (!(date instanceof Date) && !moment.isMoment(date)) {
        throw new Error('dateToString: Provided parameter is not a date');
    }
    if (isNullOrUndefined(timezone)) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    date = new Date(
        `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
            '0' + date.getDate()
        ).slice(-2)}T${('0' + date.getHours()).slice(-2)}:${(
            '0' + date.getMinutes()
        ).slice(-2)}:${('0' + date.getSeconds()).slice(
            -2
        )}.${date.getMilliseconds()}Z`
    );
    return new Date(date.toLocaleString('en-US', { timeZone: timezone }));
}

export function timeDifference(previous, timeZone) {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const t = i18n.t.bind(i18next);

    if (isNullOrUndefined(timeZone)) {
        timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    previous = new Date(
        `${previous.getFullYear()}-${('0' + (previous.getMonth() + 1)).slice(
            -2
        )}-${('0' + previous.getDate()).slice(-2)}T${(
            '0' + previous.getHours()
        ).slice(-2)}:${('0' + previous.getMinutes()).slice(-2)}:${(
            '0' + previous.getSeconds()
        ).slice(-2)}.${previous.getMilliseconds()}Z`
    );
    previous = new Date(
        previous.toLocaleString('en-US', { timeZone: timeZone })
    );
    const elapsed = new Date() - previous < 0 ? 0 : new Date() - previous;

    if (elapsed < msPerMinute) {
        return `${t('util_lastupdated_first')} ${Math.round(
            elapsed / 1000
        )} ${t('util_lastupdate_seconds')} ${t('util_lastupdated_second')}`;
    } else if (elapsed < msPerHour) {
        return `${t('util_lastupdated_first')} ${Math.round(
            elapsed / msPerMinute
        )} ${t('util_lastupdate_minutes')} ${t('util_lastupdated_second')}`;
    } else if (elapsed < msPerDay) {
        return `${t('util_lastupdated_first')} ${Math.round(
            elapsed / msPerHour
        )} ${t('util_lastupdate_hours')} ${t('util_lastupdated_second')}`;
    } else if (elapsed < msPerMonth) {
        return `${t('util_lastupdated_first')} ${Math.round(
            elapsed / msPerDay
        )} ${t('util_lastupdate_days')} ${t('util_lastupdated_second')}`;
    } else if (elapsed < msPerYear) {
        return `${t('util_lastupdated_first')} ${Math.round(
            elapsed / msPerMonth
        )} ${t('util_lastupdate_months')} ${t('util_lastupdated_second')}`;
    } else {
        return `${t('util_lastupdated_first')} ${Math.round(
            elapsed / msPerYear
        )} ${t('util_lastupdate_years')} ${t('util_lastupdated_second')}`;
    }
}

export const calcWindowPeriodForDuration = (queryDuration) =>
    Math.round(queryDuration / NUMBER_OF_DESIRED_POINTS);

export const millisecondsToDuration = (value) => {
    const unitsAndMs = Object.entries(UNIT_TO_APPROX_MS).sort(
        (a, b) => b[1] - a[1]
    );
    const durations = [];

    let unitIndex = 0;
    let remainder = value;

    while (unitIndex < unitsAndMs.length) {
        const [unit, unitAsMs] = unitsAndMs[unitIndex];
        const valueInUnit = remainder / unitAsMs;

        durations.push({ unit, magnitude: Math.floor(valueInUnit) });
        remainder = remainder - Math.floor(valueInUnit) * unitAsMs;
        unitIndex += 1;
    }

    return durations
        .filter(({ magnitude }) => magnitude > 0)
        .reduce((s, { unit, magnitude }) => `${s}${magnitude}${unit}`, '');
};
