import { SideList, SideListItem } from '@components';
import { makeStyles } from '@material-ui/core/styles';
import { getDrawerSidebar, getSidebarContent } from '@mui-treasury/layout';
import { getProperRoute, isNullOrUndefined } from '@utils/helpers/app.helpers';
import { getAvatarColor } from '@utils/helpers/colors.helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const SidebarContent = getSidebarContent(styled);
const DrawerSidebar = getDrawerSidebar(styled);

const useStyles = makeStyles(({ breakpoints }) => ({
    collapseBtn: {
        color: '#fff',
        minWidth: 0,
        width: 40,
        borderRadius: '50%',
        border: 'none',
        backgroundColor: 'rgba(0,0,0,0.24)',
        margin: '0 auto 16px',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.38)',
        },
    },
    sidebar: {
        backgroundColor: '#fbfbfb',
        width: '100%',
        border: 'none',
        [breakpoints.down('md')]: {
            display: 'block',
            width: '70px',
        },
    },
    defaultAvatar: {
        borderRadius: '20%',
        border: 0,
        height: '40px',
        width: '40px',
        margin: 0,
        alignSelf: 'center',
        backgroundColor: (props) => getAvatarColor(props.id),
        fontSize: '20px',
        lineHeight: '40px',
        marginLeft: '10px',
        marginTop: '10px',
    },
}));

export function SidebarSite(props) {
    const { groupId, id } = useParams();
    const styles = useStyles({ id: id });
    const { t } = useTranslation();

    return isNullOrUndefined(props.site) ? (
        <> </>
    ) : (
        <DrawerSidebar
            sidebarId={'primarySidebar'}
            PaperProps={{ className: styles.sidebar }}>
            <SidebarContent style={{ overflowX: 'hidden' }}>
                <div
                    style={{
                        display: 'inline-flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}>
                    <div
                        style={{
                            height: 'inherit',
                            alignSelf: 'center',
                            textAlign: 'center',
                        }}>
                        <div className={styles.defaultAvatar}>
                            {props.site.name[0]?.toUpperCase()}
                        </div>
                    </div>
                    <div style={{ marginLeft: '20px', marginTop: '20px' }}>
                        <p>{props.site.name}</p>
                    </div>
                </div>
                <hr />
                <SideList>
                    <SideListItem
                        link={getProperRoute(
                            `groups/${groupId}/sites/${id}/dashboards`
                        )}
                        active={props.active === 'dashboards'}
                        text={t('pagelayout_sidebarsite_dashboards')}
                        icon="dashboards"
                    />
                    <SideListItem
                        link={getProperRoute(
                            `groups/${groupId}/sites/${id}/manual_measurements`
                        )}
                        active={props.active === 'manual'}
                        text={t('pagelayout_sidebarsite_manual')}
                        icon="manual"
                    />
                    <SideListItem
                        link={getProperRoute(
                            `groups/${groupId}/sites/${id}/settings`
                        )}
                        active={props.active?.includes('settings')}
                        text={t('pagelayout_sidebarsite_settings')}
                        icon="settings">
                        <SideList>
                            <SideListItem
                                link={getProperRoute(
                                    `groups/${groupId}/sites/${id}/settings`
                                )}
                                active={props.active === 'settings/general'}
                                nested
                                text={t('pagelayout_sidebarsite_general')}
                            />
                            <SideListItem
                                link={getProperRoute(
                                    `groups/${groupId}/sites/${id}/members`
                                )}
                                active={props.active === 'settings/members'}
                                nested
                                text={t('pagelayout_sidebarsite_members')}
                            />
                            <SideListItem
                                link={getProperRoute(
                                    `groups/${groupId}/sites/${id}/gateways`
                                )}
                                active={props.active === 'settings/gateways'}
                                nested
                                text={t('pagelayout_sidebarsite_gateways')}
                            />
                        </SideList>
                    </SideListItem>
                    <SideListItem
                        link={getProperRoute(
                            `groups/${groupId}/sites/${id}/alarms`
                        )}
                        active={props.active === 'alarms'}
                        text={t('pagelayout_sidebarsite_alarms')}
                        icon="alarms"
                    />
                </SideList>
            </SidebarContent>
        </DrawerSidebar>
    );
}
