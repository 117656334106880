import { DashboardApi, GraphApi } from '@aurum/nucleus-client-api';
import {
    BarGraph,
    BoxPlot,
    CircleButton,
    CustomTable,
    CustomTabs,
    LineGraph,
    MetricPlot,
    PieGraph,
    RoundedButton,
} from '@components';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { getProperRoute, isNullOrUndefined } from '@utils/helpers/app.helpers';
import { useWindowSize } from '@utils/hooks/useWindowSize';
import DisplayTab from '@views/sites/dashboards/graphs/create-graph-tabs/DisplayTab';
import SettingsTab from '@views/sites/dashboards/graphs/create-graph-tabs/SettingsTab';
import VisualizationTab from '@views/sites/dashboards/graphs/create-graph-tabs/VisualizationTab';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

export default function AddGraph(props) {
    const [editMode, setEditMode] = useState(false);
    const [series, setSeries] = useState([]);
    const [boxseries, setBoxSeries] = useState([]);
    const [graph, setGraph] = useState(null);
    const [increment, setIncrement] = useState(0);
    const [width, height] = useWindowSize();
    const [selectedTab, setSelectedTab] = useState(0);
    const [db, setDb] = useState(null);
    const { groupId, id, dbId, graphId } = useParams();

    const { t } = useTranslation();

    useEffect(() => {
        new DashboardApi().dashboardGetDashboard(
            parseInt(id, 10),
            parseInt(dbId, 10),
            function (_, data, response) {
                if (isNullOrUndefined(data)) {
                    window.location = getProperRoute(
                        `groups/${groupId}/sites/${id}/dashboards`
                    );
                }
                setDb(response.body);
            }
        );
        if (!isNullOrUndefined(graphId) && isNullOrUndefined(graph)) {
            setEditMode(true);
            new GraphApi().graphGetGraph(
                parseInt(id, 10),
                parseInt(graphId, 10),
                function (_, data, response) {
                    if (response.ok) {
                        setGraph(response.body);
                    }
                }
            );
        }
        if (isNullOrUndefined(graphId) && isNullOrUndefined(graph)) {
            setGraph({
                name: '',
                type: 0,
                measurement: 'temperature',
                measurementPoints: [],
                dashboards: [{ id: parseInt(dbId, 10) }],
                configOptions: {},
            });
        }
        const allseries = [];
        const allBoxSeries = [];
        const serie = {
            name: t('pages_addgraph_addgraph_examplename'),
            color: 'rgba(255,0,0,1)',
            datapoints: [],
        };
        const serie2 = {
            name: `${t('pages_addgraph_addgraph_examplename')} 2`,
            color: 'rgba(0,255,0,1)',
            datapoints: [],
        };

        const boxSerie = {
            name: t('pages_addgraph_addgraph_examplename'),
            color: 'rgba(255,0,0,1)',
            datapoints: [],
        };
        for (let i = 0; i < 10; i++) {
            const dt = new Date(new Date().getTime() + i * 10 * 60000);
            boxSerie.datapoints.push({
                x: dt,
                min: getRandomValue(0, 5),
                q1: getRandomValue(5, 7),
                median: getRandomValue(6, 7),
                q3: getRandomValue(7, 9),
                max: getRandomValue(9, 15),
            });
            serie.datapoints.push({ x: dt, y: getRandomValue(20.0, 30.0) });
            serie2.datapoints.push({ x: dt, y: getRandomValue(20.0, 30.0) });
        }
        allseries.push(serie);
        allseries.push(serie2);
        allBoxSeries.push(boxSerie);
        setSeries(allseries);
        setBoxSeries(allBoxSeries);
    }, []);

    const createGraph = () => {
        if (editMode) {
            new GraphApi().graphUpdateGraph(
                parseInt(id, 10),
                parseInt(graphId, 10),
                graph,
                function (_, data, response) {
                    if (response.ok) {
                        window.location.href = getProperRoute(
                            props.site?.type === 'filter'
                                ? `groups/${groupId}/sites/filter/${id}/dashboards/${dbId}`
                                : `groups/${groupId}/sites/${id}/dashboards/${dbId}`
                        );
                    }
                }
            );
        } else {
            new GraphApi().graphCreateGraph(
                parseInt(id, 10),
                graph,
                function (_, data, response) {
                    // create the layout object of the dashboard if it doesn't exist
                    if (isNullOrUndefined(db.configOptions)) {
                        db.configOptions = { layout: [] };
                    }
                    if (isNullOrUndefined(db.configOptions.layout)) {
                        db.configOptions.layout = [];
                    }
                    // add the graph to the config options of the dashboard
                    db.configOptions.layout.push({
                        h: 14,
                        i: `${db.configOptions.layout.length + 1}`,
                        w: 12,
                        x: 0,
                        y: 0,
                        moved: false,
                        static: false,
                    });
                    new DashboardApi().dashboardUpdateDashboard(
                        parseInt(id, 10),
                        parseInt(dbId, 10),
                        db,
                        function (_, d, r) {
                            if (response.ok) {
                                window.location.href = getProperRoute(
                                    props.site?.type === 'filter'
                                        ? `groups/${groupId}/sites/filter/${id}/dashboards/${dbId}`
                                        : `groups/${groupId}/sites/${id}/dashboards/${dbId}`
                                );
                            }
                        }
                    );
                }
            );
        }
    };

    const graphChanged = (newgraph) => {
        setGraph(newgraph);
        setIncrement(increment + 1);
    };

    const getRandomValue = (max, min) => {
        return parseFloat((Math.random() * (max - min) + min).toFixed(2));
    };

    let graphToBeDisplayed = <></>;
    switch (graph?.type) {
        case 0:
            graphToBeDisplayed = (
                <LineGraph
                    customStyle={graph.configOptions}
                    series={series}
                    width={width * 0.79}
                    height={height * 0.3}
                />
            );
            break;
        case 3:
            graphToBeDisplayed = (
                <PieGraph
                    series={series}
                    width={width * 0.79}
                    height={height * 0.3}
                />
            );
            break;
        case 1:
            graphToBeDisplayed = (
                <BarGraph
                    customStyle={graph.configOptions}
                    series={series}
                    width={width * 0.79}
                    height={height * 0.3}
                />
            );
            break;
        case 2:
            graphToBeDisplayed = (
                <BarGraph
                    customStyle={graph.configOptions}
                    series={series}
                    horizontal={true}
                    width={width * 0.79}
                    height={height * 0.3}
                />
            );
            break;
        case 5:
            graphToBeDisplayed = <MetricPlot data={series} />;
            break;
        case 4:
            graphToBeDisplayed = (
                <BoxPlot
                    customStyle={graph.configOptions}
                    series={boxseries}
                    width={width * 0.79}
                    height={height * 0.3}
                />
            );
            break;
        case 6:
            graphToBeDisplayed = (
                <CustomTable
                    headers={[]}
                    data={[]}
                    print={true}
                    download={true}
                    filter={true}
                    search={true}
                />
            );
            break;
    }
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: width * 0.8,
                    marginBottom: '10px',
                }}>
                <div style={{ width: isMobile ? '20%' : '5%' }}>
                    <CircleButton
                        onClick={() =>
                            (window.location.href = getProperRoute(
                                props.site?.type === 'filter'
                                    ? `groups/${groupId}/sites/filter/${id}/dashboards/${dbId}`
                                    : `groups/${groupId}/sites/${id}/dashboards/${dbId}`
                            ))
                        }
                        tooltip={t('pages_addgraph_addgraph_back')}
                        icon={<ArrowBack />}
                    />
                </div>
                <div
                    style={{
                        width: isMobile ? '50%' : '85%',
                        alignItems: 'center',
                        display: 'flex',
                    }}>
                    {editMode ? (
                        <span style={{ fontSize: '20px' }}>
                            {t('pages_dashboard_addgraph_edit')}
                        </span>
                    ) : (
                        <span style={{ fontSize: '20px' }}>
                            {t('pages_addgraph_addgraph_new')}
                        </span>
                    )}
                </div>
                <div
                    style={{
                        width: isMobile ? '30%' : '10%',
                        justifyContent: 'flex-end',
                    }}>
                    <RoundedButton
                        onClick={() => createGraph()}
                        tooltip={t('pages_addgraph_addgraph_save')}
                        width="100%"
                        color="primary"
                        text={t('pages_addgraph_addgraph_apply')}
                    />
                </div>
            </div>
            <Card
                style={{
                    width: width * 0.8,
                    height: height * 0.4,
                    background: 'rgba(230, 230, 230, 0.3)',
                }}
                variant="outlined">
                <CardContent>{graphToBeDisplayed}</CardContent>
            </Card>
            <Card
                style={{
                    marginTop: '50px',
                    width: width * 0.8,
                    background: 'rgba(230, 230, 230, 0.3)',
                }}
                variant="outlined">
                <CardContent>
                    <CustomTabs
                        tabs={[
                            { text: t('pages_addgraph_addgraph_settings') },
                            {
                                text: t(
                                    'pages_addgraph_addgraph_visualization'
                                ),
                            },
                            { text: t('pages_addgraph_addgraph_display') },
                        ]}
                        onTabChanged={(ind) => setSelectedTab(ind)}
                    />
                    {selectedTab === 0 ? (
                        <SettingsTab
                            secured={db?.configOptions?.secured ?? false}
                            graph={graph}
                            graphChanged={graphChanged}
                        />
                    ) : selectedTab === 1 ? (
                        <VisualizationTab
                            graph={graph}
                            graphChanged={graphChanged}
                        />
                    ) : (
                        <DisplayTab graph={graph} graphChanged={graphChanged} />
                    )}
                </CardContent>
            </Card>
            <br /> <br />
        </>
    );
}
