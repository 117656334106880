import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import React from 'react';

const useStyles = makeStyles(() => ({
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#000',
        '&:hover': {
            color: '#000',
        },
    },
    subTitle: {
        color: 'gray',
    },
    icon: {
        color: (props) => (props.default ? '#F9B208' : ''),
        height: '24px',
        width: '24px',
        marginLeft: '10px',
        alignSelf: 'center',
        '&:hover': {
            cursor: 'pointer',
            color: '#F9B208',
        },
    },
    grow: {
        flexGrow: 1,
        width: '100%',
        margin: 'auto',
    },
}));

export default function DashboardTableEntry(props) {
    const styles = useStyles(props);

    return (
        <>
            <div className="row">
                <div className="col-md-12 d-flex align-items-center justify-content-start">
                    <a className={styles.title} href={props.link}>
                        {props.title}
                    </a>
                    {props.default ? (
                        <StarIcon className={styles.icon} />
                    ) : (
                        <StarOutlineIcon
                            onClick={() =>
                                props.onDefaultClick &&
                                props.onDefaultClick(props.id)
                            }
                            className={styles.icon}
                        />
                    )}
                </div>
            </div>
            <Grid className={styles.grow}>
                <Typography className={styles.subTitle}>
                    {props.description}
                </Typography>
            </Grid>
        </>
    );
}
