import { ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import {
    calcWindowPeriodForDuration,
    millisecondsToDuration,
} from '@utils/helpers/timestamp.helpers';
import { RoundedButton } from '@components';

const useStyles = makeStyles(() => ({
    commonButton: {
        marginLeft: '0px',
        display: 'flex',
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        height: '40px',
        paddingTop: '0px',
        paddingRight: '8px',
        lineHeight: '30px',
        fontWeight: 500,
        borderColor: 'gray',
        borderTopStyle: 'solid',
        borderRightStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderImageSource: 'initial',
        borderImageSlice: 'initial',
        borderImageWidth: 'initial',
        borderImageOutset: 'initial',
        borderImageRepeat: 'initial',
        whiteSpace: 'nowrap',
        color: 'rgb(20, 24, 28)',
        backgroundColor: '#fff',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
    },
    generalButton: {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderTopWidth: '2px',
        borderRightWidth: '2px',
        borderBottomWidth: '2px',
        borderLeftWidth: '2px',
    },
    clockIcon: {
        overflow: 'hidden',
        verticalAlign: 'middle',
        display: 'inline-block',
        marginBottom: '2px',
        color: '#000',
    },
    textButton: {
        display: 'block',
        WebkitBoxFlex: 1,
        flexGrow: 1,
    },
    spanText: {
        display: 'flex',
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
    },
    generalMenu: {
        backgroundImage: 'initial',
        backgroundPositionX: 'initial',
        backgroundPositionY: 'initial',
        backgroundSize: 'initial',
        backgroundAttachment: 'initial',
        backgroundOrigin: 'initial',
        backgroundClip: 'initial',
        backgroundColor: '#fff',
        position: 'absolute',
        zIndex: 1030,
        width: '250px',
        top: '116%',
        display: 'inline-flex',
        flexDirection: 'column',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderTopWidth: '2px',
        borderRightWidth: '2px',
        borderBottomWidth: '2px',
        borderLeftWidth: '2px',
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderRightStyle: 'solid',
        borderImageSource: 'initial',
        borderImageSlice: 'initial',
        borderImageWidth: 'initial',
        borderImageOutset: 'initial',
        borderImageRepeat: 'initial',
        right: '0px',
    },
    windowSelectionGroup: {
        display: 'inline-flex',
        alignItems: 'stretch',
        borderRadius: '4px',
        padding: '4px',
        height: '35px',
        width: '100%',
        marginBottom: '10px',
    },
    hiddenInput: {
        position: 'fixed',
        top: 0,
        left: 0,
        visibility: 'hidden',
    },
    label: {
        flex: '1 0 0',
        height: '100%',
        padding: '0 8px',
        border: '1px solid black',
        fontWeight: '600',
        textTransform: 'capitalize',
        textAlign: 'center',
        borderRadius: '3px',
        outline: 'none',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: '2px',
        cursor: 'pointer',
    },
    labelActive: {
        backgroundColor: '#007bff',
        color: '#fff',
    },
}));

export default function DashboardWindowPeriodPicker(props) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [windowSelected, setWindowSelected] = useState(1);
    const [windowPeriod, setWindowPeriod] = useState('5m');
    const styles = useStyles(props);

    const windowPeriodSelection = () => {
        setAnchorEl(null);
        props.onWindowPeriodChange &&
            props.onWindowPeriodChange(windowSelected, windowPeriod);
    };

    const handleWpChange = (event) => {
        setWindowPeriod(event.target.value);
    };

    let wp = windowPeriod;
    if (windowSelected === 1) {
        if (
            props.from <
            new Date(new Date().setFullYear(new Date().getFullYear() - 1))
        ) {
            wp = '1d';
        } else {
            wp = millisecondsToDuration(
                calcWindowPeriodForDuration(props.to - props.from)
            );
        }
    }

    return (
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    verticalAlign: 'middle',
                }}>
                <button
                    aria-label="SelectWindowPeriod"
                    onClick={(event) =>
                        isNullOrUndefined(anchorEl)
                            ? setAnchorEl(event.currentTarget)
                            : setAnchorEl(null)
                    }
                    className={`${styles.commonButton} ${styles.generalButton}`}>
                    <div className={styles.textButton}>
                        <span className={styles.spanText}>
                            <span className={styles.clockIcon}>
                                <TimelapseIcon /> {wp}
                            </span>
                        </span>
                    </div>
                    <div className={styles.clockIcon}>
                        <ExpandMoreOutlinedIcon />
                    </div>
                </button>
                <div
                    className={styles.generalMenu}
                    style={{
                        visibility: isNullOrUndefined(anchorEl)
                            ? 'hidden'
                            : 'visible',
                    }}>
                    <p
                        style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginTop: '20px',
                        }}>
                        Aggregation Window
                    </p>
                    <div className={styles.windowSelectionGroup}>
                        <span
                            onClick={() => setWindowSelected(0)}
                            className={`${styles.label} ${
                                windowSelected === 0 ? styles.labelActive : ''
                            }`}>
                            Custom
                        </span>
                        <span
                            onClick={() => setWindowSelected(1)}
                            className={`${styles.label} ${
                                windowSelected === 1 ? styles.labelActive : ''
                            }`}>
                            Auto
                        </span>
                    </div>
                    <TextField
                        style={{ marginRight: '10px', marginLeft: '10px' }}
                        variant="standard"
                        disabled={windowSelected === 1}
                        value={wp}
                        onChange={handleWpChange}
                    />
                    <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                        <RoundedButton
                            onClick={() => windowPeriodSelection()}
                            marginTop="20px"
                            marginLeft="20px"
                            text={t(
                                'pages_dashboard_dashboarddatepicker_apply'
                            )}
                            width="50%"
                        />
                    </div>
                </div>
            </div>
        </ClickAwayListener>
    );
}
