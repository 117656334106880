import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { alphabetPosition, capitalize } from '@utils/helpers/text.helpers';
import { getAvatarColor } from '@utils/helpers/colors.helpers';
import { RoundedText } from '@components';

const useStyles = makeStyles((props) => ({
    defaultAvatar: {
        borderRadius: '20%',
        border: 0,
        height: '40px',
        width: '40px',
        textAlign: 'center',
        margin: 0,
        alignSelf: 'center',
        backgroundColor: (props) =>
            isNullOrUndefined(props.user?.email)
                ? getAvatarColor(1)
                : getAvatarColor(alphabetPosition(props.user.email[0])),
        fontSize: '20px',
        lineHeight: '40px',
        marginLeft: '10px',
        marginTop: '10px',
    },
    link: {
        color: 'inherit',
        '&:hover': {
            textDecorations: 'none',
            color: 'inherit',
        },
    },
}));

export default function UserEntryTable(props) {
    const styles = useStyles(props);
    const { t } = useTranslation();

    const diffDays = isNullOrUndefined(props.user?.lockedUntil)
        ? 1
        : Math.ceil(
              Math.abs(new Date(props.user.lockedUntil) - new Date()) /
                  (1000 * 60 * 60 * 24)
          );
    const locked = props.user?.locked && diffDays > 365;

    return (
        <>
            <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                {props.avatar ? (
                    <img
                        alt="avatar"
                        className={styles.defaultAvatar}
                        src={props.avatar}
                    />
                ) : (
                    <div className={styles.defaultAvatar}>
                        {props.user?.userName[0]?.toUpperCase()}
                    </div>
                )}
                <div style={{ marginTop: '10px' }}>
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '15px',
                            marginLeft: '20px',
                            fontSize: '13px',
                        }}>
                        <a className={styles.link} href={props.link}>
                            {props.user?.userName}
                        </a>
                        <div style={{ marginLeft: '20px' }}>
                            <RoundedText
                                color="outlined"
                                width="75px"
                                text={
                                    capitalize(props.user?.role) ??
                                    t('pages_users_userentrytable_unknown')
                                }
                            />
                        </div>
                        {locked ? (
                            <div style={{ marginLeft: '5px' }}>
                                <RoundedText
                                    color="secondary"
                                    width="60px"
                                    text="Locked"
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <p style={{ marginLeft: '20px', fontSize: '13px' }}>
                        {props.user?.email}
                    </p>
                </div>
            </Grid>
        </>
    );
}
