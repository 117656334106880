import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ColumnToRow, Item } from '@mui-treasury/components/flex';
import { NavItem, NavMenu } from '@mui-treasury/components/menu/navigation';
import { usePlainNavigationMenuStyles } from '@mui-treasury/styles/navigationMenu/plain';
import cx from 'clsx';
import React from 'react';
import { customTheme } from '@/App';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
    legalLink: {
        ...typography.caption,
        justifyContent: 'center',
        color:
            customTheme.palette.type === 'dark'
                ? 'rgba(255,255,255,0.57)'
                : customTheme.palette.secondary,
        position: 'relative',
        [breakpoints.up('sm')]: {
            '&:not(:first-of-type)': {
                '&:before': {
                    content: '"|"',
                    display: 'block',
                    position: 'absolute',
                    left: 0,
                },
            },
        },
    },
}));

export function AppFooter(props) {
    const classes = useStyles();

    return (
        <Box
            pt={2}
            pb={10}
            style={{
                width: '80%',
                paddingLeft: '20%',
                left: 0,
                bottom: 0,
                position: 'fixed',
            }}>
            <ColumnToRow
                at={'md'}
                columnStyle={{ alignItems: 'center' }}
                rowStyle={{ alignItems: 'unset' }}>
                <Item grow ml={-2} shrink={0}>
                    <NavMenu useStyles={usePlainNavigationMenuStyles}>
                        <ColumnToRow at={'sm'}>
                            <NavItem className={cx(classes.legalLink)}>
                                Cookie Policy
                            </NavItem>
                            <NavItem className={cx(classes.legalLink)}>
                                Privacy Policy
                            </NavItem>
                            <NavItem className={cx(classes.legalLink)}>
                                Impressum
                            </NavItem>
                        </ColumnToRow>
                    </NavMenu>
                </Item>
                <Item>
                    <Box py={1} textAlign={{ xs: 'center', md: 'right' }}>
                        <Typography
                            component={'p'}
                            variant={'caption'}
                            color={'textSecondary'}>
                            AquaCloud © 2021 All right reserved
                        </Typography>
                    </Box>
                </Item>
            </ColumnToRow>
        </Box>
    );
}
