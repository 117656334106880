import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RouterIcon from '@material-ui/icons/Router';
import React from 'react';

const useStyles = makeStyles(() => ({
    link: {
        height: '20px',
        alignSelf: 'center',
        color: 'inherit',
        '&:hover': {
            color: 'inherit',
        },
    },
    avatarContainer: {
        flexShrink: 0,
        flexGrow: 0,
        display: 'flex',
        overflow: 'hidden',
        float: 'left',
        alignItems: 'center',
        marginRight: '20px',
    },
    textContainer: {
        minWidth: 0,
        alignItems: 'center',
        flex: '1 1 auto',
        display: 'flex',
    },
    infoContainer: {
        flexBasis: '100%',
        minWidth: 0,
        flexShrink: 1,
        flexGrow: 1,
        display: 'block',
    },
    icon: {
        width: '20px',
        height: '20px',
    },
}));

export default function DeviceTableEntry(props) {
    const styles = useStyles();

    let icon = <RouterIcon />;
    if (props.sensor) {
        icon = (
            <svg
                className={styles.icon}
                height="512"
                viewBox="0 0 512 512"
                width="512">
                <g>
                    <path d="m437.066 74.981c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213c42.656 42.655 66.147 99.409 66.147 159.806s-23.491 117.151-66.147 159.806c-5.858 5.857-5.858 15.355 0 21.213 5.868 5.868 15.365 5.848 21.213 0 48.322-48.321 74.934-112.608 74.934-181.019s-26.612-132.698-74.934-181.019z" />
                    <path d="m391.646 120.4c-5.858-5.857-15.356-5.857-21.213 0-5.857 5.858-5.857 15.355 0 21.213 30.533 30.531 47.349 71.155 47.349 114.387s-16.815 83.855-47.349 114.387c-5.858 5.858-5.858 15.355 0 21.213 5.856 5.856 15.353 5.859 21.213 0 36.2-36.198 56.136-84.355 56.136-135.601s-19.937-99.402-56.136-135.599z" />
                    <path d="m346.193 165.852c-5.858-5.857-15.356-5.857-21.213 0-5.858 5.858-5.857 15.356 0 21.213 18.4 18.398 28.533 42.88 28.533 68.934s-10.133 50.536-28.533 68.934c-5.858 5.857-5.858 15.355 0 21.213 5.857 5.857 15.354 5.859 21.213 0 24.066-24.065 37.32-56.08 37.32-90.148s-13.254-66.081-37.32-90.146z" />
                    <path d="m30 256c0-60.397 23.491-117.151 66.147-159.806 5.858-5.857 5.858-15.355 0-21.213-5.857-5.857-15.354-5.858-21.213 0-48.322 48.321-74.934 112.608-74.934 181.019s26.612 132.698 74.934 181.019c5.856 5.857 15.355 5.859 21.213 0 5.858-5.858 5.858-15.355 0-21.213-42.656-42.655-66.147-99.409-66.147-159.806z" />
                    <path d="m94.219 256c0-43.232 16.815-83.855 47.349-114.387 5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.857-21.213 0-36.2 36.197-56.136 84.355-56.136 135.6s19.936 99.403 56.136 135.601c5.866 5.865 15.363 5.85 21.213 0 5.857-5.858 5.857-15.355 0-21.213-30.534-30.532-47.349-71.156-47.349-114.388z" />
                    <path d="m187.021 165.853c-5.857-5.858-15.355-5.858-21.213 0-24.066 24.065-37.32 56.08-37.32 90.148s13.254 66.083 37.32 90.148c5.866 5.865 15.363 5.85 21.213 0 5.858-5.858 5.857-15.356 0-21.213-18.4-18.398-28.533-42.88-28.533-68.934s10.133-50.536 28.533-68.934c5.857-5.859 5.857-15.357 0-21.215z" />
                    <path d="m256 192.8c-34.849 0-63.2 28.352-63.2 63.2s28.352 63.2 63.2 63.2 63.2-28.352 63.2-63.2-28.351-63.2-63.2-63.2zm0 96.4c-18.307 0-33.2-14.894-33.2-33.2s14.894-33.2 33.2-33.2 33.2 14.894 33.2 33.2-14.893 33.2-33.2 33.2z" />
                </g>
            </svg>
        );
    }

    return (
        <Grid
            container
            spacing={2}
            style={{ height: '30px', marginLeft: '10px' }}>
            <div className={styles.avatarContainer}>{icon}</div>
            <div className={styles.textContainer}>
                <div className={styles.infoContainer}>
                    <a className={styles.link} href={props.link}>
                        <p
                            style={{
                                fontWeight: 'bold',
                                fontSize: '15px',
                                marginTop: '15px',
                            }}>
                            {props.name}
                        </p>
                    </a>
                </div>
            </div>
        </Grid>
    );
}
