import GatewayApi from '@aurum/nucleus-client-api/dist/api/GatewayApi';
import TemperatureApi from '@aurum/nucleus-client-api/dist/api/TemperatureApi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { convertStringUtcToLocaleString } from '@utils/helpers/timestamp.helpers';
import { CustomBreadcrumbs, FilesTable } from '@components';

export default function DiagnosticsPage(props) {
    const [gateways, setGateways] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [showPage, setShowPage] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (!isNullOrUndefined(props.user)) {
            if (!userIsAtLeast(props.user, 'admin')) {
                window.location.href = getProperRoute(`sites`);
                return;
            }
            setShowPage(true);
            new GatewayApi().gatewayGetGatewaysForDiagnostic(function (
                _,
                data,
                response
            ) {
                if (response.ok) {
                    setGateways(data);
                }
            });
            new TemperatureApi().temperatureGetMeasuringElementsForDiagnostic(
                { indexStart: 0, count: 10000 },
                function (_, data, response) {
                    if (response.ok) {
                        setSensors(response.body);
                    }
                }
            );
        }
    }, [props.user]);

    const gwRows = [];
    gateways?.forEach((gw) => {
        gwRows.push([
            {
                link: getProperRoute(`diagnostics/gateways/${gw.uuid}`),
                text: gw.uuid,
                value: (
                    <div
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>
                        {' '}
                        {gw.uuid}{' '}
                    </div>
                ),
            },
            {
                link: null,
                text: gw.name,
                value: (
                    <div
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>
                        {' '}
                        {gw.name}
                    </div>
                ),
            },
            {
                link: null,
                text: gw.connected
                    ? t('pages_diagnostics_diagnosticsgatewaypage_connected')
                    : t(
                          'pages_diagnostics_diagnosticsgatewaypage_disconnected'
                      ),
                value: (
                    <div
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>
                        {' '}
                        {gw.connected
                            ? t(
                                  'pages_diagnostics_diagnosticsgatewaypage_connected'
                              )
                            : t(
                                  'pages_diagnostics_diagnosticsgatewaypage_disconnected'
                              )}
                    </div>
                ),
            },
        ]);
    });

    const sensorRows = [];
    sensors?.forEach((sensor) => {
        const gatewayWithBestSignal = sensor.signalQuality.filter(
            (sq) =>
                sq.rssi === Math.max(...sensor.signalQuality.map((r) => r.rssi))
        );
        // The sensor will be active if it sent data in the last 20 minutes
        const sensorIsActive = isNullOrUndefined(sensor.lastRX)
            ? false
            : Math.floor(
                  (new Date().getTime() - new Date(sensor.lastRX)) / (1000 * 60)
              ) <= 20;
        sensorRows.push([
            {
                link: getProperRoute(`diagnostics/sensors/${sensor.uuid}`),
                text: sensor.uuid,
                value: (
                    <span>
                        <span
                            style={{ color: sensorIsActive ? 'green' : 'red' }}>
                            ●{' '}
                        </span>
                        <span>{sensor.uuid}</span>
                    </span>
                ),
            },
            { link: null, text: sensor.description, value: sensor.description },
            {
                link: null,
                text: isNullOrUndefined(sensor.value)
                    ? -1000
                    : parseFloat(sensor.value.slice(0, -2)),
                value: isNullOrUndefined(sensor.value) ? '-' : sensor.value,
            },
            {
                link: null,
                text: isNullOrUndefined(sensor.battery) ? 0 : sensor.battery,
                value: isNullOrUndefined(sensor.battery) ? '-' : sensor.battery,
            },
            {
                link: null,
                text: sensor.lastJoin
                    ? new Date(sensor.lastJoin)
                    : new Date(2000, 1, 1),
                value: isNullOrUndefined(sensor.lastJoin)
                    ? t('pages_diagnostics_diagnosticspage_nojoin')
                    : convertStringUtcToLocaleString(
                          sensor.lastJoin,
                          props.user.timeZone
                      ),
            },
            {
                link: null,
                text: sensor.lastRX
                    ? new Date(sensor.lastRX)
                    : new Date(2000, 1, 1),
                value: isNullOrUndefined(sensor.lastRX)
                    ? t('common_nodata_nodata')
                    : convertStringUtcToLocaleString(
                          sensor.lastRX,
                          props.user.timeZone
                      ),
            },
            {
                link: null,
                text: isNullOrUndefined(gatewayWithBestSignal[0])
                    ? '-'
                    : gatewayWithBestSignal[0].rssi?.toString(),
                value: isNullOrUndefined(gatewayWithBestSignal[0])
                    ? '-'
                    : gatewayWithBestSignal[0].rssi,
            },
            {
                link: null,
                text: isNullOrUndefined(gatewayWithBestSignal[0])
                    ? '-'
                    : gatewayWithBestSignal[0].datr,
                value: isNullOrUndefined(gatewayWithBestSignal[0])
                    ? '-'
                    : gatewayWithBestSignal[0].datr,
            },
        ]);
    });

    return showPage ? (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: getProperRoute(`admin/customers`),
                            text: 'Admin Area',
                        },
                        {
                            link: getProperRoute(`/`),
                            text: t(
                                'pages_diagnostics_diagnosticsgatewaypage_diagnostics'
                            ),
                        },
                    ]}
                />
            </div>
            <hr />
            <br />
            <div className="row">
                <div className="col-md-12">
                    <div style={{ width: '100%', marginTop: '20px' }}>
                        <FilesTable
                            sort
                            pagination
                            search
                            columns={[
                                t('pages_diagnostics_diagnosticspage_gwuuid'),
                                t('pages_diagnostics_diagnosticspage_serialno'),
                                t('pages_diagnostics_diagnosticspage_status'),
                            ]}
                            rows={gwRows}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div style={{ width: '100%', marginTop: '20px' }}>
                        <FilesTable
                            responsive
                            sort
                            pagination
                            search
                            resizeHeader
                            columns={[
                                t(
                                    'pages_diagnostics_diagnosticspage_sensoruuid'
                                ),
                                t('pages_diagnostics_diagnosticspage_serialno'),
                                t('pages_diagnostics_diagnosticspage_value'),
                                t('pages_diagnostics_diagnosticspage_battery'),
                                t(
                                    'pages_diagnostics_diagnosticssensorpage_lastjoin'
                                ),
                                t(
                                    'pages_diagnostics_diagnosticssensorpage_lastrx'
                                ),
                                'RSSI',
                                'Data Rate',
                            ]}
                            rows={sensorRows}
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
}
