import {
    ManualMeasurementApi,
    MeasurementPointApi,
} from '@aurum/nucleus-client-api';
import { MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
    getFullGroups,
} from '@utils/helpers/app.helpers';
import { dateToString } from '@utils/helpers/timestamp.helpers';
import { CustomBreadcrumbs, FilesTable, MoreSettingsMenu } from '@components';
import CreateManualMeasurementModal from '@views/sites/manual-measurements/create-manual-measurement/CreateManualMeasurementModal';

export default function ManualMeasurementsPage(props) {
    const [mps, setMps] = useState([]);
    const [manual, setManual] = useState([]);
    const [selectedManual, setSelectedManual] = useState([]);
    const [numSelected, setNumSelected] = useState(0);
    const [createManualModal, setCreateManualModal] = useState(false);
    const [creationErrorMsg, setCreationErrorMsg] = useState(null);
    const { groupId, id } = useParams();

    const { t } = useTranslation();

    useEffect(() => {
        new ManualMeasurementApi().manualMeasurementGetManualMeasurements(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setManual(data);
                }
            }
        );
        new MeasurementPointApi().measurementPointGetMeasurementPoints(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setMps(data);
                }
            }
        );
    }, []);

    const createManualMeasurement = (mm) => {
        new ManualMeasurementApi().manualMeasurementAddManualMeasurement(
            parseInt(id, 10),
            mm,
            function (_, data, response) {
                if (response.ok) {
                    setCreationErrorMsg(null);
                    setCreateManualModal(false);
                    setManual([...manual, data]);
                } else {
                    setCreationErrorMsg(response.body);
                }
            }
        );
    };

    const removeSelectedManualMeasurements = () => {
        swal({
            title: t('swal_sure'),
            text: t('pages_groups_groupprojectspage_info'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult && selectedManual?.length > 0) {
                const removed = [];
                let counter = 0;
                selectedManual.forEach((measurement) => {
                    new ManualMeasurementApi().manualMeasurementDeleteManualMeasurement(
                        parseInt(id, 10),
                        measurement.id,
                        function (_, data, response) {
                            counter += 1;
                            if (response.ok) {
                                removed.push(measurement);
                            }
                            if (counter === selectedManual.length) {
                                manualMeasurementsRemoved(removed);
                            }
                        }
                    );
                });
            }
        });
    };

    const manualMeasurementsRemoved = (removed) => {
        const manualNotRemoved = [];
        manual.forEach((m) => {
            if (isNullOrUndefined(removed?.filter((mm) => mm.id === m.id)[0])) {
                manualNotRemoved.push(m);
            }
        });
        setManual(manualNotRemoved);
        setSelectedManual([]);
        setNumSelected(0);
    };

    const selectedRow = (selected, type, id) => {
        if (id === -1) {
            setNumSelected(selected ? manual.length : 0);
            if (selected) {
                setSelectedManual([...manual]);
            } else {
                setSelectedManual([]);
            }
        } else {
            setNumSelected(selected ? numSelected + 1 : numSelected - 1);
            if (selected) {
                const mm = manual?.filter((m) => m.id === id)[0];
                if (!isNullOrUndefined(mm)) {
                    setSelectedManual([...selectedManual, mm]);
                }
            } else {
                setSelectedManual(selectedManual.filter((m) => m.id !== id));
            }
        }
    };

    const closeCreateManualMeasurementModal = () => {
        setCreateManualModal(false);
        setCreationErrorMsg(null);
    };

    const fullGroups = getFullGroups(props.groups, props.site);

    const rows = [];
    manual?.forEach((measurement) => {
        rows.push([
            {
                addCheckbox: true,
                id: measurement.id,
                checked: !isNullOrUndefined(
                    selectedManual?.filter((m) => m.id === measurement.id)[0]
                ),
                text: measurement.measurementTimestamp,
                value: dateToString(measurement.measurementTimestamp),
            },
            {
                text: measurement.measurementPoint?.description,
                value: measurement.measurementPoint?.description,
            },
            {
                link: getProperRoute(`users/${measurement.user?.id}`),
                text: measurement.user?.userName,
                value: measurement.user?.userName,
            },
            { text: measurement.manualValue, value: measurement.manualValue },
            {
                text: measurement.displayReadoutValue,
                value: measurement.displayReadoutValue,
            },
            {
                text: measurement.storedValue.toFixed(2),
                value: measurement.storedValue.toFixed(2),
            },
        ]);
    });

    const mpoints = [];
    mps?.forEach((mp) => {
        mpoints.push({ value: mp.id, description: mp.description });
    });

    return isNullOrUndefined(props.site) ? (
        <></>
    ) : (
        <>
            <CustomBreadcrumbs
                breadcrumbs={[
                    ...fullGroups,
                    {
                        link: getProperRoute(
                            `groups/${groupId}/sites/${id}/settings`
                        ),
                        text: `${props.site.name}`,
                    },
                    {
                        link: getProperRoute('/'),
                        text: t('pagelayout_sidebarsite_manual'),
                    },
                ]}
            />
            <hr /> <br />
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}>
                <span style={{ width: 'inherit', justifySelf: 'flex-start' }}>
                    {t('pagelayout_sidebarsite_manual')}
                </span>
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'analyst')}>
                    {numSelected > 0 ? (
                        <>
                            <MenuItem
                                onClick={() =>
                                    removeSelectedManualMeasurements()
                                }>
                                {t(
                                    'pages_sitesettings_manualmeasurementspage_remove'
                                )}{' '}
                                {numSelected}{' '}
                                {t(
                                    'pages_sitesettings_manualmeasurementspage_manualmeasurements'
                                )}
                            </MenuItem>
                            <hr />
                        </>
                    ) : (
                        <></>
                    )}
                    <MenuItem onClick={() => setCreateManualModal(true)}>
                        {t('pages_sitesettings_manualmeasurementspage_new')}
                    </MenuItem>
                </MoreSettingsMenu>
            </div>
            <FilesTable
                responsive
                sort
                onCheckbox={(selected, type, id) =>
                    selectedRow(selected, type, id)
                }
                columns={[
                    t('pages_sitesettings_createmanualmodal_date'),
                    t('pages_devices_devicepage_mp'),
                    t('pages_sitesettings_manualmeasurementspage_tester'),
                    t('pages_sitesettings_createmanualmodal_manual'),
                    t('pages_sitesettings_createmanualmodal_display'),
                    'AquaCloud Value [ppm]',
                ]}
                rows={rows}
            />
            <CreateManualMeasurementModal
                errorMsg={creationErrorMsg}
                open={createManualModal}
                mpoints={mpoints}
                handleClose={() => closeCreateManualMeasurementModal()}
                confirm={(mm) => createManualMeasurement(mm)}
            />
        </>
    );
}
