import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: {
                    common_filestable_outof: 'out of',
                    common_filestable_previous: 'prev',
                    common_filestable_next: 'next',
                    common_filestable_search: 'Search...',
                    common_filestable_empty: 'No entries were found',
                    common_filestable_namecol: 'Name',
                    swal_sure: 'Are you sure?',
                    swal_cannot_recover_resource:
                        'You will not be able to recover this resource',
                    swal_no: 'No',
                    swal_yes: 'Yes',
                    common_graphcontainer_aggregation: 'Aggregation',
                    common_graphcontainer_average: 'Average',
                    common_graphcontainer_median: 'Median',
                    common_graphcontainer_max: 'Maximum',
                    common_graphcontainer_min: 'Minimum',
                    common_graphcontainer_view: 'View',
                    common_graphcontainer_edit: 'Edit',
                    common_graphcontainer_remove: 'Remove',
                    common_graphcontainer_groups: 'Groups',
                    common_inputdropzone_info:
                        'Drag and drop the file here, or click to select file',
                    common_nodata_nodata: 'No data',
                    common_notfound_return: 'Return to happiness',
                    common_notfound_notfound: 'Not found',
                    common_roundedtext_close: 'close',
                    pagelayout_navbar_projects: 'Projects',
                    pagelayout_navbar_viewallprojects: 'View all Projects',
                    pagelayout_navbar_groups: 'Groups',
                    pagelayout_navbar_viewallgroups: 'View all Groups',
                    pagelayout_navbar_more: 'More',
                    pagelayout_navbar_users: 'Users',
                    pagelayout_navbar_devices: 'Devices',
                    pagelayout_navbar_accounting: 'Accounting',
                    pagelayout_navbar_diagnostics: 'Diagnostics',
                    pagelayout_navbar_editprofile: 'Edit Profile',
                    pagelayout_navbar_signout: 'Sign Out',
                    pagelayout_sidebargroup_groups: 'Groups',
                    pagelayout_sidebargroup_settings: 'Settings',
                    pagelayout_sidebargroup_general: 'General',
                    pagelayout_sidebargroup_projects: 'Projects',
                    pagelayout_sidebargroup_members: 'Members',
                    pagelayout_sidebargroup_devices: 'Devices',
                    pagelayout_sidebarsite_dashboards: 'Dashboards',
                    pagelayout_sidebarsite_manual: 'Manual Measurements',
                    pagelayout_sidebarsite_settings: 'Settings',
                    pagelayout_sidebarsite_general: 'General',
                    pagelayout_sidebarsite_members: 'Members',
                    pagelayout_sidebarsite_gateways: 'Gateways',
                    pagelayout_sidebarsite_alarms: 'Alarms',
                    pages_addgraph_addgraph_new: 'New Graph',
                    pages_addgraph_addgraph_save: 'Save graph changes',
                    pages_addgraph_addgraph_back: 'Go Back',
                    pages_addgraph_addgraph_apply: 'Apply',
                    pages_addgraph_addgraph_settings: 'Settings',
                    pages_addgraph_addgraph_visualization: 'Visualization',
                    pages_addgraph_addgraph_display: 'Display',
                    pages_addgraph_addgraph_examplename: 'Graph Example',
                    pages_addgraph_displaytabbaroptions_linewidth: 'Line Width',
                    pages_addgraph_displaytabbaroptions_fillopacity:
                        'Fill Opacity',
                    pages_addgraph_displaytabbaroptions_barratio: 'Bar Ratio',
                    pages_addgraph_displaytabbaroptions_baralign:
                        'Bar alignment',
                    pages_addgraph_displaytabbaroptions_middle: 'Center',
                    pages_addgraph_displaytabbaroptions_start: 'Start',
                    pages_addgraph_displaytabbaroptions_end: 'End',
                    pages_addgraph_displaytabboxplotoptions_boxwidth:
                        'Box Width',
                    pages_addgraph_displaytabboxplotoptions_whiskerwidth:
                        'Whisker Width',
                    pages_addgraph_displaytablineoptions_usepoints:
                        'Use Points',
                    pages_addgraph_displaytablineoptions_pointwidth:
                        'Point Width',
                    pages_addgraph_displaytablineoptions_fill: 'Fill',
                    pages_addgraph_displaytablineoptions_linewidth:
                        'Line Width',
                    pages_addgraph_displaytablineoptions_interpolation:
                        'Interpolation',
                    pages_addgraph_displaytablineoptions_linear: 'Linear',
                    pages_addgraph_displaytablineoptions_natural: 'Natural',
                    pages_addgraph_displaytablineoptions_step: 'Step',
                    pages_addgraph_displaytablineoptions_basis: 'Basis',
                    pages_addgraph_displaytablineoptions_cardinal: 'Cardinal',
                    pages_addgraph_settingstab_name: 'Graph Name',
                    pages_addgraph_settingstab_placeholder: 'Graph example',
                    pages_addgraph_settingstab_type: 'Type',
                    pages_addgraph_settingstab_temperature: 'Temperature',
                    pages_addgraph_settingstab_chlorine: 'Chlorine',
                    pages_addgraph_settingstab_battery: 'Battery',
                    pages_addgraph_settingstab_gomp:
                        'Group or Measurement Point',
                    pages_addgraph_settingstab_flowrate: 'Flow Rate',
                    pages_addgraph_settingstab_digitalInput: 'Digital Signal',
                    pages_addgraph_visualizationtab_line: 'Line Graph',
                    pages_addgraph_visualizationtab_bar: 'Bar Graph',
                    pages_addgraph_visualizationtab_hbar:
                        'Horizontal Bar Graph',
                    pages_addgraph_visualizationtab_box: 'Box Plot',
                    pages_addgraph_visualizationtab_table: 'Table',
                    pages_dashboard_createdashboardmodal_okerror:
                        'The name of the dashboard is mandatory and must be specified',
                    pages_dashboard_createdashboardmodal_new: 'New Dashboard',
                    pages_dashboard_createdashboardmodal_name: 'Name',
                    pages_dashboard_createdashboardmodal_namelbl:
                        'Name of the dashboard',
                    pages_dashboard_dashboarddatepicker_absolute:
                        'Absolute time range',
                    pages_dashboard_dashboarddatepicker_from: 'From',
                    pages_dashboard_dashboarddatepicker_to: 'To',
                    pages_dashboard_dashboarddatepicker_apply: 'Apply',
                    pages_dashboard_dashboarddatepicker_relative:
                        'Relative time ranges',
                    pages_dashboard_dashboarddatepicker_last12h:
                        'Last 12 hours',
                    pages_dashboard_dashboarddatepicker_last1d: 'Last 1 day',
                    pages_dashboard_dashboarddatepicker_last3d: 'Last 3 days',
                    pages_dashboard_dashboarddatepicker_last1w: 'Last 1 week',
                    pages_dashboard_dashboarddatepicker_last1m: 'Last 1 month',
                    pages_dashboard_dashboarddatepicker_all: 'All time',
                    pages_dashboard_dashboardpage_dashboards: 'Dashboards',
                    pages_dashboard_dashboardpage_protected:
                        'The dashboard is protected and cannot be edited nor deleted',
                    pages_dashboard_dashboardpage_spinnerfetch:
                        'Fetching Data...',
                    pages_dashboard_dashboardpage_create: 'Create graph',
                    pages_dashboard_addgraph_edit: 'Edit Graph',
                    pages_dashboard_dashboardpage_edit: 'Edit Dashboard',
                    pages_dashboard_dashboardpage_delete: 'Delete Dashboard',
                    pages_dashboard_dashboardspage_new: 'New Dashboard',
                    pages_devices_creategatewaymodal_errormsg:
                        'The UUID and the serial number of the gateway are mandatory and must be specified',
                    pages_devices_creategatewaymodal_newgw: 'New Gateway',
                    pages_devices_creategatewaymodal_uuidlbl:
                        'UUID of the gateway',
                    pages_devices_creategatewaymodal_gwsnlbl:
                        'Serial number of the gateway',
                    'pages_devices_creategatewaymodal_gwsn:': 'Serial No.',
                    pages_devices_createsensormodal_errormsg:
                        'The UUID and the serial number of the sensor are mandatory and must be specified',
                    pages_devices_createsensormodal_newsensor: 'New Sensor',
                    pages_devices_createsensormodal_uuidlbl:
                        'UUID of the sensor',
                    pages_devices_createsensormodal_sensorsnlbl:
                        'Serial number of the sensor',
                    pages_devices_createsensormodal_sensorsn: 'Serial No.',
                    pages_devices_createsensormodal_type: 'Type of the sensor',
                    pages_devices_devicepage_acid: 'AquaCloud ID',
                    pages_devices_devicepage_name: 'Name',
                    pages_devices_devicepage_connected: 'Connected',
                    pages_devices_devicepage_uptime: 'Uptime',
                    pages_devices_devicepage_lastactive: 'Last active at',
                    pages_devices_devicepage_assignedto: 'Assigned to Group',
                    pages_devices_devicepage_project: 'Project',
                    pages_devices_devicepage_type: 'Type',
                    pages_devices_devicepage_status: 'Status',
                    pages_devices_devicepage_mp: 'Measurement Point',
                    pages_devices_devicepage_never: 'Never',
                    pages_devices_devicepage_active: 'Active',
                    pages_devices_devicepage_inactive: 'Inactive',
                    pages_devices_devicepage_unassigned: 'Unassigned',
                    pages_devices_devicepage_devices: 'Devices',
                    pages_devices_devicepage_rename: 'Rename Device',
                    pages_devices_devicepage_remove: 'Remove Device',
                    pages_devices_devicepage_remove_confirmation_msg:
                        'You are about to completely remove this device. The device and its data cannot be recovered. To proceed, please confirm the required information.',
                    pages_devices_devicepage_remove_mp_attachment_msg:
                        'This sensor is assigned to a measurement point. If it is deleted, the assignment will be removed and the measurement point will no longer store readings.',
                    pages_devices_devicepage_remove_gw_attachment_msg:
                        'This gateway is assigned to a project. If it is deleted, the assignment will be removed.',
                    pages_devices_devicepage_properties: 'Properties',
                    pages_devices_devicespage_formaterror:
                        'The format of the sensor UUID is not right',
                    pages_devices_devicespage_temperature: 'Temperature',
                    pages_devices_devicespage_chlorine: 'Chlorine',
                    pages_devices_devicespage_dinput: 'Digital Input',
                    pages_devices_devicespage_creategw: 'Create Gateway',
                    pages_devices_devicespage_createsensor: 'Create Sensor',
                    pages_devices_devicespage_assigned: 'Assigned to',
                    pages_devices_renamedevicemodal_device: 'Device',
                    pages_devices_renamedevicemodal_errormsg:
                        'The name of the device is mandatory and must be specified',
                    pages_devices_renamedevicemodal_rename:
                        'New name of the device',
                    pages_diagnostics_diagnosticsgatewaypage_connected:
                        'Connected',
                    pages_diagnostics_diagnosticsgatewaypage_disconnected:
                        'Disconnected',
                    pages_diagnostics_diagnosticsgatewaypage_totalmemory:
                        'Total Memory',
                    pages_diagnostics_diagnosticsgatewaypage_reboots: 'Reboots',
                    pages_diagnostics_diagnosticsgatewaypage_freemem:
                        'Free Memory',
                    pages_diagnostics_diagnosticsgatewaypage_cpuload:
                        'CPU Load',
                    pages_diagnostics_diagnosticsgatewaypage_vpnactive:
                        'VPN was active for',
                    pages_diagnostics_diagnosticsgatewaypage_diagnostics:
                        'Diagnostics',
                    pages_diagnostics_diagnosticsgatewaypage_properties:
                        'Properties',
                    pages_diagnostics_diagnosticsgatewaypage_status: 'Status',
                    pages_diagnostics_diagnosticspage_notattached:
                        'Not attached',
                    pages_diagnostics_diagnosticspage_attached: 'Attached to',
                    pages_diagnostics_diagnosticspage_gwuuid: 'GW UUID',
                    pages_diagnostics_diagnosticspage_serialno: 'Serial No.',
                    pages_diagnostics_diagnosticspage_status: 'Status',
                    pages_diagnostics_diagnosticspage_sensoruuid: 'Sensor UUID',
                    pages_diagnostics_diagnosticspage_nojoin: 'Not joined',
                    pages_diagnostics_diagnosticspage_value: 'Value',
                    pages_diagnostics_diagnosticspage_battery: 'Battery',
                    pages_diagnostics_diagnosticssensorpage_lastrx: 'Last RX',
                    pages_diagnostics_diagnosticssensorpage_lastjoin:
                        'Last Join',
                    pages_diagnostics_diagnosticssensorpage_gateway: 'Gateway',
                    pages_diagnostics_diagnosticssensorpage_halerts:
                        'Health alerts',
                    pages_groups_assigngatewaymodal_errorMsg:
                        'A gateway must be selected',
                    pages_groups_assigngatewaymodal_assign: 'Assign Gateway',
                    pages_groups_assigngatewaymodal_gwassign:
                        'Gateway to assign',
                    pages_groups_assignsensormodal_errorMsg:
                        'A sensor must be selected',
                    pages_groups_assignsensormodal_assign: 'Assign Sensor',
                    pages_groups_assignsensormodal_sensorassign:
                        'Sensor to assign',
                    pages_groups_creategroupmodal_errorMsg:
                        'The name of the group is mandatory and must be specified',
                    pages_groups_creategroupmodal_new: 'New Group',
                    pages_groups_creategroupmodal_namelbl: 'Name of the group',
                    pages_groups_creategroupmodal_name: 'Name',
                    pages_groups_creategroupmodal_desclbl:
                        'Description of the group',
                    pages_groups_creategroupmodal_desc: 'Optional description',
                    pages_groups_gatewaygrouppage_settings: 'Settings',
                    pages_groups_gatewaygrouppage_devices: 'Devices',
                    pages_groups_gatewaygrouppage_gws: 'Gateways',
                    pages_groups_gatewaygrouppage_wlan: 'WLAN Consumption',
                    pages_groups_gatewaygrouppage_eth: 'ETH Consumption',
                    pages_groups_gatewaygrouppage_vpn: 'VPN Status',
                    pages_groups_gatewaygrouppage_uptime: 'Uptime',
                    pages_groups_gatewaygrouppage_cpu: 'CPU Usage',
                    pages_groups_gatewaygrouppage_sw: 'SW Version',
                    pages_groups_gatewaygrouppage_value: 'Value',
                    pages_groups_gatewaygrouppage_remove: 'Remove Gateway',
                    pages_groups_groupmemberspage_info:
                        "These members won't be able to access this group anymore",
                    pages_groups_groupprojectspage_info:
                        'You will not be able to recover these resources',
                    pages_groups_groupspage_groups: 'Groups',
                    pages_groups_groupspage_create: 'Create Group',
                    pages_groups_grouptableentry_subgroups: 'Subgroups',
                    pages_groups_grouptableentry_projects: 'Projects',
                    pages_groups_grouptableentry_members: 'Members',
                    pages_groups_grouptitle_id: 'Group ID',
                    pages_groups_grouptreestructure_empty:
                        'The group must be empty',
                    pages_groups_grouptreestructure_createproject:
                        'Create Project',
                    pages_groups_grouptreestructure_assignmember:
                        'Assign Member',
                    pages_groups_grouptreestructure_assigngw: 'Assign Gateway',
                    pages_groups_grouptreestructure_assignsensor:
                        'Assign Sensor',
                    pages_groups_grouptreestructure_creategroup: 'Create Group',
                    pages_groups_grouptreestructure_move: 'Move',
                    pages_groups_grouptreestructure_projects: 'Project(s)',
                    pages_groups_grouptreestructure_delete: 'Delete',
                    pages_groups_grouptreestructure_remove: 'Remove',
                    pages_groups_grouptreestructure_members: 'Member(s)',
                    pages_groups_grouptreestructure_devices: 'Device(s)',
                    pages_groups_grouptreestructure_groups: 'Group(s)',
                    pages_groups_grouptreestructure_editgroup: 'Edit Group',
                    pages_groups_grouptreestructure_deletegroup: 'Delete Group',
                    pages_groups_movedevicesmodal_move: 'Move Devices',
                    pages_groups_movedevicesmodal_moveto: 'Move devices to',
                    pages_groups_moveprojectsmodal_move: 'Move Projects',
                    pages_groups_moveprojectsmodal_moveto: 'Move projects to',
                    pages_groups_movegroupsmodal_move: 'Move Groups',
                    pages_groups_movegroupsmodal_moveto: 'Move groups to',
                    pages_login_forgotpasswordmodal_info:
                        "We've sent you an email with the token that authorizes the password change. Fill in the form below to reset your password.",
                    pages_login_forgotpasswordmodal_reset: 'Reset Password',
                    pages_login_forgotpasswordmodal_email: 'Your email',
                    pages_login_forgotpasswordmodal_newpass: 'New password',
                    pages_login_forgotpasswordmodal_repeatpass:
                        'Repeat new password',
                    pages_login_forgotpasswordmodal_token: 'Token',
                    pages_login_forgotpasswordmodal_resetbtn: 'Reset',
                    pages_login_forgotpasswordmodal_verifyinputs:
                        'The password cannot be reset. Verify your inputs',
                    pages_login_forgotpasswordmodal_sendtoken: 'Send Token',
                    pages_login_loginpage_baddata:
                        'You have entered an invalid username or password',
                    pages_login_loginpage_required: 'All fields are required',
                    pages_login_loginpage_username: 'Username',
                    pages_login_loginpage_password: 'Password',
                    pages_login_loginpage_forgot: 'Forgot password ?',
                    pages_login_loginpage_login: 'Login',
                    pages_profile_profilepage_cannotchange:
                        'The password could not be changed',
                    pages_profile_profilepage_profile: 'Profile',
                    pages_profile_profilepage_username: 'User Name',
                    pages_profile_profilepage_email: 'Email',
                    pages_profile_profilepage_tz: 'Time Zone',
                    pages_profile_profilepage_lang: 'Language',
                    pages_profile_profilepage_save: 'Save profile changes',
                    pages_profile_profilepage_savechanges: 'Save Changes',
                    pages_profile_profilepage_change: 'Change Password',
                    pages_profile_profilepage_old: 'Old Password',
                    pages_profile_profilepage_new: 'New Password',
                    pages_profile_profilepage_confirm: 'Confirm New Password',
                    pages_viewgraph_viewgraph_back:
                        'Go Back to Dashboards Page',
                    pages_users_createusermodal_bademail:
                        'The format of the email is not right',
                    pages_users_createusermodal_badpassword:
                        'Your password must be at least 8 characters long, contain at least one number, one special character and have a mixture of uppercase and lowercase letters.',
                    pages_users_createusermodal_allfields:
                        'All fields must be specified',
                    pages_users_createusermodal_newuser: 'New User',
                    pages_users_createusermodal_user: 'User',
                    pages_users_createusermodal_role: 'Role',
                    pages_users_userentrytable_unknown: 'Unknown',
                    pages_users_userpage_membersince: 'Member Since',
                    pages_users_userpage_lastsignin: 'Last sign-in at',
                    pages_users_userpage_createdby: 'Created By',
                    pages_users_userpage_users: 'Users',
                    pages_users_userpage_profile: 'Profile',
                    pages_users_userpage_gp: 'Groups & Projects',
                    pages_users_userpage_accessto: 'Access to',
                    pages_users_userpage_projects: 'projects',
                    pages_users_userspage_create: 'Create User',
                    pages_users_userspage_createdon: 'Created on',
                    pages_users_userspage_lastactivity: 'Last activity',
                    pages_sitesettings_alarmentrypage_active: 'Is active?',
                    pages_sitesettings_alarmentrypage_lower: 'Lower Limit',
                    pages_sitesettings_alarmentrypage_upper: 'Upper Limit',
                    pages_sitesettings_alarmentrypage_hlow: 'Lower Hysteresis',
                    pages_sitesettings_alarmentrypage_hupp: 'Upper Hysteresis',
                    pages_sitesettings_alarmentrypage_from: 'Activatable from',
                    pages_sitesettings_alarmentrypage_to: 'Activatable until',
                    pages_sitesettings_alarmentrypage_alarms: 'Alarms',
                    pages_sitesettings_alarmentrypage_edit: 'Edit Alarm',
                    pages_sitesettings_alarmentrypage_delete: 'Delete Alarm',
                    pages_sitesettings_alarmentrypage_new: 'New Alarm',
                    pages_sitesettings_alarmentrypage_remove: 'Remove',
                    pages_sitesettings_alarmentrypage_alarmslower: 'alarms',
                    pages_sitesettings_alarmentrypage_fromtxt: 'From',
                    pages_sitesettings_alarmentrypage_totxt: 'To',
                    pages_sitesettings_alarmentrypage_activetxt: 'Active',
                    pages_sitesettings_alarmentrypage_watchers: 'Watchers',
                    pages_sitesettings_alarmentrypage_addwatcher: 'Add Watcher',
                    pages_sitesettings_assigngatewaymodal_errortxt:
                        'A gateway must be selected',
                    pages_sitesettings_assigngatewaymodal_assign:
                        'Assign Gateway',
                    pages_sitesettings_assigngatewaymodal_assigngw:
                        'Assign gateway to this project',
                    pages_sitesettings_assignsensormodal_errortxt:
                        'A sensor must be selected',
                    pages_sitesettings_assignsensormodal_assign:
                        'Assign Sensor',
                    pages_sitesettings_assignsensormodal_assignsensor:
                        'Assign a sensor to this measurement point',
                    pages_sitesettings_assignusermodal_errortxt:
                        'A user must be selected',
                    pages_sitesettings_assignusermodal_assign: 'Assign User',
                    pages_sitesettings_assignusermodal_assignuser:
                        'Assign a user to this project',
                    pages_sitesettings_backupgenerationmodal_generation:
                        'Backup Generation',
                    pages_sitesettings_backupgenerationmodal_backuptxt:
                        'The backup of this project is being generated. Keep this dialogue open until the generation is finished. Then click on the download button.',
                    pages_sitesettings_backupgenerationmodal_download:
                        'Download',
                    pages_sitesettings_createalarmmodal_limiterror:
                        'The lower und upper limits (and hysteresis values) must be numbers',
                    pages_sitesettings_createalarmmodal_nameerror:
                        'The name of the alarm is mandatory and must be specified',
                    pages_sitesettings_createalarmmodal_new: 'New Alarm',
                    pages_sitesettings_createmanualmodal_errortxt:
                        'The manual value and display readout must be numbers',
                    pages_sitesettings_createmanualmodal_new:
                        'New Manual Measurement',
                    pages_sitesettings_createmanualmodal_date: 'Date',
                    pages_sitesettings_createmanualmodal_manual:
                        'Manual Value [ppm]',
                    pages_sitesettings_createmanualmodal_display:
                        'Display Readout [ppm]',
                    pages_sitesettings_creatempmodal_errortxt:
                        'The name of the measurement point is mandatory and must be specified',
                    pages_sitesettings_creatempmodal_new:
                        'New Measurement Point',
                    pages_sitesettings_creatempmodal_namelbl:
                        'Name of the measurement point',
                    pages_sitesettings_createsitegroupmodal_errortxt:
                        'The name of the group is mandatory and must be specified',
                    pages_sitesettings_createsitegroupmodal_new: 'New Group',
                    pages_sitesettings_createsitegroupmodal_namelbl:
                        'Name of the group',
                    pages_sitesettings_createsitemainpagemodal_errortxt:
                        'The name of the project is mandatory and must be specified',
                    pages_sitesettings_createsitemainpagemodal_new:
                        'New Project',
                    pages_sitesettings_createsitemainpagemodal_namelbl:
                        'Name of the project',
                    pages_sitesettings_createsitemainpagemodal_descr:
                        'Description',
                    pages_sitesettings_createsitemainpagemodal_descrlbl:
                        'Description of the project',
                    pages_sitesettings_createsitemainpagemodal_attach:
                        'Attach project to',
                    pages_sitesettings_gatewayentrypage_sure:
                        'The gateway will be removed from this project',
                    pages_sitesettings_gatewayentrypage_remove:
                        'Remove Gateway',
                    pages_sitesettings_gatewayentrypage_openvpn:
                        'Open VPN Tunnel',
                    pages_sitesettings_gatewayentrypage_closevpn:
                        'Close VPN Tunnel',
                    pages_sitesettings_gatewayentrypage_update:
                        'Update Firmware',
                    pages_sitesettings_gatewayssitepage_assign:
                        'Assign Gateway',
                    pages_sitesettings_importbackupmodal_import:
                        'Import Backup',
                    pages_sitesettings_importbackupmodal_include:
                        'Include Data',
                    pages_sitesettings_importbackupmodal_files:
                        'Files to import',
                    pages_sitesettings_importbackupmodal_import: 'Import',
                    pages_sitesettings_manualmeasurementspage_tester: 'Tester',
                    pages_sitesettings_manualmeasurementspage_acvalue:
                        'AquaCloud Value [ppm]',
                    pages_sitesettings_manualmeasurementspage_remove: 'Remove',
                    pages_sitesettings_manualmeasurementspage_manualmeasurements:
                        'manual measurements',
                    pages_sitesettings_manualmeasurementspage_new:
                        'New manual measurement',
                    pages_sitesettings_measurementpointpage_errortxt:
                        'This measurement point will stop measuring data',
                    pages_sitesettings_measurementpointpage_incomplete:
                        'incomplete',
                    pages_sitesettings_measurementpointpage_active: 'active',
                    pages_sitesettings_measurementpointpage_inactive:
                        'inactive',
                    pages_sitesettings_measurementpointpage_assign:
                        'Assign Sensor',
                    pages_sitesettings_measurementpointpage_removesensor:
                        'Remove Sensor',
                    pages_sitesettings_measurementpointpage_editmp: 'Edit MP',
                    pages_sitesettings_measurementpointpage_deletemp:
                        'Delete MP',
                    pages_sitesettings_measurementpointpage_lasttemp:
                        'Last Temperature',
                    pages_sitesettings_measurementpointpage_lastbatt:
                        'Last Battery',
                    pages_sitesettings_measurementpointpage_lastmeas:
                        'Last Measurement At',
                    pages_sitesettings_memberentrypage_errortxt:
                        "This user won't have access to this project anymore",
                    pages_sitesettings_memberentrypage_remove: 'Remove Member',
                    pages_sitesettings_memberentrypage_created: 'Created At',
                    pages_sitesettings_memberentrypage_lastlogin: 'Last Login',
                    pages_sitesettings_movempgroupsmodal_move: 'Move Items',
                    pages_sitesettings_movempgroupsmodal_new: 'New group',
                    pages_sitesettings_sitesettingspage_empty:
                        'The group must be empty',
                    pages_sitesettings_sitesettingspage_confirmation:
                        'All gateways will be detached from the project and all sensors will be removed from the measurement points.',
                    pages_sitesettings_sitesettingspage_createmp:
                        'Create Measurement Point',
                    pages_sitesettings_sitesettingspage_movesel: 'Move',
                    pages_sitesettings_sitesettingspage_moveitems: 'Item(s)',
                    pages_sitesettings_sitesettingspage_deletesel: 'Delete',
                    pages_sitesettings_sitesettingspage_deleteitems: 'Item(s)',
                    pages_sitesettings_sitesettingspage_editproj:
                        'Edit Project',
                    pages_sitesettings_sitesettingspage_backup:
                        'Generate Backup',
                    pages_sitesettings_sitesettingspage_free:
                        'Free all Devices',
                    pages_sitesettings_sitesettingspage_deleteproj:
                        'Delete Project',
                    pages_sitesettings_sitesettingspage_editgroup: 'Edit Group',
                    pages_sitesettings_sitesettingspage_delgroup:
                        'Delete Group',
                    pages_sitesettings_sitesettingspage_sensor: 'Sensor',
                    pages_sitesettings_sitetableentry_updated: 'Updated',
                    pages_sitesettings_sitetitle_projid: 'Project ID',
                    pages_sitesettings_sitetitle_installationmode:
                        'Installation Mode',
                    pages_sitesettings_sitetitle_start1: 'Start',
                    pages_sitesettings_sitetitle_stop1: 'Stop',
                    pages_sitesettings_sitetitle_start2: '',
                    pages_sitesettings_sitetitle_stop2: '',
                    pages_sitesettings_sitetitle_warntext:
                        'The sensors will reduce their sampling interval to 1 minute. If the installation mode is active for too long, the battery of the sensors will degrade prematurely.',
                    modal_edit: 'Edit',
                    modal_create: 'Create',
                    modal_cancel: 'Cancel',
                    modal_rename: 'Rename',
                    modal_assign: 'Assign',
                    modal_move: 'Move',
                    util_lastupdated_first: '',
                    util_lastupdated_second: 'ago',
                    util_lastupdate_seconds: 'seconds',
                    util_lastupdate_minutes: 'minutes',
                    util_lastupdate_hours: 'hours',
                    util_lastupdate_days: 'days',
                    util_lastupdate_months: 'months',
                    util_lastupdate_years: 'years',
                },
            },

            de: {
                translations: {
                    common_filestable_outof: 'von',
                    common_filestable_previous: 'zurück',
                    common_filestable_next: 'weiter',
                    common_filestable_search: 'Suche...',
                    common_filestable_empty:
                        'Es wurden keine Einträge gefunden',
                    common_filestable_namecol: 'Name',
                    swal_sure: 'Sind Sie sicher?',
                    swal_cannot_recover_resource:
                        'You will not be able to recover this resource',
                    swal_no: 'Nein',
                    swal_yes: 'Ja',
                    common_graphcontainer_aggregation: 'Aggregation',
                    common_graphcontainer_average: 'Durchschnitt',
                    common_graphcontainer_median: 'Median',
                    common_graphcontainer_max: 'Maximum',
                    common_graphcontainer_min: 'Minimum',
                    common_graphcontainer_view: 'Ansehen',
                    common_graphcontainer_edit: 'Bearbeiten',
                    common_graphcontainer_remove: 'Entfernen',
                    common_graphcontainer_groups: 'Gruppen',
                    common_inputdropzone_info:
                        'Ziehen Sie die Datei hierher oder klicken Sie, um sie auszuwählen',
                    common_nodata_nodata: 'Keine Daten',
                    common_notfound_return: 'Zurück ins Glück',
                    common_notfound_notfound: 'Nicht gefunden',
                    common_roundedtext_close: 'Schließen',
                    pagelayout_navbar_projects: 'Projekte',
                    pagelayout_navbar_viewallprojects: 'Alle Projekte',
                    pagelayout_navbar_groups: 'Gruppen',
                    pagelayout_navbar_viewallgroups: 'Alle Gruppen',
                    pagelayout_navbar_more: 'Mehr',
                    pagelayout_navbar_users: 'Benutzer',
                    pagelayout_navbar_devices: 'Geräte',
                    pagelayout_navbar_accounting: 'Abrechnung',
                    pagelayout_navbar_diagnostics: 'Diagnose',
                    pagelayout_navbar_editprofile: 'Profil bearbeiten',
                    pagelayout_navbar_signout: 'Abmelden',
                    pagelayout_sidebargroup_groups: 'Gruppen',
                    pagelayout_sidebargroup_settings: 'Einstellungen',
                    pagelayout_sidebargroup_general: 'Allgemein',
                    pagelayout_sidebargroup_projects: 'Projekte',
                    pagelayout_sidebargroup_members: 'Mitglieder',
                    pagelayout_sidebargroup_devices: 'Geräte',
                    pagelayout_sidebarsite_dashboards: 'Dashboards',
                    pagelayout_sidebarsite_manual: 'Manuelle Messungen',
                    pagelayout_sidebarsite_settings: 'Einstellungen',
                    pagelayout_sidebarsite_general: 'Allgemein',
                    pagelayout_sidebarsite_members: 'Mitglieder',
                    pagelayout_sidebarsite_gateways: 'Gateways',
                    pagelayout_sidebarsite_alarms: 'Alarme',
                    pages_addgraph_addgraph_new: 'Neuer Graph',
                    pages_addgraph_addgraph_save:
                        'Diagrammänderungen speichern',
                    pages_addgraph_addgraph_back: 'Zurück',
                    pages_addgraph_addgraph_apply: 'Anwenden',
                    pages_addgraph_addgraph_settings: 'Einstellungen',
                    pages_addgraph_addgraph_visualization: 'Visualisierung',
                    pages_addgraph_addgraph_display: 'Anzeige',
                    pages_addgraph_addgraph_examplename: 'Beispielgraph',
                    pages_addgraph_displaytabbaroptions_linewidth:
                        'Linienbreite',
                    pages_addgraph_displaytabbaroptions_fillopacity:
                        'Deckkraft',
                    pages_addgraph_displaytabbaroptions_barratio:
                        'Balkenverhältnis',
                    pages_addgraph_displaytabbaroptions_baralign:
                        'Balkenausrichtung',
                    pages_addgraph_displaytabbaroptions_middle: 'Zentriert',
                    pages_addgraph_displaytabbaroptions_start: 'Start',
                    pages_addgraph_displaytabbaroptions_end: 'Ende',
                    pages_addgraph_displaytabboxplotoptions_boxwidth:
                        'Boxenbreite',
                    pages_addgraph_displaytabboxplotoptions_whiskerwidth:
                        'Whiskerbreite',
                    pages_addgraph_displaytablineoptions_usepoints:
                        'Punkte Benutzen',
                    pages_addgraph_displaytablineoptions_pointwidth:
                        'Punktgröße',
                    pages_addgraph_displaytablineoptions_fill: 'Füllen',
                    pages_addgraph_displaytablineoptions_linewidth:
                        'Linienbreite',
                    pages_addgraph_displaytablineoptions_interpolation:
                        'Interpolation',
                    pages_addgraph_displaytablineoptions_linear: 'Linear',
                    pages_addgraph_displaytablineoptions_natural: 'Natürlich',
                    pages_addgraph_displaytablineoptions_step: 'Stufen',
                    pages_addgraph_displaytablineoptions_basis: 'Basis',
                    pages_addgraph_displaytablineoptions_cardinal: 'Kardinal',
                    pages_addgraph_settingstab_name: 'Diagrammname',
                    pages_addgraph_settingstab_placeholder: 'Beispielgraph',
                    pages_addgraph_settingstab_type: 'Typ',
                    pages_addgraph_settingstab_temperature: 'Temperatur',
                    pages_addgraph_settingstab_chlorine: 'Chlor',
                    pages_addgraph_settingstab_battery: 'Batterie',
                    pages_addgraph_settingstab_gomp: 'Gruppe oder Messpunkt',
                    pages_addgraph_settingstab_flowrate: 'Durchfluss',
                    pages_addgraph_settingstab_digitalInput: 'Digitales Signal',
                    pages_addgraph_visualizationtab_line: 'Liniendiagramm',
                    pages_addgraph_visualizationtab_bar: 'Säulendiagramm',
                    pages_addgraph_visualizationtab_hbar:
                        'Horizontales Balkendiagramm',
                    pages_addgraph_visualizationtab_box: 'Boxplot',
                    pages_addgraph_visualizationtab_table: 'Tabelle',
                    pages_dashboard_createdashboardmodal_okerror:
                        'Der Dashboardname ist muss ausgefüllt werden',
                    pages_dashboard_createdashboardmodal_new: 'Neues Dashboard',
                    pages_dashboard_createdashboardmodal_name: 'Name',
                    pages_dashboard_createdashboardmodal_namelbl:
                        'Name des Dashboards',
                    pages_dashboard_dashboarddatepicker_absolute:
                        'Absolute Zeitspanne',
                    pages_dashboard_dashboarddatepicker_from: 'Von',
                    pages_dashboard_dashboarddatepicker_to: 'Bis',
                    pages_dashboard_dashboarddatepicker_apply: 'Anwenden',
                    pages_dashboard_dashboarddatepicker_relative:
                        'Relative Zeitspannen',
                    pages_dashboard_dashboarddatepicker_last12h:
                        'Letzte 12 Stunden',
                    pages_dashboard_dashboarddatepicker_last1d: 'Letzter Tag',
                    pages_dashboard_dashboarddatepicker_last3d: 'Letzte 3 Tage',
                    pages_dashboard_dashboarddatepicker_last1w: 'Letzte Woche',
                    pages_dashboard_dashboarddatepicker_last1m: 'Letzter Monat',
                    pages_dashboard_dashboarddatepicker_all: 'Alles',
                    pages_dashboard_dashboardpage_dashboards: 'Dashboards',
                    pages_dashboard_dashboardpage_protected:
                        'Dieses Dashboard ist geschützt und kann nicht verändert oder gelöscht werden',
                    pages_dashboard_dashboardpage_spinnerfetch:
                        'Daten abrufen...',
                    pages_dashboard_dashboardpage_create: 'Graph erstellen',
                    pages_dashboard_addgraph_edit: 'Graph Bearbeiten',
                    pages_dashboard_dashboardpage_edit: 'Dashboard Bearbeiten',
                    pages_dashboard_dashboardpage_delete: 'Dashboard löschen',
                    pages_dashboard_dashboardspage_new: 'Neues Dashboard',
                    pages_devices_creategatewaymodal_errormsg:
                        'Die UUID und die Seriennummer des Gateways sind erforderlich und müssen eingetragen werden',
                    pages_devices_creategatewaymodal_newgw: 'Neues Gateway',
                    pages_devices_creategatewaymodal_uuidlbl:
                        'UUID des Gateways',
                    pages_devices_creategatewaymodal_gwsnlbl:
                        'Seriennummer des Gateways',
                    'pages_devices_creategatewaymodal_gwsn:': 'Seriennr.',
                    pages_devices_createsensormodal_errormsg:
                        'Die UUID und die Seriennummer des Sensors sind erforderlich und müssen eingetragen werden',
                    pages_devices_createsensormodal_newsensor: 'Neuer Sensor',
                    pages_devices_createsensormodal_uuidlbl: 'UUID des Sensors',
                    pages_devices_createsensormodal_sensorsnlbl:
                        'Seriennummer des Sensors',
                    pages_devices_createsensormodal_sensorsn: 'Seriennr.',
                    pages_devices_createsensormodal_type: 'Typ des Sensors',
                    pages_devices_devicepage_acid: 'AquaCloud ID',
                    pages_devices_devicepage_name: 'Name',
                    pages_devices_devicepage_connected: 'Verbunden',
                    pages_devices_devicepage_uptime: 'Betriebszeit',
                    pages_devices_devicepage_lastactive: 'Zuletzt aktiv',
                    pages_devices_devicepage_assignedto:
                        'Zugewiesen zur Gruppe',
                    pages_devices_devicepage_project: 'Projekt',
                    pages_devices_devicepage_type: 'Typ',
                    pages_devices_devicepage_status: 'Status',
                    pages_devices_devicepage_mp: 'Messpunkt',
                    pages_devices_devicepage_never: 'Nie',
                    pages_devices_devicepage_active: 'Aktiv',
                    pages_devices_devicepage_inactive: 'Inaktiv',
                    pages_devices_devicepage_unassigned: 'Nicht zugewiesen',
                    pages_devices_devicepage_devices: 'Geräte',
                    pages_devices_devicepage_rename: 'Gerät Umbenennen',
                    pages_devices_devicepage_remove: 'Gerät Entfernen',
                    pages_devices_devicepage_remove_confirmation_msg:
                        'Sie sind im Begriff das Gerät komplett zu entfernen. Weder das Gerät noch seine Daten können wiederhergestellt werden. Um fortzufahren, bestätigen Sie bitte die notwendigen Information.',
                    pages_devices_devicepage_remove_mp_attachment_msg:
                        'Dieser Sensor ist einem Messpunkt zugeordnet. Sollte er gelöscht werden, so wird die Zuordnung gelöscht und der Messpunkt speichert keine Daten mehr.',
                    pages_devices_devicepage_remove_gw_attachment_msg:
                        'Dieses Gateway is einem Projekt zugeordnet. Wird es gelöscht, so wird die Zuordnung ebenso gelöscht.',
                    pages_devices_devicepage_properties: 'Eigenschaften',
                    pages_devices_devicespage_formaterror:
                        'Die Sensor UUID ist ungültig',
                    pages_devices_devicespage_temperature: 'Temperatur',
                    pages_devices_devicespage_chlorine: 'Chlor',
                    pages_devices_devicespage_dinput: 'Digitaler Eingang',
                    pages_devices_devicespage_creategw: 'Gateway Erstellen',
                    pages_devices_devicespage_createsensor: 'Sensor Erstellen',
                    pages_devices_devicespage_assigned: 'Zugewiesen zu',
                    pages_devices_renamedevicemodal_device: 'Gerät',
                    pages_devices_renamedevicemodal_errormsg:
                        'Der Gerätename ist erforderlich und muss ausgefüllt sein',
                    pages_devices_renamedevicemodal_rename:
                        'Neuer Name des Geräts',
                    pages_diagnostics_diagnosticsgatewaypage_connected:
                        'Verbunden',
                    pages_diagnostics_diagnosticsgatewaypage_disconnected:
                        'Getrennt',
                    pages_diagnostics_diagnosticsgatewaypage_totalmemory:
                        'Gesamtspeicher',
                    pages_diagnostics_diagnosticsgatewaypage_reboots:
                        'Neustarts',
                    pages_diagnostics_diagnosticsgatewaypage_freemem:
                        'Freier Speicher',
                    pages_diagnostics_diagnosticsgatewaypage_cpuload:
                        'CPU Auslastung',
                    pages_diagnostics_diagnosticsgatewaypage_vpnactive:
                        'VPN war aktiv für',
                    pages_diagnostics_diagnosticsgatewaypage_diagnostics:
                        'Diagnose',
                    pages_diagnostics_diagnosticsgatewaypage_properties:
                        'Eigenschaften',
                    pages_diagnostics_diagnosticsgatewaypage_status: 'Status',
                    pages_diagnostics_diagnosticspage_notattached:
                        'Nicht eingebaut',
                    pages_diagnostics_diagnosticspage_attached: 'Eingebaut an',
                    pages_diagnostics_diagnosticspage_gwuuid: 'GW UUID',
                    pages_diagnostics_diagnosticspage_serialno: 'Seriennr.',
                    pages_diagnostics_diagnosticspage_status: 'Status',
                    pages_diagnostics_diagnosticspage_sensoruuid: 'Sensor UUID',
                    pages_diagnostics_diagnosticspage_nojoin: 'Nicht verbunden',
                    pages_diagnostics_diagnosticspage_value: 'Wert',
                    pages_diagnostics_diagnosticspage_battery: 'Batterie',
                    pages_diagnostics_diagnosticssensorpage_lastrx:
                        'Letzte empfangene Nachricht',
                    pages_diagnostics_diagnosticssensorpage_lastjoin:
                        'Letzte Verbindung',
                    pages_diagnostics_diagnosticssensorpage_gateway: 'Gateway',
                    pages_diagnostics_diagnosticssensorpage_halerts:
                        'Betriebswarnungen',
                    pages_groups_assigngatewaymodal_errorMsg:
                        'Ein Gateway muss ausgewählt werden',
                    pages_groups_assigngatewaymodal_assign: 'Gateway Zuweisen',
                    pages_groups_assigngatewaymodal_gwassign:
                        'Gateway zum Zuweisen',
                    pages_groups_assignsensormodal_errorMsg:
                        'Ein Sensor muss ausgewählt werden',
                    pages_groups_assignsensormodal_assign: 'Sensor Zuweisen',
                    pages_groups_assignsensormodal_sensorassign:
                        'Sensor zum Zuweisen',
                    pages_groups_creategroupmodal_errorMsg:
                        'Der Gruppenname ist erforderlich und muss ausgefüllt werden',
                    pages_groups_creategroupmodal_new: 'Neue Gruppe',
                    pages_groups_creategroupmodal_namelbl: 'Name der Gruppe',
                    pages_groups_creategroupmodal_name: 'Name',
                    pages_groups_creategroupmodal_desclbl:
                        'Beschreibung der Gruppe',
                    pages_groups_creategroupmodal_desc:
                        'Optionale Beschreibung',
                    pages_groups_gatewaygrouppage_settings: 'Einstellungen',
                    pages_groups_gatewaygrouppage_devices: 'Geräte',
                    pages_groups_gatewaygrouppage_gws: 'Gateways',
                    pages_groups_gatewaygrouppage_wlan: 'WLAN Nutzung',
                    pages_groups_gatewaygrouppage_eth: 'ETH Nutzung',
                    pages_groups_gatewaygrouppage_vpn: 'VPN Status',
                    pages_groups_gatewaygrouppage_uptime: 'Betriebszeit',
                    pages_groups_gatewaygrouppage_cpu: 'CPU Auslastung',
                    pages_groups_gatewaygrouppage_sw: 'SW Version',
                    pages_groups_gatewaygrouppage_value: 'Wert',
                    pages_groups_gatewaygrouppage_remove: 'Gateway Entfernen',
                    pages_groups_groupmemberspage_info:
                        'Diese Nutzer werden keinen Zugriff mehr auf diese Gruppe haben',
                    pages_groups_groupprojectspage_info:
                        'Sie werden diese Objekte nicht wiederherstellen können',
                    pages_groups_groupspage_groups: 'Gruppen',
                    pages_groups_groupspage_create: 'Gruppe Erstellen',
                    pages_groups_grouptableentry_subgroups: 'Untergruppen',
                    pages_groups_grouptableentry_projects: 'Projekte',
                    pages_groups_grouptableentry_members: 'Mitglieder',
                    pages_groups_grouptitle_id: 'Gruppen ID',
                    pages_groups_grouptreestructure_empty:
                        'Die Gruppe muss leer sein',
                    pages_groups_grouptreestructure_createproject:
                        'Projekt Erstellen',
                    pages_groups_grouptreestructure_assignmember:
                        'Benutzer Zuweisen',
                    pages_groups_grouptreestructure_assigngw:
                        'Gateway Zuweisen',
                    pages_groups_grouptreestructure_assignsensor:
                        'Sensor Zuweisen',
                    pages_groups_grouptreestructure_creategroup:
                        'Gruppe Erstellen',
                    pages_groups_grouptreestructure_move: 'Verschieben',
                    pages_groups_grouptreestructure_projects: 'Projekt(e)',
                    pages_groups_grouptreestructure_delete: 'Löschen',
                    pages_groups_grouptreestructure_remove: 'Entfernen',
                    pages_groups_grouptreestructure_members: 'Mitglied(er)',
                    pages_groups_grouptreestructure_devices: 'Gerät(e)',
                    pages_groups_grouptreestructure_groups: 'Grouppe(n)',
                    pages_groups_grouptreestructure_editgroup:
                        'Gruppe Bearbeiten',
                    pages_groups_grouptreestructure_deletegroup:
                        'Gruppe Löschen',
                    pages_groups_movedevicesmodal_move: 'Geräte Verschieben',
                    pages_groups_movedevicesmodal_moveto:
                        'Geräte verschieben nach',
                    pages_groups_moveprojectsmodal_move: 'Projekte Verschieben',
                    pages_groups_moveprojectsmodal_moveto:
                        'Projekte verschieben nach',
                    pages_groups_movegroupsmodal_move: 'Gruppen Verschieben',
                    pages_groups_movegroupsmodal_moveto:
                        'Gruppen verschieben nach',
                    pages_login_forgotpasswordmodal_info:
                        'Wir haben Ihnen eine E-Mail mit einem Token zur Authorisierung der Passwortänderung geschickt. Füllen Sie das Formular unten aus um Ihr Passwort zurückzusetzen.',
                    pages_login_forgotpasswordmodal_reset:
                        'Passwort Zurücksetzen',
                    pages_login_forgotpasswordmodal_email:
                        'Ihre E-Mail Adresse',
                    pages_login_forgotpasswordmodal_newpass: 'Neues Passwort',
                    pages_login_forgotpasswordmodal_repeatpass:
                        'Neues Passwort bestätigen',
                    pages_login_forgotpasswordmodal_token: 'Token',
                    pages_login_forgotpasswordmodal_resetbtn: 'Zurücksetzen',
                    pages_login_forgotpasswordmodal_sendtoken:
                        'Token Abschicken',
                    pages_login_forgotpasswordmodal_verifyinputs:
                        'Das Passwort kann nicht zurückgesetzt werden. Überprüfen Sie Ihre Eingaben',
                    pages_login_loginpage_baddata:
                        'Sie haben einen falschen Benutzernamen/Passwort eingegeben',
                    pages_login_loginpage_required:
                        'Alle Felder sind erforderlich',
                    pages_login_loginpage_username: 'Benutzername',
                    pages_login_loginpage_password: 'Passwort',
                    pages_login_loginpage_forgot: 'Passwort vergessen?',
                    pages_login_loginpage_login: 'Anmeldung',
                    pages_profile_profilepage_cannotchange:
                        'Das Passwort konnte nicht geändert werden',
                    pages_profile_profilepage_profile: 'Profil',
                    pages_profile_profilepage_username: 'Benutzername',
                    pages_profile_profilepage_email: 'E-Mail',
                    pages_profile_profilepage_tz: 'Zeitzone',
                    pages_profile_profilepage_lang: 'Sprache',
                    pages_profile_profilepage_save:
                        'Profiländerungen Speichern',
                    pages_profile_profilepage_savechanges:
                        'Änderungen Speichern',
                    pages_profile_profilepage_change: 'Passwort Ändern',
                    pages_profile_profilepage_old: 'Altes Passwort',
                    pages_profile_profilepage_new: 'Neues Passwort',
                    pages_profile_profilepage_confirm:
                        'Neues Passwort bestätigen',
                    pages_viewgraph_viewgraph_back:
                        'Zurück zur Dashboard Seite',
                    pages_users_createusermodal_bademail:
                        'Die E-Mail Adresse ist nicht gültig',
                    pages_users_createusermodal_badpassword:
                        'Ihr Password muss mindestens 8 Zeichen lang sein, eine Zahl, Groß- und Kleinbuchstaben sowie ein Sonderzeichen enthalten.',
                    pages_users_createusermodal_allfields:
                        'Alle Felder müssen ausgefüllt werden',
                    pages_users_createusermodal_newuser: 'Neuer Benutzer',
                    pages_users_createusermodal_user: 'Benutzer',
                    pages_users_createusermodal_role: 'Rolle',
                    pages_users_userentrytable_unknown: 'Unbekannt',
                    pages_users_userpage_membersince: 'Mitglied seit',
                    pages_users_userpage_lastsignin: 'Letze Anmeldung',
                    pages_users_userpage_createdby: 'Erstellt von',
                    pages_users_userpage_users: 'Benutzer',
                    pages_users_userpage_profile: 'Profil',
                    pages_users_userpage_gp: 'Gruppen & Projekte',
                    pages_users_userpage_accessto: 'Zugriff auf',
                    pages_users_userpage_projects: 'Projekte',
                    pages_users_userspage_create: 'Benutzer erstellen',
                    pages_users_userspage_createdon: 'Erstellt am',
                    pages_users_userspage_lastactivity: 'Letzte Aktivität',
                    pages_sitesettings_alarmentrypage_active: 'Ist aktiv?',
                    pages_sitesettings_alarmentrypage_lower: 'Unteres Limit',
                    pages_sitesettings_alarmentrypage_upper: 'Oberes Limit',
                    pages_sitesettings_alarmentrypage_hlow: 'Untere Hysterese',
                    pages_sitesettings_alarmentrypage_hupp: 'Obere Hysterese',
                    pages_sitesettings_alarmentrypage_from: 'Aktivierbar von',
                    pages_sitesettings_alarmentrypage_to: 'Aktivierbar bis',
                    pages_sitesettings_alarmentrypage_alarms: 'Alarme',
                    pages_sitesettings_alarmentrypage_edit: 'Alarm bearbeiten',
                    pages_sitesettings_alarmentrypage_delete: 'Alarm löschen',
                    pages_sitesettings_alarmentrypage_new: 'Neuer Alarm',
                    pages_sitesettings_alarmentrypage_remove: 'Entfernen',
                    pages_sitesettings_alarmentrypage_alarmslower: 'Alarme',
                    pages_sitesettings_alarmentrypage_fromtxt: 'Von',
                    pages_sitesettings_alarmentrypage_totxt: 'Bis',
                    pages_sitesettings_alarmentrypage_activetxt: 'Aktiv',
                    pages_sitesettings_alarmentrypage_watchers: 'Beobachter',
                    pages_sitesettings_alarmentrypage_addwatcher:
                        'Beobachter hinzufügen',
                    pages_sitesettings_assigngatewaymodal_errortxt:
                        'Ein Gateway muss ausgewählt werden',
                    pages_sitesettings_assigngatewaymodal_assign:
                        'Gateway zuweisen',
                    pages_sitesettings_assigngatewaymodal_assigngw:
                        'Ein Gateway diesem Projekt zuweisen',
                    pages_sitesettings_assignsensormodal_errortxt:
                        'Ein Sensor muss ausgewählt werden',
                    pages_sitesettings_assignsensormodal_assign:
                        'Sensor zuweisen',
                    pages_sitesettings_assignsensormodal_assignsensor:
                        'Ein Sensor diesem Messpunkt zuweisen',
                    pages_sitesettings_assignusermodal_errortxt:
                        'Ein Benutzer muss ausgewählt werden',
                    pages_sitesettings_assignusermodal_assign:
                        'Benutzer zuweisen',
                    pages_sitesettings_assignusermodal_assignuser:
                        'Einen Benutzer diesem Projekt zuweisen',
                    pages_sitesettings_backupgenerationmodal_generation:
                        'Backup Erstellung',
                    pages_sitesettings_backupgenerationmodal_backuptxt:
                        'Das Backup dieses Projekts wird erstellt. Schließen Sie diesen Dialog nicht, bis die Erstellung abgeschlossen ist. Klicken Sie anschließend auf die "Herunterladen" Schaltfläche um das Backup herunterzuladen.',
                    pages_sitesettings_backupgenerationmodal_download:
                        'Herunterladen',
                    pages_sitesettings_createalarmmodal_limiterror:
                        'Die unteren und oberen Grenzen (sowie die Hysteresewerte) müssen numerische Werte sein',
                    pages_sitesettings_createalarmmodal_nameerror:
                        'Der Alarmname ist erforderlich',
                    pages_sitesettings_createalarmmodal_new: 'Neuer Alarm',
                    pages_sitesettings_createmanualmodal_errortxt:
                        'Der manuelle Wert und die Displayanzeige müssen numerische Werte sein',
                    pages_sitesettings_createmanualmodal_new:
                        'Neuer manueller Messwert',
                    pages_sitesettings_createmanualmodal_date: 'Datum',
                    pages_sitesettings_createmanualmodal_manual: 'Wert [ppm]',
                    pages_sitesettings_createmanualmodal_display:
                        'Displayanzeige [ppm]',
                    pages_sitesettings_creatempmodal_errortxt:
                        'Der Messpunktname muss ausgefüllt werden',
                    pages_sitesettings_creatempmodal_new: 'Neuer Messpunkt',
                    pages_sitesettings_creatempmodal_namelbl:
                        'Name des Messpunkts',
                    pages_sitesettings_createsitegroupmodal_errortxt:
                        'Der Gruppenname muss ausgefüllt werden',
                    pages_sitesettings_createsitegroupmodal_new: 'Neue Gruppe',
                    pages_sitesettings_createsitegroupmodal_namelbl:
                        'Gruppenname',
                    pages_sitesettings_createsitemainpagemodal_errortxt:
                        'Der Projektname muss ausgefüllt werden',
                    pages_sitesettings_createsitemainpagemodal_new:
                        'Neues Projekt',
                    pages_sitesettings_createsitemainpagemodal_namelbl:
                        'Projektname',
                    pages_sitesettings_createsitemainpagemodal_descr:
                        'Beschreibung',
                    pages_sitesettings_createsitemainpagemodal_descrlbl:
                        'Projektbeschreibung',
                    pages_sitesettings_createsitemainpagemodal_attach:
                        'Projekt zuteilen zu',
                    pages_sitesettings_gatewayentrypage_sure:
                        'Das Gateway wird von diesem Projekt entfernt',
                    pages_sitesettings_gatewayentrypage_remove:
                        'Gateway entfernen',
                    pages_sitesettings_gatewayentrypage_openvpn:
                        'VPN Tunnel öffnen',
                    pages_sitesettings_gatewayentrypage_closevpn:
                        'VPN Tunnel schließen',
                    pages_sitesettings_gatewayentrypage_update:
                        'Firmware aktualisieren',
                    pages_sitesettings_gatewayssitepage_assign:
                        'Gateway zuweisen',
                    pages_sitesettings_importbackupmodal_import:
                        'Backup importieren',
                    pages_sitesettings_importbackupmodal_include:
                        'Daten miteinbeziehen',
                    pages_sitesettings_importbackupmodal_files:
                        'Zu importierende Dateien',
                    pages_sitesettings_importbackupmodal_import: 'Importieren',
                    pages_sitesettings_manualmeasurementspage_tester: 'Tester',
                    pages_sitesettings_manualmeasurementspage_acvalue:
                        'AquaCloud Wert [ppm]',
                    pages_sitesettings_manualmeasurementspage_remove:
                        'Entfernen',
                    pages_sitesettings_manualmeasurementspage_manualmeasurements:
                        'Manuelle Messungen',
                    pages_sitesettings_manualmeasurementspage_new:
                        'Neuer manueller Messwert',
                    pages_sitesettings_measurementpointpage_errortxt:
                        'Dieser Messpunkt wird keine Daten mehr aufzeichnen',
                    pages_sitesettings_measurementpointpage_incomplete:
                        'unvollständig',
                    pages_sitesettings_measurementpointpage_active: 'aktiv',
                    pages_sitesettings_measurementpointpage_inactive: 'inaktiv',
                    pages_sitesettings_measurementpointpage_assign:
                        'Sensor zuweisen',
                    pages_sitesettings_measurementpointpage_removesensor:
                        'Sensor entfernen',
                    pages_sitesettings_measurementpointpage_editmp:
                        'MP bearbeiten',
                    pages_sitesettings_measurementpointpage_deletemp:
                        'MP löschen',
                    pages_sitesettings_measurementpointpage_lasttemp:
                        'Letzte Temperatur',
                    pages_sitesettings_measurementpointpage_lastbatt:
                        'Letzter Batteriewert',
                    pages_sitesettings_measurementpointpage_lastmeas:
                        'Letzte Messung',
                    pages_sitesettings_memberentrypage_errortxt:
                        'Dieser Benutzer wird keinen Zugriff auf dieses Projekt mehr haben',
                    pages_sitesettings_memberentrypage_remove:
                        'Mitglied entfernen',
                    pages_sitesettings_memberentrypage_created: 'Erstellt',
                    pages_sitesettings_memberentrypage_lastlogin:
                        'Letzte Anmeldung',
                    pages_sitesettings_movempgroupsmodal_move:
                        'Elemente verschieben',
                    pages_sitesettings_movempgroupsmodal_new: 'Neue Gruppe',
                    pages_sitesettings_sitesettingspage_empty:
                        'Die Gruppe muss leer sein',
                    pages_sitesettings_sitesettingspage_confirmation:
                        'Alle Gateways werden vom Projekt und alle Sensoren werden von den Messpunkten entfernt.',
                    pages_sitesettings_sitesettingspage_createmp:
                        'Messpunkt erstellen',
                    pages_sitesettings_sitesettingspage_movesel: 'Verschieben',
                    pages_sitesettings_sitesettingspage_moveitems: 'Element(e)',
                    pages_sitesettings_sitesettingspage_deletesel: 'Löschen',
                    pages_sitesettings_sitesettingspage_deleteitems:
                        'Element(e)',
                    pages_sitesettings_sitesettingspage_editproj:
                        'Projekt bearbeiten',
                    pages_sitesettings_sitesettingspage_backup:
                        'Backup generieren',
                    pages_sitesettings_sitesettingspage_free:
                        'Alle Geräte freigeben',
                    pages_sitesettings_sitesettingspage_deleteproj:
                        'Projekt löschen',
                    pages_sitesettings_sitesettingspage_editgroup:
                        'Gruppe bearbeiten',
                    pages_sitesettings_sitesettingspage_delgroup:
                        'Gruppe löschen',
                    pages_sitesettings_sitesettingspage_sensor: 'Sensor',
                    pages_sitesettings_sitetableentry_updated: 'Aktualisiert',
                    pages_sitesettings_sitetitle_projid: 'Projekt ID',
                    pages_sitesettings_sitetitle_installationmode:
                        'Installationsmodus',
                    pages_sitesettings_sitetitle_start1: '',
                    pages_sitesettings_sitetitle_stop1: '',
                    pages_sitesettings_sitetitle_start2: 'starten',
                    pages_sitesettings_sitetitle_stop2: 'beenden',
                    pages_sitesettings_sitetitle_warntext:
                        'The sensors will reduce their sampling interval to 1 minute. If the installation mode is active for too long, the battery of the sensors will degrade prematurely.',
                    modal_edit: 'Bearbeiten',
                    modal_create: 'Erstellen',
                    modal_cancel: 'Abbrechen',
                    modal_rename: 'Umbenennen',
                    modal_assign: 'Zuweisen',
                    modal_move: 'Verschieben',
                    util_lastupdated_first: 'vor',
                    util_lastupdated_second: '',
                    util_lastupdate_seconds: 'Sekunden',
                    util_lastupdate_minutes: 'Minuten',
                    util_lastupdate_hours: 'Stunden',
                    util_lastupdate_days: 'Tage',
                    util_lastupdate_months: 'Monate',
                    util_lastupdate_years: 'Jahre',
                },
            },

            nl: {
                translations: {
                    common_filestable_outof: 'uit',
                    common_filestable_previous: 'vorige',
                    common_filestable_next: 'volgende',
                    common_filestable_search: 'Zoeken...',
                    common_filestable_empty:
                        'Er zijn geen inzendingen gevonden',
                    common_filestable_namecol: 'Naam',
                    swal_sure: 'Weet je het zeker?',
                    swal_cannot_recover_resource:
                        'U kunt deze bron niet herstellen',
                    swal_no: 'Nee',
                    swal_yes: 'Ja',
                    common_graphcontainer_aggregation: 'Aggregatie',
                    common_graphcontainer_average: 'Gemiddeld',
                    common_graphcontainer_median: 'Mediaan',
                    common_graphcontainer_max: 'Maximaal',
                    common_graphcontainer_min: 'Minimum',
                    common_graphcontainer_view: 'Weergeven',
                    common_graphcontainer_edit: 'Bewerk',
                    common_graphcontainer_remove: 'Verwijderen',
                    common_graphcontainer_groups: 'Groepen',
                    common_inputdropzone_info:
                        'Sleep het bestand hierheen of klik om het bestand te selecteren',
                    common_nodata_nodata: 'Geen gegevens',
                    common_notfound_return: 'Keer terug naar het geluk',
                    common_notfound_notfound: 'Niet gevonden',
                    common_roundedtext_close: 'dichtbij',
                    pagelayout_navbar_projects: 'Projecten',
                    pagelayout_navbar_viewallprojects: 'Bekijk alle projecten',
                    pagelayout_navbar_groups: 'Groepen',
                    pagelayout_navbar_viewallgroups: 'Bekijk alle groepen',
                    pagelayout_navbar_more: 'Meer',
                    pagelayout_navbar_users: 'Gebruikers',
                    pagelayout_navbar_devices: 'Apparaten',
                    pagelayout_navbar_accounting: 'Boekhouding',
                    pagelayout_navbar_diagnostics: 'Diagnostiek',
                    pagelayout_navbar_editprofile: 'Bewerk profiel',
                    pagelayout_navbar_signout: 'Afmelden',
                    pagelayout_sidebargroup_groups: 'Groepen',
                    pagelayout_sidebargroup_settings: 'Instellingen',
                    pagelayout_sidebargroup_general: 'Algemeen',
                    pagelayout_sidebargroup_projects: 'Projecten',
                    pagelayout_sidebargroup_members: 'Leden',
                    pagelayout_sidebargroup_devices: 'Apparaten',
                    pagelayout_sidebarsite_dashboards: 'Dashboards',
                    pagelayout_sidebarsite_manual: 'Handmatige metingen',
                    pagelayout_sidebarsite_settings: 'Instellingen',
                    pagelayout_sidebarsite_general: 'Algemeen',
                    pagelayout_sidebarsite_members: 'Leden',
                    pagelayout_sidebarsite_gateways: 'Gateways',
                    pagelayout_sidebarsite_alarms: 'Alarmen',
                    pages_addgraph_addgraph_new: 'Nieuwe grafiek',
                    pages_addgraph_addgraph_save: 'Grafiekwijzigingen opslaan',
                    pages_addgraph_addgraph_back: 'Ga terug',
                    pages_addgraph_addgraph_apply: 'Toepassen',
                    pages_addgraph_addgraph_settings: 'Instellingen',
                    pages_addgraph_addgraph_visualization: 'Visualisatie',
                    pages_addgraph_addgraph_display: 'Weergave',
                    pages_addgraph_addgraph_examplename: 'Grafiek voorbeeld',
                    pages_addgraph_displaytabbaroptions_linewidth:
                        'Lijnbreedte',
                    pages_addgraph_displaytabbaroptions_fillopacity:
                        'Dekking vullen',
                    pages_addgraph_displaytabbaroptions_barratio:
                        'Barverhouding',
                    pages_addgraph_displaytabbaroptions_baralign:
                        'Uitlijning staaf',
                    pages_addgraph_displaytabbaroptions_middle: 'Midden',
                    pages_addgraph_displaytabbaroptions_start: 'Begin',
                    pages_addgraph_displaytabbaroptions_end: 'Einde',
                    pages_addgraph_displaytabboxplotoptions_boxwidth:
                        'Doos breedte',
                    pages_addgraph_displaytabboxplotoptions_whiskerwidth:
                        'Snorhaar Breedte',
                    pages_addgraph_displaytablineoptions_usepoints:
                        'Punten gebruiken',
                    pages_addgraph_displaytablineoptions_pointwidth:
                        'Punt Breedte',
                    pages_addgraph_displaytablineoptions_fill: 'Vullen',
                    pages_addgraph_displaytablineoptions_linewidth:
                        'Lijnbreedte',
                    pages_addgraph_displaytablineoptions_interpolation:
                        'Interpolatie',
                    pages_addgraph_displaytablineoptions_linear: 'Lineair',
                    pages_addgraph_displaytablineoptions_natural: 'Natuurlijk',
                    pages_addgraph_displaytablineoptions_step: 'Stap',
                    pages_addgraph_displaytablineoptions_basis: 'Basis',
                    pages_addgraph_displaytablineoptions_cardinal: 'Kardinaal',
                    pages_addgraph_settingstab_name: 'Grafieknaam',
                    pages_addgraph_settingstab_placeholder: 'Grafiek voorbeeld',
                    pages_addgraph_settingstab_type: 'Type',
                    pages_addgraph_settingstab_temperature: 'Temperatuur',
                    pages_addgraph_settingstab_chlorine: 'Chloor',
                    pages_addgraph_settingstab_battery: 'Accu',
                    pages_addgraph_settingstab_gomp: 'Groep of meetpunt',
                    pages_addgraph_settingstab_flowrate: 'Stroomsnelheid:',
                    pages_addgraph_settingstab_digitalInput: 'Digitaal signaal',
                    pages_addgraph_visualizationtab_line: 'Lijn grafiek',
                    pages_addgraph_visualizationtab_bar: 'Staafdiagram',
                    pages_addgraph_visualizationtab_hbar:
                        'Horizontale staafgrafiek',
                    pages_addgraph_visualizationtab_box: 'Boxplot',
                    pages_addgraph_visualizationtab_table: 'Tafel',
                    pages_dashboard_createdashboardmodal_okerror:
                        'De naam van het dashboard is verplicht en moet worden opgegeven',
                    pages_dashboard_createdashboardmodal_new: 'Nieuw dashboard',
                    pages_dashboard_createdashboardmodal_name: 'Naam',
                    pages_dashboard_createdashboardmodal_namelbl:
                        'Naam van het dashboard',
                    pages_dashboard_dashboarddatepicker_absolute:
                        'Absoluut tijdsbereik',
                    pages_dashboard_dashboarddatepicker_from: 'Van',
                    pages_dashboard_dashboarddatepicker_to: 'Tot',
                    pages_dashboard_dashboarddatepicker_apply: 'Toepassen',
                    pages_dashboard_dashboarddatepicker_relative:
                        'Relatieve tijdsbereiken',
                    pages_dashboard_dashboarddatepicker_last12h:
                        'Laatste 12 uur',
                    pages_dashboard_dashboarddatepicker_last1d: 'Laatste 1 dag',
                    pages_dashboard_dashboarddatepicker_last3d:
                        'Laatste 3 dagen',
                    pages_dashboard_dashboarddatepicker_last1w:
                        'Afgelopen 1 week',
                    pages_dashboard_dashboarddatepicker_last1m:
                        'Laatste 1 maand',
                    pages_dashboard_dashboarddatepicker_all: 'Altijd',
                    pages_dashboard_dashboardpage_dashboards: 'Dashboards',
                    pages_dashboard_dashboardpage_protected:
                        'Het dashboard is beveiligd en kan niet worden bewerkt of verwijderd',
                    pages_dashboard_dashboardpage_spinnerfetch:
                        'Data ophalen...',
                    pages_dashboard_dashboardpage_create: 'Grafiek maken',
                    pages_dashboard_addgraph_edit: 'Grafiek bewerken',
                    pages_dashboard_dashboardpage_edit: 'Dashboard bewerken',
                    pages_dashboard_dashboardpage_delete:
                        'Dashboard verwijderen',
                    pages_dashboard_dashboardspage_new: 'Nieuw dashboard',
                    pages_devices_creategatewaymodal_errormsg:
                        'De UUID en het serienummer van de gateway zijn verplicht en moeten worden opgegeven',
                    pages_devices_creategatewaymodal_newgw: 'Nieuwe poort',
                    pages_devices_creategatewaymodal_uuidlbl:
                        'UUID van de gateway',
                    pages_devices_creategatewaymodal_gwsnlbl:
                        'Serienummer van de gateway',
                    pages_devices_creategatewaymodal_gwsn: 'Serienummer.',
                    pages_devices_createsensormodal_errormsg:
                        'De UUID en het serienummer van de sensor zijn verplicht en moeten worden gespecificeerd',
                    pages_devices_createsensormodal_newsensor: 'Nieuwe sensor',
                    pages_devices_createsensormodal_uuidlbl:
                        'UUID van de sensor',
                    pages_devices_createsensormodal_sensorsnlbl:
                        'Serienummer van de sensor',
                    pages_devices_createsensormodal_sensorsn: 'Serienummer.',
                    pages_devices_createsensormodal_type: 'Type van de sensor:',
                    pages_devices_devicepage_acid: 'AquaCloud-ID',
                    pages_devices_devicepage_name: 'Naam',
                    pages_devices_devicepage_connected: 'Verbonden',
                    pages_devices_devicepage_uptime: 'Uptime',
                    pages_devices_devicepage_lastactive: 'Laatst actief om',
                    pages_devices_devicepage_assignedto: 'Toegewezen aan groep',
                    pages_devices_devicepage_project: 'Project',
                    pages_devices_devicepage_type: 'Type',
                    pages_devices_devicepage_status: 'Toestand',
                    pages_devices_devicepage_mp: 'Meetpunt',
                    pages_devices_devicepage_never: 'Nooit',
                    pages_devices_devicepage_active: 'Actief',
                    pages_devices_devicepage_inactive: 'Inactief',
                    pages_devices_devicepage_unassigned: 'Niet toegewezen',
                    pages_devices_devicepage_devices: 'Apparaten',
                    pages_devices_devicepage_rename: 'Naam apparaat wijzigen',
                    pages_devices_devicepage_remove: 'Verwijder apparaat',
                    pages_devices_devicepage_remove_confirmation_msg:
                        'U staat op het punt dit apparaat volledig te verwijderen. Het apparaat en de gegevens kunnen niet worden hersteld. Bevestig de vereiste informatie om verder te gaan.',
                    pages_devices_devicepage_remove_mp_attachment_msg:
                        'Deze sensor is toegewezen aan een meetpunt. Als het wordt verwijderd, wordt de toewijzing verwijderd en slaat het meetpunt geen metingen meer op.',
                    pages_devices_devicepage_remove_gw_attachment_msg:
                        'Deze gateway is toegewezen aan een project. Als deze wordt verwijderd, wordt de opdracht verwijderd.',
                    pages_devices_devicepage_properties: 'Instellingen',
                    pages_devices_devicespage_formaterror:
                        'Het formaat van de sensor UUID klopt niet',
                    pages_devices_devicespage_temperature: 'Temperatuur',
                    pages_devices_devicespage_chlorine: 'Chloor',
                    pages_devices_devicespage_dinput: 'Digitale invoer',
                    pages_devices_devicespage_creategw: 'Gateway maken',
                    pages_devices_devicespage_createsensor: 'Sensor maken',
                    pages_devices_devicespage_assigned: 'Toegewezen aan',
                    pages_devices_renamedevicemodal_device: 'Apparaat',
                    pages_devices_renamedevicemodal_errormsg:
                        'De naam van het apparaat is verplicht en moet worden opgegeven',
                    pages_devices_renamedevicemodal_rename:
                        'Nieuwe naam van het apparaat',
                    pages_diagnostics_diagnosticsgatewaypage_connected:
                        'Verbonden',
                    pages_diagnostics_diagnosticsgatewaypage_disconnected:
                        'Verbinding verbroken',
                    pages_diagnostics_diagnosticsgatewaypage_totalmemory:
                        'Totaal geheugen',
                    pages_diagnostics_diagnosticsgatewaypage_reboots:
                        'Opnieuw opstarten',
                    pages_diagnostics_diagnosticsgatewaypage_freemem:
                        'Vrij geheugen',
                    pages_diagnostics_diagnosticsgatewaypage_cpuload:
                        'CPU-belasting',
                    pages_diagnostics_diagnosticsgatewaypage_vpnactive:
                        'VPN was actief voor',
                    pages_diagnostics_diagnosticsgatewaypage_diagnostics:
                        'Diagnostiek',
                    pages_diagnostics_diagnosticsgatewaypage_properties:
                        'Eigendommen',
                    pages_diagnostics_diagnosticsgatewaypage_status: 'Toestand',
                    pages_diagnostics_diagnosticspage_notattached:
                        'Niet bijgevoegd',
                    pages_diagnostics_diagnosticspage_attached: 'Gehecht aan',
                    pages_diagnostics_diagnosticspage_gwuuid: 'GW UUID',
                    pages_diagnostics_diagnosticspage_serialno: 'Serienummer.',
                    pages_diagnostics_diagnosticspage_status: 'Toestand',
                    pages_diagnostics_diagnosticspage_sensoruuid: 'Sensor UUID',
                    pages_diagnostics_diagnosticspage_nojoin:
                        'Niet lid geworden',
                    pages_diagnostics_diagnosticspage_value: 'Waarde',
                    pages_diagnostics_diagnosticspage_battery: 'Accu',
                    pages_diagnostics_diagnosticssensorpage_lastrx:
                        'Laatste RX',
                    pages_diagnostics_diagnosticssensorpage_lastjoin:
                        'Laatste deelname',
                    pages_diagnostics_diagnosticssensorpage_gateway: 'Gateway',
                    pages_diagnostics_diagnosticssensorpage_halerts:
                        'Gezondheidswaarschuwingen',
                    pages_groups_assigngatewaymodal_errorMsg:
                        'Er moet een gateway worden geselecteerd',
                    pages_groups_assigngatewaymodal_assign: 'Gateway toewijzen',
                    pages_groups_assigngatewaymodal_gwassign:
                        'Gateway om toe te wijzen',
                    pages_groups_assignsensormodal_errorMsg:
                        'Er moet een sensor worden geselecteerd',
                    pages_groups_assignsensormodal_assign: 'Sensor toewijzen',
                    pages_groups_assignsensormodal_sensorassign:
                        'Toe te wijzen sensor',
                    pages_groups_creategroupmodal_errorMsg:
                        'De naam van de groep is verplicht en moet worden opgegeven',
                    pages_groups_creategroupmodal_new: 'Nieuwe groep',
                    pages_groups_creategroupmodal_namelbl: 'Naam van de groep',
                    pages_groups_creategroupmodal_name: 'Naam',
                    pages_groups_creategroupmodal_desclbl:
                        'Beschrijving van de groep',
                    pages_groups_creategroupmodal_desc:
                        'Optionele beschrijving',
                    pages_groups_gatewaygrouppage_settings: 'Instellingen',
                    pages_groups_gatewaygrouppage_devices: 'Apparaten',
                    pages_groups_gatewaygrouppage_gws: 'Gateways',
                    pages_groups_gatewaygrouppage_wlan: 'WLAN verbruik',
                    pages_groups_gatewaygrouppage_eth: 'ETH-verbruik',
                    pages_groups_gatewaygrouppage_vpn: 'VPN status',
                    pages_groups_gatewaygrouppage_uptime: 'Uptime',
                    pages_groups_gatewaygrouppage_cpu: 'CPU gebruik',
                    pages_groups_gatewaygrouppage_sw: 'SW-versie',
                    pages_groups_gatewaygrouppage_value: 'Waarde',
                    pages_groups_gatewaygrouppage_remove: 'Gateway verwijderen',
                    pages_groups_groupmemberspage_info:
                        'Deze leden hebben geen toegang meer tot deze groep',
                    pages_groups_groupprojectspage_info:
                        'U kunt deze bronnen niet herstellen',
                    pages_groups_groupspage_groups: 'Groepen',
                    pages_groups_groupspage_create: 'Groep maken',
                    pages_groups_grouptableentry_subgroups: 'Subgroepen',
                    pages_groups_grouptableentry_projects: 'Projecten',
                    pages_groups_grouptableentry_members: 'Leden',
                    pages_groups_grouptitle_id: 'Groeps ID',
                    pages_groups_grouptreestructure_empty:
                        'De groep moet leeg zijn',
                    pages_groups_grouptreestructure_createproject:
                        'Project maken',
                    pages_groups_grouptreestructure_assignmember:
                        'Lid toewijzen',
                    pages_groups_grouptreestructure_assigngw:
                        'Gateway toewijzen',
                    pages_groups_grouptreestructure_assignsensor:
                        'Sensor toewijzen',
                    pages_groups_grouptreestructure_creategroup: 'Groep maken',
                    pages_groups_grouptreestructure_move: 'Beweging',
                    pages_groups_grouptreestructure_projects: 'Project(en)',
                    pages_groups_grouptreestructure_delete: 'Verwijderen',
                    pages_groups_grouptreestructure_remove: 'Verwijderen',
                    pages_groups_grouptreestructure_members: 'lid(en)',
                    pages_groups_grouptreestructure_devices: 'Apparaat(en)',
                    pages_groups_grouptreestructure_groups: 'Groep(en)',
                    pages_groups_grouptreestructure_editgroup: 'Groep bewerken',
                    pages_groups_grouptreestructure_deletegroup:
                        'Groep verwijderen',
                    pages_groups_movedevicesmodal_move: 'Apparaten verplaatsen',
                    pages_groups_movedevicesmodal_moveto:
                        'Verplaats apparaten naar',
                    pages_groups_moveprojectsmodal_move:
                        'Projecten verplaatsen',
                    pages_groups_moveprojectsmodal_moveto:
                        'Verplaats projecten naar',
                    pages_groups_movegroupsmodal_move: 'Groepen verplaatsen',
                    pages_groups_movegroupsmodal_moveto:
                        'Groepen verplaatsen naar',
                    pages_login_forgotpasswordmodal_info:
                        'We hebben u een e-mail gestuurd met het token dat de wijziging van het wachtwoord autoriseert. Vul het onderstaande formulier in om uw wachtwoord opnieuw in te stellen.',
                    pages_login_forgotpasswordmodal_reset:
                        'Wachtwoord opnieuw instellen',
                    pages_login_forgotpasswordmodal_email: 'Jouw email',
                    pages_login_forgotpasswordmodal_newpass: 'Nieuw paswoord',
                    pages_login_forgotpasswordmodal_repeatpass:
                        'Herhaal nieuw wachtwoord',
                    pages_login_forgotpasswordmodal_token: 'Token',
                    pages_login_forgotpasswordmodal_resetbtn: 'Resetten',
                    pages_login_forgotpasswordmodal_verifyinputs:
                        'Het wachtwoord kan niet worden gereset. Verifieer uw invoer',
                    pages_login_forgotpasswordmodal_sendtoken:
                        'Token verzenden',
                    pages_login_loginpage_baddata:
                        'U heeft een ongeldige gebruikersnaam of wachtwoord ingevoerd',
                    pages_login_loginpage_required:
                        'Alle velden zijn verplicht',
                    pages_login_loginpage_username: 'gebruikersnaam',
                    pages_login_loginpage_password: 'Wachtwoord',
                    pages_login_loginpage_forgot: 'Wachtwoord vergeten ?',
                    pages_login_loginpage_login: 'Log in',
                    pages_profile_profilepage_cannotchange:
                        'Het wachtwoord kan niet worden gewijzigd',
                    pages_profile_profilepage_profile: 'Profiel',
                    pages_profile_profilepage_username: 'Gebruikersnaam',
                    pages_profile_profilepage_email: 'E-mail',
                    pages_profile_profilepage_tz: 'Tijdzone',
                    pages_profile_profilepage_lang: 'Taal',
                    pages_profile_profilepage_save:
                        'Profielwijzigingen opslaan',
                    pages_profile_profilepage_savechanges:
                        'Wijzigingen opslaan',
                    pages_profile_profilepage_change: 'Verander wachtwoord',
                    pages_profile_profilepage_old: 'Oud wachtwoord',
                    pages_profile_profilepage_new: 'Nieuw wachtwoord',
                    pages_profile_profilepage_confirm:
                        'Bevestig nieuw wachtwoord',
                    pages_viewgraph_viewgraph_back:
                        'Ga terug naar de Dashboards-pagina',
                    pages_users_createusermodal_bademail:
                        'Het formaat van de e-mail klopt niet',
                    pages_users_createusermodal_badpassword:
                        'Uw wachtwoord moet minimaal 8 tekens lang zijn, minimaal één cijfer, één speciaal teken bevatten en een combinatie van hoofdletters en kleine letters bevatten.',
                    pages_users_createusermodal_allfields:
                        'Alle velden moeten worden opgegeven',
                    pages_users_createusermodal_newuser: 'Nieuwe gebruiker',
                    pages_users_createusermodal_user: 'Gebruiker',
                    pages_users_createusermodal_role: 'Rol',
                    pages_users_userentrytable_unknown: 'Onbekend',
                    pages_users_userpage_membersince: 'Lid sinds',
                    pages_users_userpage_lastsignin: 'Laatste aanmelding om',
                    pages_users_userpage_createdby: 'Gemaakt door',
                    pages_users_userpage_users: 'Gebruikers',
                    pages_users_userpage_profile: 'Profiel',
                    pages_users_userpage_gp: 'Groepen & Projecten',
                    pages_users_userpage_accessto: 'Toegang tot',
                    pages_users_userpage_projects: 'projecten',
                    pages_users_userspage_create: 'Gebruiker maken',
                    pages_users_userspage_createdon: 'Gemaakt op',
                    pages_users_userspage_lastactivity: 'Laatste Activiteit',
                    pages_sitesettings_alarmentrypage_active: 'Is actief?',
                    pages_sitesettings_alarmentrypage_lower: 'Ondergrens',
                    pages_sitesettings_alarmentrypage_upper: 'Bovengrens',
                    pages_sitesettings_alarmentrypage_hlow: 'Lagere hysterese',
                    pages_sitesettings_alarmentrypage_hupp:
                        'Bovenste hysterese',
                    pages_sitesettings_alarmentrypage_from:
                        'Activeerbaar vanaf',
                    pages_sitesettings_alarmentrypage_to: 'Activeerbaar tot',
                    pages_sitesettings_alarmentrypage_alarms: 'Alarmen',
                    pages_sitesettings_alarmentrypage_edit: 'Alarm bewerken',
                    pages_sitesettings_alarmentrypage_delete:
                        'Alarm verwijderen',
                    pages_sitesettings_alarmentrypage_new: 'Nieuw alarm',
                    pages_sitesettings_alarmentrypage_remove: 'Verwijderen',
                    pages_sitesettings_alarmentrypage_alarmslower: 'alarmen',
                    pages_sitesettings_alarmentrypage_fromtxt: 'Van',
                    pages_sitesettings_alarmentrypage_totxt: 'Tot',
                    pages_sitesettings_alarmentrypage_activetxt: 'Actief',
                    pages_sitesettings_alarmentrypage_watchers: 'Kijkers',
                    pages_sitesettings_alarmentrypage_addwatcher:
                        'Kijker toevoegen',
                    pages_sitesettings_assigngatewaymodal_errortxt:
                        'Er moet een gateway worden geselecteerd',
                    pages_sitesettings_assigngatewaymodal_assign:
                        'Gateway toewijzen',
                    pages_sitesettings_assigngatewaymodal_assigngw:
                        'Gateway toewijzen aan dit project',
                    pages_sitesettings_assignsensormodal_errortxt:
                        'Er moet een sensor worden geselecteerd',
                    pages_sitesettings_assignsensormodal_assign:
                        'Sensor toewijzen',
                    pages_sitesettings_assignsensormodal_assignsensor:
                        'Wijs een sensor toe aan dit meetpunt',
                    pages_sitesettings_assignusermodal_errortxt:
                        'Er moet een gebruiker worden geselecteerd',
                    pages_sitesettings_assignusermodal_assign:
                        'Gebruiker toewijzen',
                    pages_sitesettings_assignusermodal_assignuser:
                        'Een gebruiker toewijzen aan dit project',
                    pages_sitesettings_backupgenerationmodal_generation:
                        'Back-up genereren',
                    pages_sitesettings_backupgenerationmodal_backuptxt:
                        'De back-up van dit project wordt gegenereerd. Houd deze dialoog open totdat de generatie is voltooid. Klik dan op de downloadknop.',
                    pages_sitesettings_backupgenerationmodal_download:
                        'Downloaden',
                    pages_sitesettings_createalarmmodal_limiterror:
                        'De onder- en bovengrenzen (en hysteresewaarden) moeten getallen zijn',
                    pages_sitesettings_createalarmmodal_nameerror:
                        'De naam van het alarm is verplicht en moet worden opgegeven',
                    pages_sitesettings_createalarmmodal_new: 'Nieuw alarm',
                    pages_sitesettings_createmanualmodal_errortxt:
                        'De handmatige waarde en display-uitlezing moeten cijfers zijn',
                    pages_sitesettings_createmanualmodal_new:
                        'Nieuwe handmatige meting',
                    pages_sitesettings_createmanualmodal_date: 'Datum',
                    pages_sitesettings_createmanualmodal_manual:
                        'Handmatige waarde [ppm]',
                    pages_sitesettings_createmanualmodal_display:
                        'Display Uitlezing [ppm]',
                    pages_sitesettings_creatempmodal_errortxt:
                        'De naam van het meetpunt is verplicht en moet worden gespecificeerd',
                    pages_sitesettings_creatempmodal_new: 'Nieuw meetpunt',
                    pages_sitesettings_creatempmodal_namelbl:
                        'Naam van het meetpunt',
                    pages_sitesettings_createsitegroupmodal_errortxt:
                        'De naam van de groep is verplicht en moet worden opgegeven',
                    pages_sitesettings_createsitegroupmodal_new: 'Nieuwe groep',
                    pages_sitesettings_createsitegroupmodal_namelbl:
                        'Naam van de groep',
                    pages_sitesettings_createsitemainpagemodal_errortxt:
                        'De naam van het project is verplicht en moet worden opgegeven',
                    pages_sitesettings_createsitemainpagemodal_new:
                        'Nieuw project',
                    pages_sitesettings_createsitemainpagemodal_namelbl:
                        'Naam van het project',
                    pages_sitesettings_createsitemainpagemodal_descr:
                        'Beschrijving',
                    pages_sitesettings_createsitemainpagemodal_descrlbl:
                        'Beschrijving van het project',
                    pages_sitesettings_createsitemainpagemodal_attach:
                        'Voeg project toe aan:',
                    pages_sitesettings_gatewayentrypage_sure:
                        'De gateway wordt uit dit project verwijderd',
                    pages_sitesettings_gatewayentrypage_remove:
                        'Gateway verwijderen',
                    pages_sitesettings_gatewayentrypage_openvpn:
                        'VPN-tunnel openen',
                    pages_sitesettings_gatewayentrypage_closevpn:
                        'VPN-tunnel sluiten',
                    pages_sitesettings_gatewayentrypage_update:
                        'Firmware bijwerken',
                    pages_sitesettings_gatewayssitepage_assign:
                        'Gateway toewijzen',
                    pages_sitesettings_importbackupmodal_import:
                        'Back-up importeren',
                    pages_sitesettings_importbackupmodal_include:
                        'Gegevens opnemen',
                    pages_sitesettings_importbackupmodal_files:
                        'Bestanden om te importeren',
                    pages_sitesettings_importbackupmodal_import: 'Importeren',
                    pages_sitesettings_manualmeasurementspage_tester: 'Tester',
                    pages_sitesettings_manualmeasurementspage_acvalue:
                        'AquaCloud-waarde [ppm]',
                    pages_sitesettings_manualmeasurementspage_remove:
                        'Verwijderen',
                    pages_sitesettings_manualmeasurementspage_manualmeasurements:
                        'handmatige metingen',
                    pages_sitesettings_manualmeasurementspage_new:
                        'Nieuwe handmatige meting',
                    pages_sitesettings_measurementpointpage_errortxt:
                        'Dit meetpunt stopt met het meten van gegevens',
                    pages_sitesettings_measurementpointpage_incomplete:
                        'incompleet',
                    pages_sitesettings_measurementpointpage_active: 'actief',
                    pages_sitesettings_measurementpointpage_inactive:
                        'inactief',
                    pages_sitesettings_measurementpointpage_assign:
                        'Sensor toewijzen',
                    pages_sitesettings_measurementpointpage_removesensor:
                        'Sensor verwijderen',
                    pages_sitesettings_measurementpointpage_editmp:
                        'MP bewerken',
                    pages_sitesettings_measurementpointpage_deletemp:
                        'MP verwijderen',
                    pages_sitesettings_measurementpointpage_lasttemp:
                        'Laatste temperatuur',
                    pages_sitesettings_measurementpointpage_lastbatt:
                        'Laatste batterij',
                    pages_sitesettings_measurementpointpage_lastmeas:
                        'Laatste meting bij',
                    pages_sitesettings_memberentrypage_errortxt:
                        'Deze gebruiker heeft geen toegang meer tot dit project',
                    pages_sitesettings_memberentrypage_remove:
                        'Lid verwijderen',
                    pages_sitesettings_memberentrypage_created: 'Gemaakt bij',
                    pages_sitesettings_memberentrypage_lastlogin:
                        'Laatste aanmelding',
                    pages_sitesettings_movempgroupsmodal_move:
                        'Items verplaatsen',
                    pages_sitesettings_movempgroupsmodal_new: 'Nieuwe groep',
                    pages_sitesettings_sitesettingspage_empty:
                        'De groep moet leeg zijn',
                    pages_sitesettings_sitesettingspage_confirmation:
                        'Alle gateways worden losgekoppeld van het project en alle sensoren worden verwijderd van de meetpunten.',
                    pages_sitesettings_sitesettingspage_createmp:
                        'Meetpunt maken',
                    pages_sitesettings_sitesettingspage_movesel: 'Beweging',
                    pages_sitesettings_sitesettingspage_moveitems:
                        'Artikel(en)',
                    pages_sitesettings_sitesettingspage_deletesel:
                        'Verwijderen',
                    pages_sitesettings_sitesettingspage_deleteitems:
                        'Artikel(en)',
                    pages_sitesettings_sitesettingspage_editproj:
                        'Project bewerken',
                    pages_sitesettings_sitesettingspage_backup:
                        'Back-up genereren',
                    pages_sitesettings_sitesettingspage_free:
                        'Alle apparaten gratis',
                    pages_sitesettings_sitesettingspage_deleteproj:
                        'Project verwijderen',
                    pages_sitesettings_sitesettingspage_editgroup:
                        'Groep bewerken',
                    pages_sitesettings_sitesettingspage_delgroup:
                        'Groep verwijderen',
                    pages_sitesettings_sitesettingspage_sensor: 'Sensor',
                    pages_sitesettings_sitetableentry_updated: 'Bijgewerkt',
                    pages_sitesettings_sitetitle_projid: 'Project ID',
                    pages_sitesettings_sitetitle_installationmode:
                        'Installatiemodus:',
                    pages_sitesettings_sitetitle_start1: 'Begin',
                    pages_sitesettings_sitetitle_stop1: 'Hou op',
                    pages_sitesettings_sitetitle_start2: '',
                    pages_sitesettings_sitetitle_stop2: '',
                    pages_sitesettings_sitetitle_warntext:
                        'De sensoren verkorten hun bemonsteringsinterval tot 1 minuut. Als de installatiemodus te lang actief is, gaat de batterij van de sensoren voortijdig achteruit.',
                    modal_edit: 'Bewerk',
                    modal_create: 'Maken',
                    modal_cancel: 'Annuleren',
                    modal_rename: 'Hernoemen',
                    modal_assign: 'Toewijzen',
                    modal_move: 'Verplaatsen',
                    util_lastupdated_first: '',
                    util_lastupdated_second: 'geleden',
                    util_lastupdate_seconds: 'seconden',
                    util_lastupdate_minutes: 'minuten',
                    util_lastupdate_hours: 'uur',
                    util_lastupdate_days: 'dagen',
                    util_lastupdate_months: 'maanden',
                    util_lastupdate_years: 'jaar',
                },
            },

            es: {
                translations: {
                    common_filestable_outof: 'de',
                    common_filestable_previous: 'anterior',
                    common_filestable_next: 'siguiente',
                    common_filestable_search: 'Buscar...',
                    common_filestable_empty: 'No se han encontrado datos',
                    common_filestable_namecol: 'Nombre',
                    swal_sure: '¿Estás seguro?',
                    swal_cannot_recover_resource:
                        'No podrás recuperar este recurso',
                    swal_no: 'No',
                    swal_yes: 'Si',
                    common_graphcontainer_aggregation: 'Agregación',
                    common_graphcontainer_average: 'Media',
                    common_graphcontainer_median: 'Mediana',
                    common_graphcontainer_max: 'Máximo',
                    common_graphcontainer_min: 'Mínimo',
                    common_graphcontainer_view: 'Ver',
                    common_graphcontainer_edit: 'Editar',
                    common_graphcontainer_remove: 'Eliminar',
                    common_graphcontainer_groups: 'Grupos',
                    common_inputdropzone_info:
                        'Arrastra aquí el archivo, o haz click para seleccionarlo',
                    common_nodata_nodata: 'No hay datos',
                    common_notfound_return: 'Vuelve a la felicidad',
                    common_notfound_notfound: 'No se ha encontrado',
                    common_roundedtext_close: 'cerrar',
                    pagelayout_navbar_projects: 'Proyectos',
                    pagelayout_navbar_viewallprojects:
                        'Ver todos los proyectos',
                    pagelayout_navbar_groups: 'Grupos',
                    pagelayout_navbar_viewallgroups: 'Ver todos los grupos',
                    pagelayout_navbar_more: 'Más',
                    pagelayout_navbar_users: 'Usuarios',
                    pagelayout_navbar_devices: 'Dispositivos',
                    pagelayout_navbar_accounting: 'Contabilidad',
                    pagelayout_navbar_diagnostics: 'Diagnóstico',
                    pagelayout_navbar_editprofile: 'Editar Perfil',
                    pagelayout_navbar_signout: 'Cerrar Sesión',
                    pagelayout_sidebargroup_groups: 'Grupos',
                    pagelayout_sidebargroup_settings: 'Configuración',
                    pagelayout_sidebargroup_general: 'General',
                    pagelayout_sidebargroup_projects: 'Proyectos',
                    pagelayout_sidebargroup_members: 'Miembros',
                    pagelayout_sidebargroup_devices: 'Dispositivos',
                    pagelayout_sidebarsite_dashboards: 'Dashboards',
                    pagelayout_sidebarsite_manual: 'Medidas Manuales',
                    pagelayout_sidebarsite_settings: 'Configuración',
                    pagelayout_sidebarsite_general: 'General',
                    pagelayout_sidebarsite_members: 'Miembros',
                    pagelayout_sidebarsite_gateways: 'Gateways',
                    pagelayout_sidebarsite_alarms: 'Alarmas',
                    pages_addgraph_addgraph_new: 'Nuevo Gráfico',
                    pages_addgraph_addgraph_save:
                        'Guardar cambios en el gráfico',
                    pages_addgraph_addgraph_back: 'Atrás',
                    pages_addgraph_addgraph_apply: 'Aplicar',
                    pages_addgraph_addgraph_settings: 'Configuración',
                    pages_addgraph_addgraph_visualization: 'Visualización',
                    pages_addgraph_addgraph_display: 'Presentación',
                    pages_addgraph_addgraph_examplename: 'Gráfico de Ejemplo',
                    pages_addgraph_displaytabbaroptions_linewidth:
                        'Grosor de Línea',
                    pages_addgraph_displaytabbaroptions_fillopacity:
                        'Opacidad de Relleno',
                    pages_addgraph_displaytabbaroptions_barratio:
                        'Proporción de las barras',
                    pages_addgraph_displaytabbaroptions_baralign:
                        'Alineamiento de las barras',
                    pages_addgraph_displaytabbaroptions_middle: 'Centro',
                    pages_addgraph_displaytabbaroptions_start: 'Inicio',
                    pages_addgraph_displaytabbaroptions_end: 'Fin',
                    pages_addgraph_displaytabboxplotoptions_boxwidth:
                        'Anchura de Caja',
                    pages_addgraph_displaytabboxplotoptions_whiskerwidth:
                        'Anchura del Bigote',
                    pages_addgraph_displaytablineoptions_usepoints:
                        'Usar Puntos',
                    pages_addgraph_displaytablineoptions_pointwidth:
                        'Grosor del Punto',
                    pages_addgraph_displaytablineoptions_fill: 'Rellenar',
                    pages_addgraph_displaytablineoptions_linewidth:
                        'Grosor de Línea',
                    pages_addgraph_displaytablineoptions_interpolation:
                        'Interpolación',
                    pages_addgraph_displaytablineoptions_linear: 'Lineal',
                    pages_addgraph_displaytablineoptions_natural: 'Natural',
                    pages_addgraph_displaytablineoptions_step: 'Step',
                    pages_addgraph_displaytablineoptions_basis: 'Base',
                    pages_addgraph_displaytablineoptions_cardinal: 'Cardenal',
                    pages_addgraph_settingstab_name: 'Nombre del gráfico',
                    pages_addgraph_settingstab_placeholder:
                        'Gráfico de Ejemplo',
                    pages_addgraph_settingstab_type: 'Tipo',
                    pages_addgraph_settingstab_temperature: 'Temperatura',
                    pages_addgraph_settingstab_chlorine: 'Cloro',
                    pages_addgraph_settingstab_battery: 'Batería',
                    pages_addgraph_settingstab_gomp: 'Grupo o Punto de Medida',
                    pages_addgraph_settingstab_flowrate: 'Caudal',
                    pages_addgraph_settingstab_digitalInput: 'Señal Digital',
                    pages_addgraph_visualizationtab_line: 'Gráfico de Línea',
                    pages_addgraph_visualizationtab_bar: 'Gráfico de Barras',
                    pages_addgraph_visualizationtab_hbar:
                        'Gráfico de Barras Horizontal',
                    pages_addgraph_visualizationtab_box: 'Diagrama de Caja',
                    pages_addgraph_visualizationtab_table: 'Tabla',
                    pages_dashboard_createdashboardmodal_okerror:
                        'El nombre del dashboard es obligatorio',
                    pages_dashboard_createdashboardmodal_new: 'Nuevo Dashboard',
                    pages_dashboard_createdashboardmodal_name: 'Nombre',
                    pages_dashboard_createdashboardmodal_namelbl:
                        'Nombre del dashboard',
                    pages_dashboard_dashboarddatepicker_absolute:
                        'Rango temporal absoluto',
                    pages_dashboard_dashboarddatepicker_from: 'Desde',
                    pages_dashboard_dashboarddatepicker_to: 'Hasta',
                    pages_dashboard_dashboarddatepicker_apply: 'Aplicar',
                    pages_dashboard_dashboarddatepicker_relative:
                        'Rangos temporales relativos',
                    pages_dashboard_dashboarddatepicker_last12h:
                        'Últimas 12 horas',
                    pages_dashboard_dashboarddatepicker_last1d: 'Último día',
                    pages_dashboard_dashboarddatepicker_last3d:
                        'Últimos 3 días',
                    pages_dashboard_dashboarddatepicker_last1w: 'Última semana',
                    pages_dashboard_dashboarddatepicker_last1m: 'Último mes',
                    pages_dashboard_dashboarddatepicker_all: 'Todo el tiempo',
                    pages_dashboard_dashboardpage_dashboards: 'Dashboards',
                    pages_dashboard_dashboardpage_protected:
                        'El dashboard está protegido y no se puede eliminar ni editar.',
                    pages_dashboard_dashboardpage_spinnerfetch:
                        'Obteniendo datos...',
                    pages_dashboard_dashboardpage_create: 'Crear gráfico',
                    pages_dashboard_addgraph_edit: 'Editar Gráfico',
                    pages_dashboard_dashboardpage_edit: 'Editar Dashboard',
                    pages_dashboard_dashboardpage_delete: 'Eliminar Dashboard',
                    pages_dashboard_dashboardspage_new: 'Nuevo Dashboard',
                    pages_devices_creategatewaymodal_errormsg:
                        'El UUID y el número de serie del gateway son obligatorios',
                    pages_devices_creategatewaymodal_newgw: 'Nuevo Gateway',
                    pages_devices_creategatewaymodal_uuidlbl:
                        'UUID del gateway',
                    pages_devices_creategatewaymodal_gwsnlbl:
                        'Número de serie del gateway',
                    'pages_devices_creategatewaymodal_gwsn:': 'Nº Serie',
                    pages_devices_createsensormodal_errormsg:
                        'El UUID y el número de serie del sensor son obligatorios',
                    pages_devices_createsensormodal_newsensor: 'Nuevo Sensor',
                    pages_devices_createsensormodal_uuidlbl: 'UUID del sensor',
                    pages_devices_createsensormodal_sensorsnlbl:
                        'Número de serie del sensor',
                    pages_devices_createsensormodal_sensorsn: 'Nº Serie',
                    pages_devices_createsensormodal_type: 'Tipo del sensor',
                    pages_devices_devicepage_acid: 'ID AquaCloud',
                    pages_devices_devicepage_name: 'Nombre',
                    pages_devices_devicepage_connected: 'Conectado',
                    pages_devices_devicepage_uptime: 'Tiempo de actividad',
                    pages_devices_devicepage_lastactive: 'Última vez activo',
                    pages_devices_devicepage_assignedto: 'Asignado al Grupo',
                    pages_devices_devicepage_project: 'Proyecto',
                    pages_devices_devicepage_type: 'Tipo',
                    pages_devices_devicepage_status: 'Estado',
                    pages_devices_devicepage_mp: 'Punto de Medida',
                    pages_devices_devicepage_never: 'Nunca',
                    pages_devices_devicepage_active: 'Activo',
                    pages_devices_devicepage_inactive: 'Inactivo',
                    pages_devices_devicepage_unassigned: 'No asignado',
                    pages_devices_devicepage_devices: 'Dispositivos',
                    pages_devices_devicepage_rename: 'Renombrar Dispositivo',
                    pages_devices_devicepage_remove: 'Eliminar Dispositivo',
                    pages_devices_devicepage_remove_confirmation_msg:
                        'Está a punto de eliminar completamente este dispositivo. El dispositivo y su información no podrán ser recuperados. Para proceder, confirme la información requerida.',
                    pages_devices_devicepage_remove_mp_attachment_msg:
                        'Este sensor se encuentra asignado a un punto de medida. Si procede a su eliminación, la asignación se perderá y el punto de medida dejará de almacenar medidas.',
                    pages_devices_devicepage_remove_gw_attachment_msg:
                        'Este gateway se encuentra asignado a un proyecto. Si procede a su eliminación, la asignación se perderá.',
                    pages_devices_devicepage_properties: 'Propiedades',
                    pages_devices_devicespage_formaterror:
                        'El formato del UUID del sensor no es correcto',
                    pages_devices_devicespage_temperature: 'Temperatura',
                    pages_devices_devicespage_chlorine: 'Cloro',
                    pages_devices_devicespage_dinput: 'Entrada Digital',
                    pages_devices_devicespage_creategw: 'Crear Gateway',
                    pages_devices_devicespage_createsensor: 'Crear Sensor',
                    pages_devices_devicespage_assigned: 'Asignado a',
                    pages_devices_renamedevicemodal_device: 'Dispositivo',
                    pages_devices_renamedevicemodal_errormsg:
                        'El nombre del dispositivo es obligatorio',
                    pages_devices_renamedevicemodal_rename:
                        'Nuevo nombre del dispositivo',
                    pages_diagnostics_diagnosticsgatewaypage_connected:
                        'Conectado',
                    pages_diagnostics_diagnosticsgatewaypage_disconnected:
                        'Desconectado',
                    pages_diagnostics_diagnosticsgatewaypage_totalmemory:
                        'Memoria Total',
                    pages_diagnostics_diagnosticsgatewaypage_reboots:
                        'Reinicios',
                    pages_diagnostics_diagnosticsgatewaypage_freemem:
                        'Memoria Disponible',
                    pages_diagnostics_diagnosticsgatewaypage_cpuload:
                        'Carga de la CPU',
                    pages_diagnostics_diagnosticsgatewaypage_vpnactive:
                        'La VPN ha estado activa por',
                    pages_diagnostics_diagnosticsgatewaypage_diagnostics:
                        'Diagnóstico',
                    pages_diagnostics_diagnosticsgatewaypage_properties:
                        'Propiedades',
                    pages_diagnostics_diagnosticsgatewaypage_status: 'Estado',
                    pages_diagnostics_diagnosticspage_notattached:
                        'No asignado',
                    pages_diagnostics_diagnosticspage_attached: 'Asignado a',
                    pages_diagnostics_diagnosticspage_gwuuid: 'UUID del GW',
                    pages_diagnostics_diagnosticspage_serialno: 'Nº Serie',
                    pages_diagnostics_diagnosticspage_status: 'Estado',
                    pages_diagnostics_diagnosticspage_sensoruuid:
                        'UUID del sensor',
                    pages_diagnostics_diagnosticspage_nojoin: 'No se ha unido',
                    pages_diagnostics_diagnosticspage_value: 'Valor',
                    pages_diagnostics_diagnosticspage_battery: 'Batería',
                    pages_diagnostics_diagnosticssensorpage_lastrx: 'Último RX',
                    pages_diagnostics_diagnosticssensorpage_lastjoin:
                        'Última Unión a la red',
                    pages_diagnostics_diagnosticssensorpage_gateway: 'Gateway',
                    pages_diagnostics_diagnosticssensorpage_halerts:
                        'Alertas de Salud',
                    pages_groups_assigngatewaymodal_errorMsg:
                        'Se debe seleccionar un gateway',
                    pages_groups_assigngatewaymodal_assign: 'Asignar Gateway',
                    pages_groups_assigngatewaymodal_gwassign:
                        'Gateway a asignar',
                    pages_groups_assignsensormodal_errorMsg:
                        'Se debe seleccionar un sensor',
                    pages_groups_assignsensormodal_assign: 'Asignar Sensor',
                    pages_groups_assignsensormodal_sensorassign:
                        'Sensor a asignar',
                    pages_groups_creategroupmodal_errorMsg:
                        'El nombre del grupo es obligatorio',
                    pages_groups_creategroupmodal_new: 'Nuevo Grupo',
                    pages_groups_creategroupmodal_namelbl: 'Nombre del grupo',
                    pages_groups_creategroupmodal_name: 'Nombre',
                    pages_groups_creategroupmodal_desclbl:
                        'Descripción del grupo',
                    pages_groups_creategroupmodal_desc: 'Descripción opcional',
                    pages_groups_gatewaygrouppage_settings: 'Configuración',
                    pages_groups_gatewaygrouppage_devices: 'Dispositivos',
                    pages_groups_gatewaygrouppage_gws: 'Gateways',
                    pages_groups_gatewaygrouppage_wlan: 'Consumo WLAN',
                    pages_groups_gatewaygrouppage_eth: 'Consumo ETH',
                    pages_groups_gatewaygrouppage_vpn: 'Estado de la VPN',
                    pages_groups_gatewaygrouppage_uptime: 'Tiempo de Actividad',
                    pages_groups_gatewaygrouppage_cpu: 'Uso de CPU',
                    pages_groups_gatewaygrouppage_sw: 'Versión SW',
                    pages_groups_gatewaygrouppage_value: 'Valor',
                    pages_groups_gatewaygrouppage_remove: 'Eliminar Gateway',
                    pages_groups_groupmemberspage_info:
                        'Estos miembros no podrán acceder más a este grupo',
                    pages_groups_groupprojectspage_info:
                        'No podrás recuperar estos recursos',
                    pages_groups_groupspage_groups: 'Grupos',
                    pages_groups_groupspage_create: 'Crear Grupo',
                    pages_groups_grouptableentry_subgroups: 'Subgrupos',
                    pages_groups_grouptableentry_projects: 'Proyectos',
                    pages_groups_grouptableentry_members: 'Miembors',
                    pages_groups_grouptitle_id: 'ID del Grupo',
                    pages_groups_grouptreestructure_empty:
                        'El grupo debe estar vacío',
                    pages_groups_grouptreestructure_createproject:
                        'Crear Proyecto',
                    pages_groups_grouptreestructure_assignmember:
                        'Asignar Miembro',
                    pages_groups_grouptreestructure_assigngw: 'Asignar Gateway',
                    pages_groups_grouptreestructure_assignsensor:
                        'Asignar Sensor',
                    pages_groups_grouptreestructure_creategroup: 'Crear Grupo',
                    pages_groups_grouptreestructure_move: 'Mover',
                    pages_groups_grouptreestructure_projects: 'Proyecto(s)',
                    pages_groups_grouptreestructure_delete: 'Eliminar',
                    pages_groups_grouptreestructure_remove: 'Quitar',
                    pages_groups_grouptreestructure_members: 'Miembro(s)',
                    pages_groups_grouptreestructure_devices: 'Dispositivo(s)',
                    pages_groups_grouptreestructure_groups: 'Grupo(s)',
                    pages_groups_grouptreestructure_editgroup: 'Editar Grupo',
                    pages_groups_grouptreestructure_deletegroup:
                        'Eliminar Group',
                    pages_groups_movedevicesmodal_move: 'Mover Dispositivos',
                    pages_groups_movedevicesmodal_moveto:
                        'Mover dispositivos a',
                    pages_groups_moveprojectsmodal_move: 'Mover Proyectos',
                    pages_groups_moveprojectsmodal_moveto: 'Mover proyectos a',
                    pages_groups_movegroupsmodal_move: 'Mover Grupos',
                    pages_groups_movegroupsmodal_moveto: 'Mover grupos a',
                    pages_login_forgotpasswordmodal_info:
                        'Te hemos enviado un email con el token que autoriza el cambio de contraseña. Rellena el siguiente formulario para reestablecerla.',
                    pages_login_forgotpasswordmodal_reset:
                        'Reestablecer Contraseña',
                    pages_login_forgotpasswordmodal_email: 'Tu email',
                    pages_login_forgotpasswordmodal_newpass: 'Nueva contraseña',
                    pages_login_forgotpasswordmodal_repeatpass:
                        'Repetir nueva contraseña',
                    pages_login_forgotpasswordmodal_token: 'Token',
                    pages_login_forgotpasswordmodal_resetbtn: 'Reestablecer',
                    pages_login_forgotpasswordmodal_sendtoken: 'Enviar Token',
                    pages_login_forgotpasswordmodal_verifyinputs:
                        'No se puede restablecer la contraseña. Compruebe sus entradas',
                    pages_login_loginpage_baddata:
                        'Has introducido un nombre de usuario o contraseña inválido',
                    pages_login_loginpage_required:
                        'Todos los campos deben ser rellenos',
                    pages_login_loginpage_username: 'Nombre de usuario',
                    pages_login_loginpage_password: 'Contraseña',
                    pages_login_loginpage_forgot: '¿ Olvidaste tu contraseña ?',
                    pages_login_loginpage_login: 'Iniciar Sesión',
                    pages_profile_profilepage_cannotchange:
                        'La contraseña no puede cambiarse',
                    pages_profile_profilepage_profile: 'Perfil',
                    pages_profile_profilepage_username: 'Nombre de Usuario',
                    pages_profile_profilepage_email: 'Email',
                    pages_profile_profilepage_tz: 'Zona Horaria',
                    pages_profile_profilepage_lang: 'Idioma',
                    pages_profile_profilepage_save:
                        'Guardar cambios en el perfil',
                    pages_profile_profilepage_savechanges: 'Guardar cambios',
                    pages_profile_profilepage_change: 'Cambiar contraseña',
                    pages_profile_profilepage_old: 'Contraseña antigua',
                    pages_profile_profilepage_new: 'Nueva contraseña',
                    pages_profile_profilepage_confirm:
                        'Confirmar nueva contraseña',
                    pages_viewgraph_viewgraph_back:
                        'Volver a la página de Dashboards',
                    pages_users_createusermodal_bademail:
                        'El formato del email no es correcto',
                    pages_users_createusermodal_badpassword:
                        'Your password must be at least 8 characters long, contain at least one number, one special character and have a mixture of uppercase and lowercase letters.',
                    pages_users_createusermodal_allfields:
                        'Todos los campos deben ser rellenos',
                    pages_users_createusermodal_newuser: 'Nuevo Usuario',
                    pages_users_createusermodal_user: 'Usuario',
                    pages_users_createusermodal_role: 'Rol',
                    pages_users_userentrytable_unknown: 'Desconocido',
                    pages_users_userpage_membersince: 'Miembro Desde',
                    pages_users_userpage_lastsignin: 'Último inicio de sesión',
                    pages_users_userpage_createdby: 'Creado por',
                    pages_users_userpage_users: 'Usuarios',
                    pages_users_userpage_profile: 'Perfil',
                    pages_users_userpage_gp: 'Grupos y Proyectos',
                    pages_users_userpage_accessto: 'Acceso a',
                    pages_users_userpage_projects: 'proyectos',
                    pages_users_userspage_create: 'Crear Usuario',
                    pages_users_userspage_createdon: 'Creado en',
                    pages_users_userspage_lastactivity: 'Última actividad',
                    pages_sitesettings_alarmentrypage_active: '¿Está Activa?',
                    pages_sitesettings_alarmentrypage_lower: 'Límite Inferior',
                    pages_sitesettings_alarmentrypage_upper: 'Límite Superior',
                    pages_sitesettings_alarmentrypage_hlow:
                        'Histéresis Inferior',
                    pages_sitesettings_alarmentrypage_hupp:
                        'Histéresis Superior',
                    pages_sitesettings_alarmentrypage_from: 'Activable desde',
                    pages_sitesettings_alarmentrypage_to: 'Activable hasta',
                    pages_sitesettings_alarmentrypage_alarms: 'Alarmas',
                    pages_sitesettings_alarmentrypage_edit: 'Editar Alarma',
                    pages_sitesettings_alarmentrypage_delete: 'Eliminar Alarma',
                    pages_sitesettings_alarmentrypage_new: 'Nueva Alarma',
                    pages_sitesettings_alarmentrypage_remove: 'Eliminar',
                    pages_sitesettings_alarmentrypage_alarmslower: 'alarmas',
                    pages_sitesettings_alarmentrypage_fromtxt: 'Desde',
                    pages_sitesettings_alarmentrypage_totxt: 'Hasta',
                    pages_sitesettings_alarmentrypage_activetxt: 'Activa',
                    pages_sitesettings_alarmentrypage_watchers: 'Observadores',
                    pages_sitesettings_alarmentrypage_addwatcher:
                        'Añadir Observador',
                    pages_sitesettings_assigngatewaymodal_errortxt:
                        'Se debe seleccionar un gateway',
                    pages_sitesettings_assigngatewaymodal_assign:
                        'Asignar Gateway',
                    pages_sitesettings_assigngatewaymodal_assigngw:
                        'Asignar gateway al proyecto',
                    pages_sitesettings_assignsensormodal_errortxt:
                        'Se debe seleccionar un sensor',
                    pages_sitesettings_assignsensormodal_assign:
                        'Asignar Sensor',
                    pages_sitesettings_assignsensormodal_assignsensor:
                        'Asignar sensor al punto de medida',
                    pages_sitesettings_assignusermodal_errortxt:
                        'Se debe seleccionar un usuario',
                    pages_sitesettings_assignusermodal_assign:
                        'Asignar Usuario',
                    pages_sitesettings_assignusermodal_assignuser:
                        'Asignar usuario al proyecto',
                    pages_sitesettings_backupgenerationmodal_generation:
                        'Generación de Copia de Seguridad',
                    pages_sitesettings_backupgenerationmodal_backuptxt:
                        'Se está generando la copia de seguridad del proyecto. Mantenga el diálogo abierto hasta que la generación haya acabado. Después, haga click en el botón de descarga.',
                    pages_sitesettings_backupgenerationmodal_download:
                        'Descargar',
                    pages_sitesettings_createalarmmodal_limiterror:
                        'Los límites inferior y superior (y los valores de histéresis) deben ser valores númericos',
                    pages_sitesettings_createalarmmodal_nameerror:
                        'El nombre de la alarma es obligatorio',
                    pages_sitesettings_createalarmmodal_new: 'Nueva Alarma',
                    pages_sitesettings_createmanualmodal_errortxt:
                        'El valor manual y la lectura del display deben ser valores numéricos',
                    pages_sitesettings_createmanualmodal_new:
                        'Nueva Medida Manual',
                    pages_sitesettings_createmanualmodal_date: 'Fecha',
                    pages_sitesettings_createmanualmodal_manual:
                        'Valor Manual [ppm]',
                    pages_sitesettings_createmanualmodal_display:
                        'Lectura del Display [ppm]',
                    pages_sitesettings_creatempmodal_errortxt:
                        'El nombre del punto de medida es obligatorio',
                    pages_sitesettings_creatempmodal_new:
                        'Nuevo Punto de Medida',
                    pages_sitesettings_creatempmodal_namelbl:
                        'Nombre del punto de medida',
                    pages_sitesettings_createsitegroupmodal_errortxt:
                        'El nombre del grupo es obligatorio',
                    pages_sitesettings_createsitegroupmodal_new: 'Nuevo grupo',
                    pages_sitesettings_createsitegroupmodal_namelbl:
                        'Nombre del grupo',
                    pages_sitesettings_createsitemainpagemodal_errortxt:
                        'El nombre del proyecto es obligatorio',
                    pages_sitesettings_createsitemainpagemodal_new:
                        'Nuevo Proyecto',
                    pages_sitesettings_createsitemainpagemodal_namelbl:
                        'Nombre del Proyecto',
                    pages_sitesettings_createsitemainpagemodal_descr:
                        'Descripción',
                    pages_sitesettings_createsitemainpagemodal_descrlbl:
                        'Descripción del Proyecto',
                    pages_sitesettings_createsitemainpagemodal_attach:
                        'Asignar proyecto a',
                    pages_sitesettings_gatewayentrypage_sure:
                        'El gateway será eliminado del proyecto',
                    pages_sitesettings_gatewayentrypage_remove:
                        'Eliminar Gateway',
                    pages_sitesettings_gatewayentrypage_openvpn:
                        'Abrir el túnel VPN',
                    pages_sitesettings_gatewayentrypage_closevpn:
                        'Cerrar el túnel VPN',
                    pages_sitesettings_gatewayentrypage_update:
                        'Actualizar Firmware',
                    pages_sitesettings_gatewayssitepage_assign:
                        'Asignar Gateway',
                    pages_sitesettings_importbackupmodal_import:
                        'Importar Copia de Seguridad',
                    pages_sitesettings_importbackupmodal_include:
                        'Incluir Datos',
                    pages_sitesettings_importbackupmodal_files:
                        'Archivos a Importar',
                    pages_sitesettings_importbackupmodal_import: 'Importar',
                    pages_sitesettings_manualmeasurementspage_tester:
                        'Examinador',
                    pages_sitesettings_manualmeasurementspage_acvalue:
                        'Valor AquaCloud [ppm]',
                    pages_sitesettings_manualmeasurementspage_remove:
                        'Eliminar',
                    pages_sitesettings_manualmeasurementspage_manualmeasurements:
                        'medidas manuales',
                    pages_sitesettings_manualmeasurementspage_new:
                        'Nueva medida manual',
                    pages_sitesettings_measurementpointpage_errortxt:
                        'Este punto de medida dejará de medir datos',
                    pages_sitesettings_measurementpointpage_incomplete:
                        'incompleto',
                    pages_sitesettings_measurementpointpage_active: 'activo',
                    pages_sitesettings_measurementpointpage_inactive:
                        'inactivo',
                    pages_sitesettings_measurementpointpage_assign:
                        'Asignar Sensor',
                    pages_sitesettings_measurementpointpage_removesensor:
                        'Eliminar Sensor',
                    pages_sitesettings_measurementpointpage_editmp: 'Editar PM',
                    pages_sitesettings_measurementpointpage_deletemp:
                        'Eliminar PM',
                    pages_sitesettings_measurementpointpage_lasttemp:
                        'Último valor de temperatura',
                    pages_sitesettings_measurementpointpage_lastbatt:
                        'Último valor de batería',
                    pages_sitesettings_measurementpointpage_lastmeas:
                        'Última medida',
                    pages_sitesettings_memberentrypage_errortxt:
                        'Este usuario no podrá acceder más al proyecto',
                    pages_sitesettings_memberentrypage_remove:
                        'Eliminar Miembro',
                    pages_sitesettings_memberentrypage_created: 'Creación',
                    pages_sitesettings_memberentrypage_lastlogin:
                        'Último Inicio de Sesión',
                    pages_sitesettings_movempgroupsmodal_move: 'Mover Items',
                    pages_sitesettings_movempgroupsmodal_new: 'Nuevo grupo',
                    pages_sitesettings_sitesettingspage_empty:
                        'El grupo debe estar vacío',
                    pages_sitesettings_sitesettingspage_confirmation:
                        'Se van a retirar todos los gateways del proyecto y todos los sensores serán liberados de sus puntos de medida.',
                    pages_sitesettings_sitesettingspage_createmp:
                        'Crear Punto de Medida',
                    pages_sitesettings_sitesettingspage_movesel: 'Mover',
                    pages_sitesettings_sitesettingspage_moveitems: 'Item(s)',
                    pages_sitesettings_sitesettingspage_deletesel: 'Eliminar',
                    pages_sitesettings_sitesettingspage_deleteitems: 'Item(s)',
                    pages_sitesettings_sitesettingspage_editproj:
                        'Editar Proyecto',
                    pages_sitesettings_sitesettingspage_backup:
                        'Generar Copia de Seguridad',
                    pages_sitesettings_sitesettingspage_free:
                        'Liberar todos los dispositivos',
                    pages_sitesettings_sitesettingspage_deleteproj:
                        'Eliminar Proyecto',
                    pages_sitesettings_sitesettingspage_editgroup:
                        'Editar Grupo',
                    pages_sitesettings_sitesettingspage_delgroup:
                        'Eliminar Grupo',
                    pages_sitesettings_sitesettingspage_sensor: 'Sensor',
                    pages_sitesettings_sitetableentry_updated: 'Actualizado',
                    pages_sitesettings_sitetitle_projid: 'ID de Proyecto',
                    pages_sitesettings_sitetitle_installationmode:
                        'Modo de Instalación',
                    pages_sitesettings_sitetitle_start1: 'Comenzar',
                    pages_sitesettings_sitetitle_stop1: 'Finalizar',
                    pages_sitesettings_sitetitle_start2: '',
                    pages_sitesettings_sitetitle_stop2: '',
                    pages_sitesettings_sitetitle_warntext:
                        'Los sensores van a reducir su intervalo de muestreo a 1 minuto. Si el modo de instalación se mantiene activo por mucho tiempo, la batería de los sensores podría degradarse prematuramente.',
                    modal_edit: 'Editar',
                    modal_create: 'Crear',
                    modal_cancel: 'Cancelar',
                    modal_rename: 'Renombrar',
                    modal_assign: 'Asignar',
                    modal_move: 'Mover',
                    util_lastupdated_first: 'hace',
                    util_lastupdated_second: '',
                    util_lastupdate_seconds: 'segundos',
                    util_lastupdate_minutes: 'minutos',
                    util_lastupdate_hours: 'horas',
                    util_lastupdate_days: 'días',
                    util_lastupdate_months: 'meses',
                    util_lastupdate_years: 'años',
                },
            },
        },
        fallbackLng: 'en',

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ',',
        },

        react: {
            useSuspense: true,
        },
    });

export default i18n;
