export function getProperRoute(route) {
    return '/' + window.location.pathname.split('/')[1] + '/' + route;
}

export function userIsAtLeast(user, role) {
    const roles = ['reporter', 'analyst', 'maintainer', 'admin'];
    const desiredRoleIndex = roles.indexOf(role);
    if (desiredRoleIndex === -1) {
        return false;
    }
    const usersRoleIndex = roles.indexOf(user?.role ?? 'reporter');
    return usersRoleIndex >= desiredRoleIndex;
}

export function isNullOrUndefined(obj) {
    return obj === null || obj === undefined;
}

export function getFullGroups(groups, site) {
    let parentGroup = groups?.filter((g) => g.id === site?.groupId)[0];
    const fullGroups = [];
    while (!isNullOrUndefined(parentGroup)) {
        if (parentGroup.id !== 1) {
            fullGroups.push({
                link: getProperRoute(`groups/${parentGroup.id}/settings`),
                text: parentGroup.name,
            });
        }
        parentGroup = groups?.filter((g) => g.id === parentGroup.parent?.id)[0];
    }
    fullGroups.reverse();
    return fullGroups;
}

export function getProperSiteSettingsPage(groupId, siteId, siteType) {
    if (siteType === 'filter') {
        return `groups/${groupId}/sites/filter/${siteId}/parameters`;
    } else {
        return `groups/${groupId}/sites/${siteId}/settings`;
    }
}

export function redirectToSiteTypeApp(site, url, redirectTo = null) {
    if (!isNullOrUndefined(site)) {
        if (
            window.location.href.includes('sites/filter') &&
            site.type !== 'filter'
        ) {
            window.location.href = getProperRoute(url);
        } else if (
            !window.location.href.includes('sites/filter') &&
            site.type === 'filter'
        ) {
            if (isNullOrUndefined(redirectTo)) {
                url = url.replace('/sites/', '/sites/filter/');
                window.location.href = getProperRoute(url);
            } else {
                window.location.href = getProperRoute(redirectTo);
            }
        }
    }
}
