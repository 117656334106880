import { GroupApi, SiteApi } from '@aurum/nucleus-client-api';
import { CustomBreadcrumbs, FilesTable, MoreSettingsMenu } from '@components';
import { MenuItem } from '@material-ui/core';
import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import ImportBackupModal from '@views/sites/backup-site/import-backup/ImportBackupModal';
import CreateSiteMainPageModal from '@views/sites/create-site/CreateSiteMainPageModal';
import SiteTableEntry from '@views/sites/site-entry/SiteTableEntry';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

export default function SitesPage(props) {
    const [groups, setGroups] = useState(null);
    const [sites, setSites] = useState([]);
    const [createProjectOpenModal, setCreateProjectOpenModal] = useState(false);
    const [importBackupModal, setImportBackupModal] = useState(false);

    const { t } = useTranslation();
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    useEffect(() => {
        const allsites = [];
        new GroupApi().groupGetGroups(function (_, data, response) {
            if (response.ok) {
                data?.forEach((group) => {
                    if (group.id !== 1) {
                        allsites.push(...group.sites);
                    }
                });
                setSites([...sites, ...allsites]);
                setGroups(data);
            }
        });
        // get sites assigned to root group, if the user has acess to them
        new SiteApi().siteGetSites(1, function (_, data, response) {
            if (response.ok) {
                allsites.push(...data.filter((s) => s.groupId === 1));
                setSites([...sites, ...allsites]);
            }
        });
    }, []);

    const onCreateProject = (name, description, group, type) => {
        if (!isNullOrUndefined(name) && name.trim() !== '') {
            new SiteApi().siteAddSite(
                group,
                { name: name, description: description, type: type },
                function (_, data, response) {
                    if (response.ok) {
                        setSites([...sites, data]);
                        setCreateProjectOpenModal(false);
                    }
                }
            );
        }
    };

    const valuesTopGroup = [];
    sites?.sort(
        (a, b) => b?.lastUpdated?.getTime() - a?.lastUpdated?.getTime()
    );
    sites?.forEach((site) => {
        valuesTopGroup.push([
            {
                flex: true,
                text: site?.name,
                value: (
                    <SiteTableEntry
                        link={
                            isNullOrUndefined(site.defaultDashboardId)
                                ? getProperRoute(
                                      site.type === 'filter'
                                          ? `groups/${site.groupId}/sites/filter/${site.id}/parameters`
                                          : `groups/${site.groupId}/sites/${site.id}/settings`
                                  )
                                : getProperRoute(
                                      site.type === 'filter'
                                          ? `groups/${site.groupId}/sites/filter/${site.id}/dashboards/${site.defaultDashboardId}`
                                          : `groups/${site.groupId}/sites/${site.id}/dashboards/${site.defaultDashboardId}`
                                  )
                        }
                        site={site}
                        user={props.user}
                    />
                ),
            },
        ]);
    });

    const links = [
        {
            link: getProperRoute('sites'),
            text: t('pagelayout_sidebargroup_projects'),
        },
    ];
    return isNullOrUndefined(sites) ? (
        <> </>
    ) : (
        <div style={{ paddingLeft: isMobile ? 0 : '5%', width: '90vw' }}>
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <CustomBreadcrumbs
                    datacy="projects-breadcrumbs"
                    breadcrumbs={links}
                />
                <MoreSettingsMenu
                    datacy="projects-menu"
                    disabled={!userIsAtLeast(props.user, 'analyst')}>
                    {userIsAtLeast(props.user, 'analyst') ? (
                        <>
                            <MenuItem
                                onClick={() => setCreateProjectOpenModal(true)}>
                                {t(
                                    'pages_groups_grouptreestructure_createproject'
                                )}
                            </MenuItem>
                            <MenuItem
                                onClick={() => setImportBackupModal(true)}>
                                {t(
                                    'pages_sitesettings_importbackupmodal_import'
                                )}
                            </MenuItem>
                        </>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <hr /> <br />
            <FilesTable
                datacy="projects-table"
                pagination
                search
                columns={[t('common_filestable_namecol')]}
                rows={valuesTopGroup}
            />
            <CreateSiteMainPageModal
                groups={groups}
                open={createProjectOpenModal}
                handleClose={() => setCreateProjectOpenModal(false)}
                confirm={(name, descr, group, type) =>
                    onCreateProject(name, descr, group, type)
                }
            />
            <ImportBackupModal
                groups={groups}
                open={importBackupModal}
                handleClose={() => setImportBackupModal(false)}
            />
        </div>
    );
}
