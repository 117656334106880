import { GroupApi } from '@aurum/nucleus-client-api';
import { MenuItem, MenuList } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import {
    CustomBreadcrumbs,
    FilesTable,
    NavButton,
    MoreSettingsMenu,
} from '@components';
import CreateGroupModal from '@views/groups/general-settings/create-group/CreateGroupModal';
import GroupTitle from '@views/groups/group-entry/GroupTitle';

export default function GroupTreeStructure(props) {
    const { groupId } = useParams();
    const [groups, setGroups] = useState(props.groups ?? null);
    const [curGroup, setCurGroup] = useState(
        isNullOrUndefined(groupId) || groupId === '1'
            ? props.projects
                ? 1
                : null
            : parseInt(groupId, 10)
    ); // current group that is being displayed (if any)
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [selectedSensors, setSelectedSensors] = useState([]);
    const [selectedGws, setSelectedGws] = useState([]);
    const [numSelected, setNumSelected] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        setGroups(props.groups);
    }, [props.groups]);

    // get the current group, if any
    const group =
        curGroup === 1
            ? groups?.filter((g) => g.id === 1)[0]
            : groups?.filter((g) => g.id === curGroup)[0];

    const onGroupClick = (gid) => {
        if (gid === 1) {
            setCurGroup(gid);
            window.history.replaceState(null, '', getProperRoute(`groups`));
            return;
        }
        window.history.replaceState(
            null,
            '',
            props.members
                ? getProperRoute(`groups/${gid}/members`)
                : props.projects
                ? getProperRoute(`groups/${gid}/projects`)
                    ? props.devices
                    : getProperRoute(`groups/${gid}/devices`)
                : getProperRoute(`groups/${gid}/settings`)
        );
        // set the id of the current group being displayed
        setCurGroup(gid);
        setSelectedGroups([]);
        setSelectedProjects([]);
        setSelectedMembers([]);
        setNumSelected(0);
    };

    const selectedRow = (selected, type, id) => {
        if (type === 'group') {
            id === -1
                ? setNumSelected(selected ? groups.length : 0)
                : setNumSelected(selected ? numSelected + 1 : numSelected - 1);
            if (selected) {
                if (id === -1) {
                    setSelectedGroups([...groups]);
                } else {
                    const grp = groups.filter((g) => g.id === id)[0];
                    if (!isNullOrUndefined(grp)) {
                        setSelectedGroups([...selectedGroups, grp]);
                    }
                }
            } else {
                id === -1
                    ? setSelectedGroups([])
                    : setSelectedGroups(
                          selectedGroups.filter((g) => g.id !== id)
                      );
            }
        } else if (type === 'user') {
            id === -1
                ? setNumSelected(selected ? group?.memberGroups.length : 0)
                : setNumSelected(selected ? numSelected + 1 : numSelected - 1);
            if (selected) {
                if (id === -1) {
                    setSelectedMembers([...group?.memberGroups]);
                } else {
                    const member = group?.memberGroups?.filter(
                        (mg) => mg.user?.id === id
                    )[0];
                    if (!isNullOrUndefined(member)) {
                        setSelectedMembers([...selectedMembers, member]);
                    }
                }
            } else {
                id === -1
                    ? setSelectedMembers([])
                    : setSelectedMembers(
                          selectedMembers.filter((m) => m.user?.id !== id)
                      );
            }
        } else if (type === 'project') {
            id === -1
                ? setNumSelected(selected ? group?.sites.length : 0)
                : setNumSelected(selected ? numSelected + 1 : numSelected - 1);
            if (selected) {
                if (id === -1) {
                    setSelectedProjects([...group?.sites]);
                } else {
                    const project = group?.sites?.filter((s) => s.id === id)[0];
                    if (!isNullOrUndefined(project)) {
                        setSelectedProjects([...selectedProjects, project]);
                    }
                }
            } else {
                id === -1
                    ? setSelectedProjects([])
                    : setSelectedProjects(
                          selectedProjects.filter((p) => p.id !== id)
                      );
            }
        } else if (type === 'gateway') {
            id === -1
                ? setNumSelected(selected ? group?.gateways.length : 0)
                : setNumSelected(selected ? numSelected + 1 : numSelected - 1);
            if (selected) {
                if (id === -1) {
                    setSelectedGws([...group?.gateways]);
                } else {
                    const gw = group?.gateways?.filter((g) => g.uuid === id)[0];
                    if (!isNullOrUndefined(gw)) {
                        setSelectedGws([...selectedGws, gw]);
                    }
                }
            } else {
                id === -1
                    ? setSelectedGws([])
                    : setSelectedGws(selectedGws.filter((g) => g.uuid !== id));
            }
        } else if (type === 'sensor') {
            id === -1
                ? setNumSelected(selected ? group?.sensors.length : 0)
                : setNumSelected(selected ? numSelected + 1 : numSelected - 1);
            if (selected) {
                if (id === -1) {
                    setSelectedSensors([...group?.sensors]);
                } else {
                    const sensor = group?.sensors?.filter(
                        (g) => g.uuid === id
                    )[0];
                    if (!isNullOrUndefined(sensor)) {
                        setSelectedSensors([...selectedSensors, sensor]);
                    }
                }
            } else {
                id === -1
                    ? setSelectedSensors([])
                    : setSelectedSensors(
                          selectedSensors.filter((g) => g.uuid !== id)
                      );
            }
        }
    };

    const confirmGroupCreation = (name, description) => {
        if (!isNullOrUndefined(name) && name.trim() !== '') {
            let parent = null;
            if (!isNullOrUndefined(curGroup)) {
                parent = { id: parseInt(curGroup) };
            }
            new GroupApi().groupCreateGroup(
                { name: name, description: description, parent: parent },
                function (_, data, response) {
                    if (response.ok) {
                        setGroups([...groups, data]);
                        setModalOpen(false);
                    }
                }
            );
        }
    };

    const confirmGroupEdit = (name, description) => {
        if (!isNullOrUndefined(name) && name.trim() !== '') {
            if (name !== group.name || description !== group.description) {
                new GroupApi().groupUpdateGroup(
                    parseInt(groupId, 10),
                    {
                        name: name,
                        description: description,
                        parent: group.parent,
                    },
                    function (_, data, response) {
                        if (response.ok) {
                            group.name = name;
                            group.description = description;
                            setEditModalOpen(false);
                        }
                    }
                );
            } else {
                setEditModalOpen(false);
            }
        }
    };

    const onDeleteGroup = () => {
        swal({
            title: t('swal_sure'),
            text: t('swal_cannot_recover_resource'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult && !isNullOrUndefined(group)) {
                new GroupApi().groupDeleteGroup(
                    group.id,
                    function (_, data, response) {
                        if (response.ok) {
                            if (isNullOrUndefined(group.parent?.id)) {
                                window.location.href = getProperRoute('groups');
                            } else {
                                window.location.href = getProperRoute(
                                    `groups/${group.parent.id}/settings`
                                );
                            }
                        } else if (response.statusCode === 400) {
                            swal({
                                text: t(
                                    'pages_groups_grouptreestructure_empty'
                                ),
                                icon: 'error',
                            });
                        }
                    }
                );
            }
        });
    };

    const rows = [];
    // only the groups with the current group as parent, will be displayed
    // if current group is null, the groups without parent will be displayed
    let applicable = [];
    if (isNullOrUndefined(curGroup)) {
        applicable = groups?.filter((g) => g.parent?.id === 1) ?? [];
        applicable = [
            ...applicable,
            ...(groups?.filter(
                (g) =>
                    !isNullOrUndefined(g.parent?.id) &&
                    g.parent?.id !== 1 &&
                    groups.filter((gr) => gr.id === g.parent.id).length === 0
            ) ?? []),
        ];
    } else {
        applicable = groups?.filter((g) => g.parent?.id === curGroup) ?? [];
    }
    applicable?.forEach((group) => {
        rows.push([
            {
                onClick: onGroupClick,
                id: group.id,
                checked: !isNullOrUndefined(
                    selectedGroups?.filter((g) => g.id === group.id)[0]
                ),
                addCheckbox: !(
                    props.members ||
                    props.projects ||
                    props.devices
                ),
                text: group.name,
                value: group.name,
                icon: 'group',
            },
        ]);
    });
    if (props.members) {
        group?.memberGroups?.forEach((member) => {
            rows.push([
                {
                    link: getProperRoute(
                        `groups/${group.id}/members/${member.user?.id}`
                    ),
                    checked: !isNullOrUndefined(
                        selectedMembers?.filter(
                            (m) => m.user?.id === member.user?.id
                        )[0]
                    ),
                    addCheckbox: true,
                    id: member.user?.id,
                    text: member.user?.userName,
                    value: member.user?.userName,
                    icon: 'user',
                },
            ]);
        });
    }
    if (props.projects) {
        group?.sites?.forEach((site) => {
            rows.push([
                {
                    link: isNullOrUndefined(site.defaultDashboardId)
                        ? getProperRoute(
                              `groups/${group.id}/sites/${site.id}/settings`
                          )
                        : getProperRoute(
                              `groups/${group.id}/sites/${site.id}/dashboards/${site.defaultDashboardId}`
                          ),
                    checked: !isNullOrUndefined(
                        selectedProjects?.filter((p) => p.id === site.id)[0]
                    ),
                    addCheckbox: true,
                    id: site.id,
                    text: site.name,
                    value: site.name,
                    icon: 'project',
                },
            ]);
        });
    }
    if (props.devices) {
        group?.gateways?.forEach((gateway) => {
            rows.push([
                {
                    link: getProperRoute(
                        `groups/${group.id}/devices/${gateway.uuid}`
                    ),
                    checked: !isNullOrUndefined(
                        selectedGws?.filter((g) => g.uuid === gateway.uuid)[0]
                    ),
                    addCheckbox: true,
                    id: gateway.uuid,
                    text: gateway.name,
                    value: gateway.name,
                    icon: 'gateway',
                },
            ]);
        });
        group?.sensors?.forEach((sensor) => {
            rows.push([
                {
                    onClick: function () {},
                    checked: !isNullOrUndefined(
                        selectedSensors?.filter(
                            (s) => s.uuid === sensor.uuid
                        )[0]
                    ),
                    addCheckbox: true,
                    id: sensor.uuid,
                    text: sensor.description,
                    value: sensor.description,
                    icon: 'sensor',
                },
            ]);
        });
    }

    // create the breadcrumbs structure
    const curGroupPath = [];
    let cur = groups?.filter((g) => g.id === curGroup)[0];
    while (!isNullOrUndefined(cur)) {
        if (cur.id !== 1) {
            curGroupPath.push({
                onClick: onGroupClick,
                id: cur.id,
                text: cur.name,
            });
        }
        cur = groups?.filter((g) => g.id === cur.parent?.id)[0];
    }
    curGroupPath.push({
        onClick: onGroupClick,
        id: 1,
        text: t('pages_groups_groupspage_groups'),
    });
    curGroupPath.reverse();

    return (
        <>
            {isNullOrUndefined(group) || group.id === 1 ? (
                <></>
            ) : (
                <>
                    <div
                        style={{
                            display: 'inline-flex',
                            flexDirection: 'row',
                            width: '100%',
                            alignItems: 'center',
                            marginBottom: '10px',
                        }}>
                        <GroupTitle group={group} />
                    </div>
                    <hr /> <br />
                </>
            )}
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        ...curGroupPath,
                        {
                            link: getProperRoute('/'),
                            text: (
                                <NavButton
                                    disabled={
                                        !userIsAtLeast(props.user, 'analyst')
                                    }
                                    color="black"
                                    background="gray"
                                    id="menu-add-group"
                                    text={<AddIcon />}
                                    icon={<ExpandMoreIcon />}>
                                    <MenuList id="menu-add-group">
                                        {props.projects ? (
                                            <MenuItem
                                                onClick={() =>
                                                    props.createProject &&
                                                    props.createProject(group)
                                                }>
                                                {t(
                                                    'pages_groups_grouptreestructure_createproject'
                                                )}
                                            </MenuItem>
                                        ) : props.members ? (
                                            <MenuItem
                                                onClick={() =>
                                                    props.assignMember &&
                                                    props.assignMember(group)
                                                }>
                                                {t(
                                                    'pages_groups_grouptreestructure_assignmember'
                                                )}
                                            </MenuItem>
                                        ) : props.devices ? (
                                            <>
                                                <MenuItem
                                                    onClick={() =>
                                                        props.assignGateway &&
                                                        props.assignGateway()
                                                    }>
                                                    {t(
                                                        'pages_groups_grouptreestructure_assigngw'
                                                    )}
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() =>
                                                        props.assignSensor &&
                                                        props.assignSensor()
                                                    }>
                                                    {t(
                                                        'pages_groups_grouptreestructure_assignsensor'
                                                    )}
                                                </MenuItem>
                                            </>
                                        ) : (
                                            <MenuItem
                                                onClick={() =>
                                                    setModalOpen(true)
                                                }>
                                                {t(
                                                    'pages_groups_grouptreestructure_creategroup'
                                                )}
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                </NavButton>
                            ),
                        },
                    ]}
                />
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'analyst')}>
                    {numSelected === 0 ? (
                        <></>
                    ) : props.projects ? (
                        <>
                            <MenuItem
                                onClick={() =>
                                    props.moveProjects &&
                                    props.moveProjects(selectedProjects)
                                }>
                                {t('pages_groups_grouptreestructure_move')}{' '}
                                {numSelected}{' '}
                                {t('pages_groups_grouptreestructure_projects')}
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    props.deleteProjects &&
                                    props.deleteProjects(selectedProjects)
                                }>
                                {t('pages_groups_grouptreestructure_delete')}{' '}
                                {numSelected}{' '}
                                {t('pages_groups_grouptreestructure_projects')}
                            </MenuItem>
                            <hr />
                        </>
                    ) : props.members ? (
                        <>
                            <MenuItem
                                onClick={() =>
                                    props.deleteMembers &&
                                    props.deleteMembers(selectedMembers)
                                }>
                                {t('pages_groups_grouptreestructure_remove')}{' '}
                                {numSelected}{' '}
                                {t('pages_groups_grouptreestructure_members')}
                            </MenuItem>
                            <hr />
                        </>
                    ) : props.devices ? (
                        <>
                            <MenuItem
                                onClick={() =>
                                    props.moveDevices &&
                                    props.moveDevices(
                                        selectedGws,
                                        selectedSensors
                                    )
                                }>
                                {t('pages_groups_grouptreestructure_move')}{' '}
                                {numSelected}{' '}
                                {t('pages_groups_grouptreestructure_devices')}
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    props.removeDevices &&
                                    props.removeDevices(
                                        selectedGws,
                                        selectedSensors
                                    )
                                }>
                                {t('pages_groups_grouptreestructure_remove')}{' '}
                                {numSelected}{' '}
                                {t('pages_groups_grouptreestructure_devices')}
                            </MenuItem>
                            <hr />
                        </>
                    ) : (
                        <>
                            <MenuItem
                                onClick={() =>
                                    props.moveSelected &&
                                    props.moveSelected(selectedGroups)
                                }>
                                {t('pages_groups_grouptreestructure_move')}{' '}
                                {numSelected}{' '}
                                {t('pages_groups_grouptreestructure_groups')}
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    props.deleteSelected &&
                                    props.deleteSelected(selectedGroups)
                                }>
                                {t('pages_groups_grouptreestructure_delete')}{' '}
                                {numSelected}{' '}
                                {t('pages_groups_grouptreestructure_groups')}
                            </MenuItem>
                            <hr />
                        </>
                    )}
                    {props.projects || props.members || props.devices ? (
                        <> </>
                    ) : (
                        <>
                            <MenuItem onClick={() => setEditModalOpen(true)}>
                                {t('pages_groups_grouptreestructure_editgroup')}
                            </MenuItem>
                            <MenuItem onClick={() => onDeleteGroup()}>
                                {t(
                                    'pages_groups_grouptreestructure_deletegroup'
                                )}
                            </MenuItem>
                        </>
                    )}
                </MoreSettingsMenu>
            </div>
            <br />
            <FilesTable
                columns={[t('common_filestable_namecol')]}
                rows={rows}
                onCheckbox={(selected, type, id) =>
                    selectedRow(selected, type, id)
                }
            />
            <CreateGroupModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                confirm={confirmGroupCreation}
            />
            <CreateGroupModal
                edit
                name={group?.name}
                description={group?.description}
                open={editModalOpen}
                handleClose={() => setEditModalOpen(false)}
                confirm={confirmGroupEdit}
            />
        </>
    );
}
