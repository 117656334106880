import { GroupApi } from '@aurum/nucleus-client-api';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import GroupTreeStructure from '@views/groups/group-page/GroupTreeStructure';
import AssignUserModal from '@views/sites/settings/members/assign-member/AssignUserModal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function GroupMembersPage(props) {
    const [group, setGroup] = useState(null);
    const [allUsers, setAllUsers] = useState([]);
    const [assignUserModal, setAssignUserModal] = useState(false);
    const { groupId } = useParams();

    const { t } = useTranslation();

    const assignUser = (uid) => {
        if (!isNullOrUndefined(uid) && uid.trim() !== '') {
            const user = allUsers?.filter((usr) => usr.id === uid)[0];
            if (!isNullOrUndefined(user) && !isNullOrUndefined(group)) {
                group.memberGroups.push({ group: group.id, user: user });
                new GroupApi().groupUpdateGroup(
                    group.id,
                    group,
                    function (_, data, response) {
                        if (response.ok) {
                            setAllUsers(
                                allUsers?.filter((usr) => usr.id !== uid)
                            );
                            setAssignUserModal(false);
                        }
                    }
                );
            }
        }
    };

    const onOpenAssignUser = (gr) => {
        if (!isNullOrUndefined(gr)) {
            new GroupApi().groupGetUnassignedUsersFromGroup(
                gr.id,
                function (_, data, response) {
                    if (response.ok) {
                        setAllUsers(data);
                    }
                }
            );
        }
        setAssignUserModal(true);
        setGroup(gr);
    };

    const removeSelected = (selectedMembers) => {
        swal({
            title: t('swal_sure'),
            text: t('pages_groups_groupmemberspage_info'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            const currentGroup = props.groups?.filter(
                (g) => g.id === parseInt(groupId, 10)
            )[0];
            if (
                confirmResult &&
                selectedMembers.length > 0 &&
                !isNullOrUndefined(currentGroup)
            ) {
                selectedMembers?.forEach((member) => {
                    currentGroup.memberGroups =
                        currentGroup.memberGroups.filter(
                            (mg) => mg.user.id !== member.user.id
                        );
                });
                new GroupApi().groupUpdateGroup(
                    currentGroup.id,
                    currentGroup,
                    function (_, data, response) {
                        if (response.ok) {
                            window.location.reload();
                        }
                    }
                );
            }
        });
    };

    const unassignedData = [{}];
    allUsers?.forEach((usr) => {
        unassignedData.push({ value: usr.id, description: usr.userName });
    });

    return (
        <>
            <GroupTreeStructure
                user={props.user}
                groups={props.groups}
                members
                assignMember={(gr) => onOpenAssignUser(gr)}
                deleteMembers={(selectedMembers) =>
                    removeSelected(selectedMembers)
                }
            />
            <AssignUserModal
                data={unassignedData}
                open={assignUserModal}
                handleClose={() => setAssignUserModal(false)}
                confirm={assignUser}
            />
        </>
    );
}
