import React from 'react';
import { useTranslation } from 'react-i18next';

import './nodata.css';

export function NoData() {
    const { t } = useTranslation();
    return (
        <div style={{ textAlign: 'center' }}>
            <div class="top">
                <h3 className="ghosttxt">{t('common_nodata_nodata')}</h3>
            </div>
            <div class="completeghost">
                <div class="ghost-copy">
                    <div class="one"></div>
                    <div class="two"></div>
                    <div class="three"></div>
                    <div class="four"></div>
                </div>
                <div class="ghost">
                    <div class="face">
                        <div class="eye"></div>
                        <div class="eye-right"></div>
                        <div class="mouth"></div>
                    </div>
                </div>
                <div class="shadow"></div>
            </div>
        </div>
    );
}
