import { GraphContainer } from '@components';
import { getGraphColor } from '@utils/helpers/colors.helpers';
import React, { useEffect, useRef, useState } from 'react';

import './graphstyles.css';

export function AquaFilterGraphContainer(props) {
    const [allData, setAllData] = useState([]);

    const linkRef = useRef();

    useEffect(() => {
        setAllData(props.data);
    }, [props.data]);

    const onItemClick = (id, dt = null) => {};

    const onGroupClick = (id) => {};

    const onDownloadCsv = (data) => {
        const csvData = [];
        const csvBlob = new Blob(csvData, { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(csvBlob);
        linkRef.current.href = url;
        linkRef.current.download = 'chart-data.csv';
        linkRef.current.click();
    };

    const addSeriesData = (data, measurement, index) => {
        let serie = {
            name: measurement,
            color: getGraphColor(index, 1),
            datapoints: [],
        };
        for (let dt of data) {
            serie.datapoints.push({ x: dt.timestamp, y: dt.value });
        }
        return serie;
    };

    const groupBy = (items, key) =>
        items?.reduce(
            (result, item) => ({
                ...result,
                [item[key]]: [...(result[item[key]] || []), item],
            }),
            {}
        );

    const series = [];
    const dataByMeasurements = groupBy(allData, 'measurement');
    let index = 0;
    for (let key in dataByMeasurements) {
        series.push(addSeriesData(dataByMeasurements[key], key, index++));
    }

    return (
        <GraphContainer
            graph={props.graph}
            user={props.user}
            series={series}
            onDownloadCsv={onDownloadCsv}
            groups={props.groups}
            protected={false}
            onGroupClick={onGroupClick}
            onItemClick={onItemClick}
        />
    );
}
