import {
    MeasurementPointApi,
    TemperatureAlarmApi,
} from '@aurum/nucleus-client-api';
import { CustomBreadcrumbs, FilesTable, MoreSettingsMenu } from '@components';
import { MenuItem } from '@material-ui/core';
import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
    getFullGroups,
    redirectToSiteTypeApp,
    getProperSiteSettingsPage,
} from '@utils/helpers/app.helpers';
import {
    dateToDateString,
    dateToString,
} from '@utils/helpers/timestamp.helpers';
import {
    createAlarms,
    deleteAlarms,
    editAlarm,
} from '@views/sites/alarms/AlarmCommonFunctions';
import CreateAlarmModal from '@views/sites/alarms/create-alarm/CreateAlarmModal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function AlarmsSitePage(props) {
    const [mps, setMps] = useState([]);
    const [alarms, setAlarms] = useState([]);
    const [selectedAlarms, setSelectedAlarms] = useState([]);
    const [numSelected, setNumSelected] = useState(0);
    const [createAlarmModal, setCreateAlarmModal] = useState(false);
    const [editAlarmModal, setEditAlarmModal] = useState(false);
    const { groupId, id } = useParams();

    const { t } = useTranslation();

    useEffect(() => {
        new TemperatureAlarmApi().temperatureAlarmGetSiteAlarms(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setAlarms(data);
                }
            }
        );
        new MeasurementPointApi().measurementPointGetMeasurementPoints(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setMps(data);
                }
            }
        );
    }, []);

    useEffect(() => {
        redirectToSiteTypeApp(
            props.site,
            `groups/${groupId}/sites/${id}/alarms`,
            `groups/${groupId}/sites/filter/${id}/parameters`
        );
    }, [props]);

    const createAlarm = (alarm) => {
        if (isNullOrUndefined(alarm) || isNullOrUndefined(alarm.mpoint)) {
            return;
        }
        createAlarms(alarm, [props.user?.id], alarm.mpoint, id, () => {
            window.location.reload();
        });
    };

    const edit = (alarm) => {
        const allSelectdAlarms = [
            ...selectedAlarms?.map((sa) => getSameAlarms(sa)),
        ].flat();
        editAlarm(allSelectdAlarms, alarm, id, () => {
            window.location.href = getProperRoute(
                `groups/${groupId}/sites/${id}/alarms`
            );
        });
    };

    const removeSelectedAlarms = () => {
        swal({
            title: t('swal_sure'),
            text: t('pages_groups_groupprojectspage_info'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult && selectedAlarms?.length > 0) {
                const allSelectdAlarms = [
                    ...selectedAlarms?.map((sa) => getSameAlarms(sa)),
                ].flat();
                deleteAlarms(allSelectdAlarms, id);
                alarmsRemoved(allSelectdAlarms);
            }
        });
    };

    const alarmsRemoved = (removed) => {
        const alarmsNotRemoved = [];
        alarms.forEach((a) => {
            if (isNullOrUndefined(removed?.filter((al) => al.id === a.id)[0])) {
                alarmsNotRemoved.push(a);
            }
        });
        setAlarms(alarmsNotRemoved);
    };

    const getAlarmUnit = (alarm) => {
        if (alarm?.type === 'temperature') {
            return 'ºC';
        } else if (alarm?.type === 'chlorine' || alarm?.type === 'chloride') {
            return 'ppm';
        } else if (alarm?.type === 'battery') {
            return 'mV';
        } else if (alarm?.type === 'flowrate') {
            return 'l/h';
        }
        return '';
    };

    const selectedRow = (selected, type, id) => {
        if (id === -1) {
            setNumSelected(selected ? getDifferentAlarms().length : 0);
        } else {
            setNumSelected(selected ? numSelected + 1 : numSelected - 1);
        }
        if (selected) {
            if (id === -1) {
                setSelectedAlarms([...getDifferentAlarms()]);
            } else {
                const alarm = alarms?.filter((a) => a.id === id)[0];
                if (!isNullOrUndefined(alarm)) {
                    setSelectedAlarms([...selectedAlarms, alarm]);
                }
            }
        } else {
            id === -1
                ? setSelectedAlarms([])
                : setSelectedAlarms(selectedAlarms.filter((a) => a.id !== id));
        }
    };

    const getDifferentAlarms = () => {
        const differentAlarms = [];
        alarms?.forEach((alarm) => {
            if (
                !differentAlarms.some(
                    (a) =>
                        a.isActive === alarm.isActive &&
                        a.name === alarm.name &&
                        a.type === alarm.type &&
                        a.lowerLimit === alarm.lowerLimit &&
                        a.upperLimit === alarm.upperLimit &&
                        a.hystLower === alarm.hystLower &&
                        a.hystUpper === alarm.hystUpper &&
                        dateToDateString(a.from) ===
                            dateToDateString(alarm.from) &&
                        dateToDateString(a.to) === dateToDateString(alarm.to)
                )
            ) {
                differentAlarms.push(alarm);
            }
        });
        return differentAlarms;
    };

    const getSameAlarms = (alarm) => {
        if (isNullOrUndefined(alarm)) {
            return [];
        }
        return alarms.filter(
            (a) =>
                a.isActive === alarm.isActive &&
                a.name === alarm.name &&
                a.type === alarm.type &&
                a.lowerLimit === alarm.lowerLimit &&
                a.upperLimit === alarm.upperLimit &&
                a.hystLower === alarm.hystLower &&
                a.hystUpper === alarm.hystUpper &&
                dateToDateString(a.from) === dateToDateString(alarm.from) &&
                dateToDateString(a.to) === dateToDateString(alarm.to)
        );
    };

    const fullGroups = getFullGroups(props.groups, props.site);

    const rows = [];
    getDifferentAlarms()?.forEach((alarm) => {
        rows.push([
            {
                addCheckbox: true,
                id: alarm.id,
                checked: !isNullOrUndefined(
                    selectedAlarms?.filter((s) => s.id === alarm.id)[0]
                ),
                text: alarm.isActive ? t('swal_yes') : t('swal_no'),
                value: alarm.isActive ? t('swal_yes') : t('swal_no'),
            },
            {
                link: getProperRoute(
                    `groups/${groupId}/sites/${id}/alarms/${alarm.id}`
                ),
                text: alarm.name,
                value: alarm.name,
            },
            {
                text: dateToString(alarm.from, props.user?.timeZone),
                value: dateToString(alarm.from, props.user?.timeZone),
            },
            {
                text: dateToString(alarm.to, props.user?.timeZone),
                value: dateToString(alarm.to, props.user?.timeZone),
            },
            {
                text: `${alarm.lowerLimit}${getAlarmUnit(alarm)} + ${
                    alarm.hystLower
                }${getAlarmUnit(alarm)}`,
                value: `${alarm.lowerLimit}${getAlarmUnit(alarm)} + ${
                    alarm.hystLower
                }${getAlarmUnit(alarm)}`,
            },
            {
                text: `${alarm.upperLimit}${getAlarmUnit(alarm)} + ${
                    alarm.hystUpper
                }${getAlarmUnit(alarm)}`,
                value: `${alarm.upperLimit}${getAlarmUnit(alarm)} + ${
                    alarm.hystUpper
                }${getAlarmUnit(alarm)}`,
            },
        ]);
    });

    const mpoints = [];
    mps?.forEach((mp) => {
        mpoints.push({ value: mp.id, description: mp.description });
    });

    return isNullOrUndefined(props.site) ? (
        <></>
    ) : (
        <>
            <CustomBreadcrumbs
                breadcrumbs={[
                    ...fullGroups,
                    {
                        link: getProperRoute(
                            getProperSiteSettingsPage(
                                groupId,
                                id,
                                props.site?.type
                            )
                        ),
                        text: `${props.site.name}`,
                    },
                    {
                        link: getProperRoute('/'),
                        text: t('pages_sitesettings_alarmentrypage_alarms'),
                    },
                ]}
            />
            <hr /> <br />
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}>
                <span style={{ width: 'inherit', justifySelf: 'flex-start' }}>
                    {t('pages_sitesettings_alarmentrypage_alarms')}
                </span>
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'analyst')}>
                    {numSelected === 1 ? (
                        <MenuItem onClick={() => setEditAlarmModal(true)}>
                            {t('pages_sitesettings_alarmentrypage_edit')}
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    {numSelected > 0 ? (
                        <>
                            <MenuItem onClick={() => removeSelectedAlarms()}>
                                {t('pages_sitesettings_alarmentrypage_remove')}{' '}
                                {numSelected}{' '}
                                {t(
                                    'pages_sitesettings_alarmentrypage_alarmslower'
                                )}
                            </MenuItem>
                            <hr />
                        </>
                    ) : (
                        <></>
                    )}
                    <MenuItem onClick={() => setCreateAlarmModal(true)}>
                        {t('pages_sitesettings_alarmentrypage_new')}
                    </MenuItem>
                </MoreSettingsMenu>
            </div>
            <FilesTable
                responsive
                onCheckbox={(selected, type, id) =>
                    selectedRow(selected, type, id)
                }
                columns={[
                    t('pages_sitesettings_alarmentrypage_activetxt'),
                    t('common_filestable_namecol'),
                    t('pages_sitesettings_alarmentrypage_fromtxt'),
                    t('pages_sitesettings_alarmentrypage_totxt'),
                    t('pages_sitesettings_alarmentrypage_lower'),
                    t('pages_sitesettings_alarmentrypage_upper'),
                ]}
                rows={rows}
            />
            <CreateAlarmModal
                open={createAlarmModal}
                mpoints={mpoints}
                handleClose={() => setCreateAlarmModal(false)}
                confirm={(alarm) => createAlarm(alarm)}
            />
            <CreateAlarmModal
                edit
                alarm={selectedAlarms[0]}
                open={editAlarmModal}
                mpoints={mpoints}
                alarmMps={getSameAlarms(selectedAlarms[0])?.map(
                    (a) => a.measurementPoint
                )}
                handleClose={() => setEditAlarmModal(false)}
                confirm={(alarm) => edit(alarm)}
            />
        </>
    );
}
