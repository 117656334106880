import {
    BarGraph,
    BoxPlot,
    CustomTable,
    GraphContainerContent,
    GraphContainerHeader,
    LineGraph,
    MetricPlot,
    PieGraph,
} from '@components';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import React, { memo, useEffect, useRef, useState } from 'react';

import './graphstyles.css';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        minHeight: 275,
        borderRadius: '15px',
        overflow: 'visible',
    },
    title: {
        fontSize: 14,
        textAlign: 'center',
    },
});

const headers = [
    { name: 'name', label: 'Name' },
    { name: 'value', label: 'Value' },
];

const GraphContainer = (props) => {
    const [showLegend, setShowLegend] = useState(false);

    const canvasRef = useRef();
    const linkRef = useRef();
    const graphRef = useRef();
    const targetRef = useRef();
    const classes = useStyles();

    useEffect(() => {}, [props.data]);

    const onGraphRef = (gRef) => {
        graphRef.current = gRef;
    };

    const onDownloadPng = (type) => {
        if (!isNullOrUndefined(graphRef?.current)) {
            const ctx = canvasRef.current.getContext('2d');
            // get the xml from the graph
            const xml = unescape(
                encodeURIComponent(
                    new XMLSerializer().serializeToString(
                        graphRef.current.firstChild
                    )
                )
            );
            // set the canvas dimensions to the dimensions of the graph
            const dimensions = graphRef.current.getBoundingClientRect();
            canvasRef.current.height = dimensions.height;
            canvasRef.current.width = dimensions.width;
            // convert the xml graph to a blob and export it as png/svg
            const image = new Image();
            image.src = `data:image/svg+xml;base64,${btoa(xml)}`;
            image.onload = () => {
                ctx.drawImage(image, 0, 0, dimensions.width, dimensions.height);
                linkRef.current.href =
                    type === 'png' ? canvasRef.current.toDataURL() : image.src;
                linkRef.current.download =
                    type === 'png' ? 'chart.png' : 'chart.svg';
                linkRef.current.click();
            };
        }
    };

    let graph = <></>;
    switch (props.graph?.type) {
        case 0:
            graph = (
                <LineGraph
                    user={props.user}
                    showLegend={showLegend}
                    customStyle={props.graph?.configOptions}
                    measurement={props.graph?.measurement}
                    min={0}
                    series={props.series}
                    onGraphRef={onGraphRef}
                    width={targetRef?.current?.offsetWidth ?? 1414}
                    height={(targetRef?.current?.offsetHeight ?? 670) - 200}
                />
            );
            break;
        case 3:
            graph = (
                <PieGraph
                    showLegend={showLegend}
                    customStyle={props.graph?.configOptions}
                    measurement={props.graph?.measurement}
                    series={props.series}
                    width={targetRef?.current?.offsetWidth ?? 1440 * 0.95}
                    height={(targetRef?.current?.offsetHeight ?? 670) - 200}
                />
            );
            break;
        case 1:
            graph = (
                <BarGraph
                    showLegend={showLegend}
                    customStyle={props.graph?.configOptions}
                    measurement={props.graph?.measurement}
                    series={props.series}
                    width={targetRef?.current?.offsetWidth ?? 1440 * 0.95}
                    height={(targetRef?.current?.offsetHeight ?? 670) - 200}
                />
            );
            break;
        case 2:
            graph = (
                <BarGraph
                    showLegend={showLegend}
                    customStyle={props.graph?.configOptions}
                    measurement={props.graph?.measurement}
                    series={props.series}
                    horizontal={true}
                    width={targetRef?.current?.offsetWidth ?? 1440}
                    height={(targetRef?.current?.offsetHeight ?? 670) - 200}
                />
            );
            break;
        case 5:
            graph = <MetricPlot data={props.series} />;
            break;
        case 4:
            graph = (
                <BoxPlot
                    showLegend={showLegend}
                    customStyle={props.graph?.configOptions}
                    measurement={props.graph?.measurement}
                    series={props.series}
                    width={targetRef?.current?.offsetWidth ?? 1440}
                    height={(targetRef?.current?.offsetHeight ?? 670) - 200}
                />
            );
            break;
        case 6:
            graph = (
                <CustomTable
                    headers={headers}
                    data={props.series}
                    print={false}
                    download={false}
                    filter={false}
                    search={false}
                />
            );
            break;
    }

    return (
        <>
            <Card
                ref={targetRef}
                className={classes.root}
                style={{
                    width: 'inherit',
                    minWidth: '275px',
                    height: 'inherit',
                    background: 'rgba(230, 230, 230, 0.3)',
                }}
                variant="outlined">
                <GraphContainerHeader
                    onDownloadPng={(type) => onDownloadPng(type)}
                    onTriggerShowLegend={() => setShowLegend(!showLegend)}
                    onDownloadCsv={(data) =>
                        props.onDownloadCsv && props.onDownloadCsv(data)
                    }
                    data={props.series}
                    graph={props.graph}
                    protected={props.protected}
                    view={props.view}
                    user={props.user}
                />
                <CardContent>
                    <GraphContainerContent
                        width={targetRef?.current?.offsetWidth ?? 1414}
                        data={props.series}
                        graph={props.graph}
                        groups={props.groups}
                        protected={props.protected}
                        onGroupClick={(id) =>
                            props.onGroupClick && onGroupClick(id)
                        }
                        onItemClick={(id) =>
                            props.onItemClick && onItemClick(id)
                        }
                    />
                    {graph}
                </CardContent>
            </Card>
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <a ref={linkRef} href="chart.png" style={{ display: 'none' }}>
                Download
            </a>
        </>
    );
};

export default memo(GraphContainer);
