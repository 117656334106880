import { DashboardApi } from '@aurum/nucleus-client-api';
import { CustomBreadcrumbs, FilesTable, MoreSettingsMenu } from '@components';
import { MenuItem } from '@material-ui/core';
import {
    getFullGroups,
    getProperRoute,
    isNullOrUndefined,
    redirectToSiteTypeApp,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import CreateDashboardModal from '@views/sites/dashboards/create-dashboard/CreateDashboardModal';
import DashboardTableEntry from '@views/sites/dashboards/dashboard-entry/DashboardTableEntry';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function DashboardsPage(props) {
    const [dashboards, setDashboards] = useState(null);
    const [createDbModal, setCreateDbModal] = useState(false);
    const { groupId, id } = useParams();

    const { t } = useTranslation();

    useEffect(() => {
        new DashboardApi().dashboardGetDashboards(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setDashboards(response.body);
                }
            }
        );
    }, []);

    useEffect(() => {
        redirectToSiteTypeApp(
            props.site,
            `groups/${groupId}/sites/${id}/dashboards`
        );
    }, [props]);

    const createDashboard = (name) => {
        if (!isNullOrUndefined(name) && name.trim() !== '') {
            new DashboardApi().dashboardCreateDashboard(
                parseInt(id, 10),
                { name: name },
                function (_, data, response) {
                    if (response.ok) {
                        setDashboards([...dashboards, data]);
                        setCreateDbModal(false);
                    }
                }
            );
        }
    };

    const markDashboardAsDefault = (dbId) => {
        const dboard = dashboards?.filter((d) => d.id === dbId)[0];
        if (isNullOrUndefined(dboard)) {
            return;
        }
        if (isNullOrUndefined(dboard.configOptions)) {
            dboard.configOptions = {};
        }
        dboard.configOptions.isDefault = true;
        new DashboardApi().dashboardUpdateDashboard(
            parseInt(id, 10),
            dbId,
            { configOptions: dboard.configOptions },
            function (_, data, response) {
                if (response.ok) {
                    const otherDashboards = dashboards.slice();
                    otherDashboards.forEach((db) => {
                        if (
                            !isNullOrUndefined(db.configOptions?.isDefault) &&
                            db.id !== dbId
                        ) {
                            db.configOptions.isDefault = false;
                            new DashboardApi().dashboardUpdateDashboard(
                                parseInt(id, 10),
                                db.id,
                                { configOptions: db.configOptions },
                                function (_, data, response) {}
                            );
                        }
                    });
                    setDashboards(otherDashboards);
                }
            }
        );
    };

    const data = [];
    dashboards?.forEach((dashboard) => {
        data.push([
            {
                link: null,
                text: dashboard.name,
                value: (
                    <DashboardTableEntry
                        link={getProperRoute(
                            props.site?.type === 'filter'
                                ? `groups/${groupId}/sites/filter/${id}/dashboards/${dashboard.id}`
                                : `groups/${groupId}/sites/${id}/dashboards/${dashboard.id}`
                        )}
                        onDefaultClick={markDashboardAsDefault}
                        default={
                            isNullOrUndefined(
                                dashboard.configOptions?.isDefault
                            )
                                ? false
                                : dashboard.configOptions.isDefault
                        }
                        id={dashboard.id}
                        title={dashboard.name}
                        description={''}
                    />
                ),
            },
        ]);
    });

    const fullGroups = getFullGroups(props.groups, props.site);

    return (
        <>
            <CustomBreadcrumbs
                breadcrumbs={[
                    ...fullGroups,
                    {
                        link: getProperRoute(
                            props.site?.type === 'filter'
                                ? `groups/${groupId}/sites/filter/${id}/parameters`
                                : `groups/${groupId}/sites/${id}/settings`
                        ),
                        text: props.site?.name,
                    },
                    {
                        link: getProperRoute('/'),
                        text: t('pages_dashboard_dashboardpage_dashboards'),
                    },
                ]}
            />
            <hr /> <br />
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <span style={{ width: 'inherit', justifySelf: 'flex-start' }}>
                    {t('pages_dashboard_dashboardpage_dashboards')}
                </span>
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'analyst')}>
                    {userIsAtLeast(props.user, 'analyst') ? (
                        <MenuItem onClick={() => setCreateDbModal(true)}>
                            {t('pages_dashboard_dashboardspage_new')}
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <br />
            <br />
            <FilesTable
                columns={[t('common_filestable_namecol')]}
                rows={data}
            />
            <CreateDashboardModal
                open={createDbModal}
                handleClose={() => setCreateDbModal(false)}
                confirm={createDashboard}
            />
        </>
    );
}
