import { GatewayApi, TemperatureApi } from '@aurum/nucleus-client-api';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import AssignGatewayModal from '@views/groups/devices/assign-gateway/AssignGatewayModal';
import AssignSensorModal from '@views/groups/devices/assign-sensor/AssignSensorModal';
import MoveDevicesModal from '@views/groups/devices/move-devices/MoveDevicesModal';
import GroupTreeStructure from '@views/groups/group-page/GroupTreeStructure';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function GroupDevicesPage(props) {
    const [moveDevicesModal, setMoveDevicesModal] = useState(false);
    const [assignGatewayModal, setAssignGatewayModal] = useState(false);
    const [assignSensorModal, setAssignSensorModal] = useState(false);
    const [gateways, setGateways] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [selGws, setSelGws] = useState([]);
    const [selSensors, setSelSensors] = useState([]);

    const { t } = useTranslation();

    const groupId = parseInt(window.location.href.split('/')[4], 10);
    const group = props.groups?.filter((g) => g.id === groupId)[0];

    useEffect(() => {
        new GatewayApi().gatewayGetAllGateways(function (_, data, response) {
            if (response.ok) {
                setGateways(data);
            }
        });
        new TemperatureApi().temperatureGetSensors(function (
            _,
            data,
            response
        ) {
            if (response.ok) {
                setSensors(data);
            }
        });
    }, []);

    const confirmGatewayAssignment = (uuid) => {
        if (
            !isNullOrUndefined(uuid) &&
            uuid.trim() !== '' &&
            !isNullOrUndefined(group)
        ) {
            new GatewayApi().gatewayUpdateGateway(
                uuid,
                { uuid: uuid, groupId: groupId },
                function (_, data, response) {
                    window.location.href = window.location.href;
                }
            );
        }
    };

    const confirmSensorAssignment = (uuid) => {
        if (
            !isNullOrUndefined(uuid) &&
            uuid.trim() !== '' &&
            !isNullOrUndefined(group)
        ) {
            new TemperatureApi().temperatureUpdateSensor(
                uuid,
                { uuid: uuid, groupId: groupId },
                function (_, data, response) {
                    window.location.href = window.location.href;
                }
            );
        }
    };

    const confirmRemoveDevices = (selectedGws, selectedSensors) => {
        swal({
            title: t('swal_sure'),
            text: 'The selected devices will be removed from this group',
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult) {
                let counter = 0;
                selectedGws?.forEach((gw) => {
                    gw.groupId = null;
                    new GatewayApi().gatewayUpdateGateway(
                        gw.uuid,
                        gw,
                        function (_, data, response) {
                            counter += 1;
                            if (
                                counter ===
                                (selectedGws?.length ?? 0) +
                                    (selectedSensors?.length ?? 0)
                            ) {
                                onDevicesRemoved();
                            }
                        }
                    );
                });
                selectedSensors?.forEach((sensor) => {
                    sensor.groupId = null;
                    new TemperatureApi().temperatureUpdateSensor(
                        sensor.uuid,
                        sensor,
                        function (_, data, response) {
                            counter += 1;
                            if (
                                counter ===
                                (selectedGws?.length ?? 0) +
                                    (selectedSensors?.length ?? 0)
                            ) {
                                onDevicesRemoved();
                            }
                        }
                    );
                });
            }
        });
    };

    const confirmMoveDevices = (newId) => {
        let totalMoved = 0;
        selGws?.forEach((gw) => {
            gw.groupId = newId;
            new GatewayApi().gatewayUpdateGateway(
                gw.uuid,
                gw,
                function (_, data, response) {
                    totalMoved += 1;
                    if (
                        totalMoved ===
                        (selGws?.length ?? 0) + (selSensors?.length ?? 0)
                    ) {
                        onDevicesMoved();
                    }
                }
            );
        });
        selSensors?.forEach((sensor) => {
            sensor.groupId = newId;
            new TemperatureApi().temperatureUpdateSensor(
                sensor.uuid,
                sensor,
                function (_, data, response) {
                    totalMoved += 1;
                    if (
                        totalMoved ===
                        (selGws?.length ?? 0) + (selSensors?.length ?? 0)
                    ) {
                        onDevicesMoved();
                    }
                }
            );
        });
    };

    const onMoveDevices = (gws, sensors) => {
        setMoveDevicesModal(true);
        setSelGws(gws);
        setSelSensors(sensors);
    };

    const onDevicesRemoved = () => {
        window.location.href = window.location.href;
    };

    const onDevicesMoved = () => {
        window.location.href = window.location.href;
    };

    const recursive = (levelGroups, total, items) => {
        if (isNullOrUndefined(levelGroups) || levelGroups?.length === 0) {
            return '';
        }
        let newTotal = total;
        levelGroups?.forEach((lg) => {
            newTotal = total + ' / ' + lg.name;
            items.push({ value: lg.id, description: newTotal });
            recursive(
                props.groups.filter((g) => g.parent?.id === lg.id),
                newTotal,
                items
            );
        });
    };

    const itemsToBeMoved = [];
    let levelGroups = props.groups?.filter((g) =>
        isNullOrUndefined(g.parent?.id)
    );
    let total = '';
    recursive(levelGroups, total, itemsToBeMoved);

    const gwsData = [];
    gateways
        ?.filter((gw) => isNullOrUndefined(gw.groupId))
        ?.forEach((gw) => {
            gwsData.push({ value: gw.uuid, description: gw.name });
        });
    const sensorsData = [];
    sensors
        ?.filter((sensor) => isNullOrUndefined(sensor.groupId))
        ?.forEach((sensor) => {
            sensorsData.push({ value: sensor.uuid, description: sensor.name });
        });

    return (
        <>
            <GroupTreeStructure
                user={props.user}
                groups={props.groups}
                devices
                assignGateway={() => setAssignGatewayModal(true)}
                assignSensor={() => setAssignSensorModal(true)}
                removeDevices={(selectedGws, selectedSensors) =>
                    confirmRemoveDevices(selectedGws, selectedSensors)
                }
                moveDevices={(gws, sensors) => onMoveDevices(gws, sensors)}
            />
            <MoveDevicesModal
                data={itemsToBeMoved}
                open={moveDevicesModal}
                handleClose={() => setMoveDevicesModal(false)}
                confirm={confirmMoveDevices}
            />
            <AssignSensorModal
                data={sensorsData}
                open={assignSensorModal}
                handleClose={() => setAssignSensorModal(false)}
                confirm={confirmSensorAssignment}
            />
            <AssignGatewayModal
                data={gwsData}
                open={assignGatewayModal}
                handleClose={() => setAssignGatewayModal(false)}
                confirm={confirmGatewayAssignment}
            />
        </>
    );
}
