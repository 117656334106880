import {
    MeasurementPointApi,
    TemperatureAlarmApi,
} from '@aurum/nucleus-client-api';
import { CustomBreadcrumbs, FilesTable, MoreSettingsMenu } from '@components';
import { MenuItem } from '@material-ui/core';
import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
    getFullGroups,
    getProperSiteSettingsPage,
    redirectToSiteTypeApp,
} from '@utils/helpers/app.helpers';
import { capitalize } from '@utils/helpers/text.helpers';
import {
    dateToDateString,
    dateToString,
} from '@utils/helpers/timestamp.helpers';
import AddAlarmWatcherModal from '@views/sites/alarms/add-alarm-watcher/AddAlarmWatcherModal';
import {
    deleteAlarms,
    editAlarm,
} from '@views/sites/alarms/AlarmCommonFunctions';
import CreateAlarmModal from '@views/sites/alarms/create-alarm/CreateAlarmModal';
import MemberEntryTable from '@views/sites/settings/members/member-entry/MemberEntryTable';
import SiteTitle from '@views/sites/site-entry/SiteTitle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function AlarmEntryPage(props) {
    const [mps, setMps] = useState([]);
    const [alarms, setAlarms] = useState([]);
    const [editAlarmModal, setEditAlarmModal] = useState(false);
    const [addWatcherModal, setAddWatcherModal] = useState(false);
    const { groupId, id, alarmId } = useParams();

    const { t } = useTranslation();

    useEffect(() => {
        new TemperatureAlarmApi().temperatureAlarmGetSiteAlarms(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    const allAlarmsThisId = getAlarms(data);
                    if (isNullOrUndefined(allAlarmsThisId)) {
                        window.location.href = getProperRoute(
                            `groups/${groupId}/sites/${id}/alarms`
                        );
                    } else {
                        setAlarms(allAlarmsThisId);
                    }
                } else {
                    window.location.href = getProperRoute(
                        `groups/${groupId}/sites/${id}/alarms`
                    );
                }
            }
        );
        new MeasurementPointApi().measurementPointGetMeasurementPoints(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setMps(data);
                }
            }
        );
    }, []);

    useEffect(() => {
        redirectToSiteTypeApp(
            props.site,
            `groups/${groupId}/sites/${id}/alarms/${alarmId}`,
            `groups/${groupId}/sites/filter/${id}/parameters`
        );
    }, [props]);

    const getAlarms = (allAlarms) => {
        let alarm = allAlarms?.filter((a) => a.id === parseInt(alarmId, 10));
        if (isNullOrUndefined(alarm) || alarm.length === 0) {
            return undefined;
        }
        alarm = alarm[0];
        return allAlarms.filter(
            (a) =>
                a.isActive === alarm.isActive &&
                a.name === alarm.name &&
                a.type === alarm.type &&
                a.lowerLimit === alarm.lowerLimit &&
                a.upperLimit === alarm.upperLimit &&
                a.hystLower === alarm.hystLower &&
                a.hystUpper === alarm.hystUpper &&
                dateToDateString(a.from) === dateToDateString(alarm.from) &&
                dateToDateString(a.to) === dateToDateString(alarm.to)
        );
    };

    const edit = (alarm) => {
        editAlarm(alarms, alarm, id, () => {
            window.location.href = getProperRoute(
                `groups/${groupId}/sites/${id}/alarms`
            );
        });
    };

    const removeAlarm = () => {
        swal({
            title: t('swal_sure'),
            text: t('swal_cannot_recover_resource'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult && !isNullOrUndefined(alarm)) {
                deleteAlarms(alarms, id, () => {
                    window.location.href = getProperRoute(
                        `groups/${groupId}/sites/${id}/alarms`
                    );
                });
            }
        });
    };

    const getAlarmUnit = () => {
        const alarm = alarms?.length === 0 ? undefined : alarms[0];
        if (alarm?.type === 'temperature') {
            return 'ºC';
        } else if (alarm?.type === 'chlorine' || alarm?.type === 'chloride') {
            return 'ppm';
        } else if (alarm?.type === 'battery') {
            return 'mV';
        } else if (alarm?.type === 'flowrate') {
            return 'l/h';
        }
        return '';
    };

    const removeMember = (memberId) => {
        const alarm = alarms?.length === 0 ? undefined : alarms[0];
        if (isNullOrUndefined(alarm) || isNullOrUndefined(alarm.users)) {
            return;
        }
        if (alarm.users.length === 1) {
            swal({
                text: 'Cannot remove the only watcher of the alarm',
                icon: 'error',
            });
        } else {
            alarm.users = alarm.users.filter((u) => u.id !== memberId);
            edit(alarm);
        }
    };

    const addMember = (memberId) => {
        const alarm = alarms?.length === 0 ? undefined : alarms[0];
        if (isNullOrUndefined(alarm) || isNullOrUndefined(alarm.users)) {
            return;
        }
        if (!alarm.users.map((u) => u.id).includes(memberId)) {
            alarm.users.push({ id: memberId });
            edit(alarm);
        }
    };

    const fullGroups = getFullGroups(props.groups, props.site);

    const attachedMps = alarms
        ?.map((a) => a.measurementPoint.description)
        .join(', ');
    const alarm = alarms?.length === 0 ? undefined : alarms[0];
    const rows = [
        [
            {
                text: alarm?.isActive ? t('swal_yes') : t('swal_no'),
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_sitesettings_alarmentrypage_active')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {alarm?.isActive ? t('swal_yes') : t('swal_no')}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                text: alarm?.name ?? '-',
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_devices_devicepage_name')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {alarm?.name ?? '-'}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                text: capitalize(alarm?.type) ?? '-',
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_devices_devicepage_type')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {capitalize(alarm?.type) ?? '-'}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                text: alarm?.measurementPoint ?? '-',
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_devices_devicepage_mp')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {attachedMps ?? '-'}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                text: alarm?.lowerLimit ?? '-' + getAlarmUnit(),
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_sitesettings_alarmentrypage_lower')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {alarm?.lowerLimit ?? '-' + getAlarmUnit()}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                text: alarm?.upperLimit ?? '-' + getAlarmUnit(),
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_sitesettings_alarmentrypage_upper')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {alarm?.upperLimit ?? '-' + getAlarmUnit()}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                text: alarm?.hystLower ?? '-' + getAlarmUnit(),
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_sitesettings_alarmentrypage_hlow')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {alarm?.hystLower ?? '-' + getAlarmUnit()}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                text: alarm?.hystUpper ?? '-' + getAlarmUnit(),
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_sitesettings_alarmentrypage_hupp')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {alarm?.hystUpper ?? '-' + getAlarmUnit()}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                text: isNullOrUndefined(alarm?.from)
                    ? '-'
                    : dateToString(alarm.from, props.user?.timeZone),
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_sitesettings_alarmentrypage_from')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {isNullOrUndefined(alarm?.from)
                                ? '-'
                                : dateToString(
                                      alarm.from,
                                      props.user?.timeZone
                                  )}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                text: isNullOrUndefined(alarm?.to)
                    ? '-'
                    : dateToString(alarm?.to, props.user?.timeZone),
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_sitesettings_alarmentrypage_to')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {isNullOrUndefined(alarm?.to)
                                ? '-'
                                : dateToString(alarm?.to, props.user?.timeZone)}
                        </p>
                    </div>
                ),
            },
        ],
    ];

    const memberRows = [];
    alarm?.users?.forEach((usr) => {
        memberRows.push([
            {
                flex: true,
                text: usr.email,
                value: (
                    <MemberEntryTable
                        user={usr}
                        remove
                        onRemove={(uid) => removeMember(uid)}
                    />
                ),
            },
        ]);
    });

    const mpoints = [];
    mps?.forEach((mp) => {
        mpoints.push({ value: mp.id, description: mp.description });
    });

    const watchersData = [];
    // the watchers that can be added are those that are not part of the alarm
    props.site?.users
        ?.filter((u) => !alarm?.users?.map((us) => us.id)?.includes(u.id))
        ?.forEach((us) => {
            watchersData.push({ value: us.id, description: us.userName });
        });

    return isNullOrUndefined(props.site) ? (
        <></>
    ) : (
        <>
            <CustomBreadcrumbs
                breadcrumbs={[
                    ...fullGroups,
                    {
                        link: getProperRoute(
                            getProperSiteSettingsPage(
                                groupId,
                                id,
                                props.site?.type
                            )
                        ),
                        text: `${props.site.name}`,
                    },
                    {
                        link: getProperRoute('/'),
                        text: t('pages_sitesettings_alarmentrypage_alarms'),
                    },
                ]}
            />
            <hr /> <br />
            <SiteTitle site={props.site} />
            <hr /> <br />
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: getProperRoute(
                                `groups/${groupId}/sites/${id}/alarms`
                            ),
                            text: t('pages_sitesettings_alarmentrypage_alarms'),
                        },
                        { link: getProperRoute('/'), text: alarm?.name },
                    ]}
                />
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'analyst')}>
                    {userIsAtLeast(props.user, 'analyst') ? (
                        <>
                            <MenuItem onClick={() => setAddWatcherModal(true)}>
                                {t(
                                    'pages_sitesettings_alarmentrypage_addwatcher'
                                )}
                            </MenuItem>
                            <MenuItem onClick={() => setEditAlarmModal(true)}>
                                {t('pages_sitesettings_alarmentrypage_edit')}
                            </MenuItem>
                            <MenuItem onClick={() => removeAlarm()}>
                                {t('pages_sitesettings_alarmentrypage_delete')}
                            </MenuItem>
                        </>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <br />
            <br />
            <FilesTable
                columns={[t('pages_devices_devicepage_properties')]}
                rows={rows}
            />
            <br />
            <FilesTable
                columns={[t('pages_sitesettings_alarmentrypage_watchers')]}
                rows={memberRows}
            />
            <CreateAlarmModal
                edit
                alarm={alarm}
                open={editAlarmModal}
                mpoints={mpoints}
                alarmMps={alarms?.map((a) => a.measurementPoint)}
                handleClose={() => setEditAlarmModal(false)}
                confirm={(alarm) => edit(alarm)}
            />
            <AddAlarmWatcherModal
                open={addWatcherModal}
                handleClose={() => setAddWatcherModal(false)}
                data={watchersData}
                confirm={(usr) => addMember(usr)}
            />
        </>
    );
}
