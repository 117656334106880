import { AccountApi } from '@aurum/nucleus-client-api';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import TimezoneSelect from 'react-timezone-select';
import swal from 'sweetalert';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { alphabetPosition } from '@utils/helpers/text.helpers';
import { getAvatarColor } from '@utils/helpers/colors.helpers';
import { RoundedButton, SingleSelect, TextEdit } from '@components';

import './timezone.css';

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: 'rgb(255, 255, 255)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '4px',
        boxShadow:
            'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(63 63 68 / 15%) 0px 1px 2px 0px',
        maxWidth: '100%',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    avatar: {
        borderRadius: '30%',
        border: 0,
        height: '80px',
        width: '80px',
        margin: 0,
        alignSelf: 'center',
        backgroundColor: (props) =>
            isNullOrUndefined(props.user)
                ? 'transparent'
                : getAvatarColor(alphabetPosition(props.user.email[0])),
        fontSize: '40px',
        lineHeight: '90px',
        margin: '0 auto',
        marginTop: '30px',
        textAlign: 'center',
    },
    hrStyle: {
        position: 'relative',
        left: '0',
        width: '100%',
        height: '1px',
    },
}));

export default function ProfilePage(props) {
    const [trigger, setTrigger] = useState(0);
    const [oldPass, setOldPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [newPassConfirm, setNewPassConfirm] = useState('');
    const styles = useStyles(props);
    const { i18n, t } = useTranslation();

    useEffect(() => {
        if (!isNullOrUndefined(props.user)) {
            let update = false;
            if (isNullOrUndefined(props.user.language)) {
                props.user.language = i18n.language;
                update = true;
            }
            if (isNullOrUndefined(props.user.timeZone)) {
                props.user.timeZone =
                    Intl.DateTimeFormat().resolvedOptions().timeZone;
                update = true;
            }
            if (update) {
                updateProfile();
            }
        }
    }, [props.user]);

    const changeLanguage = (lang) => {
        props.user.language = lang;
    };

    const changeAqVersion = (version) => {
        setCookie('aqv', version);
    };

    const changeTimezone = (tz) => {
        props.user.timeZone = tz.value;
        setTrigger(trigger + 1);
    };

    const changeUserName = (userName) => {
        if (!isNullOrUndefined(userName) && userName.trim() !== '') {
            props.user.userName = userName;
        }
    };

    const changeEmail = (email) => {
        if (!isNullOrUndefined(email) && email.trim() !== '') {
            props.user.email = email;
        }
    };

    const updateOldPass = (txt) => {
        if (!isNullOrUndefined(txt) && txt.trim() !== '') {
            setOldPass(txt);
        }
    };

    const updateNewPass = (txt) => {
        if (!isNullOrUndefined(txt) && txt.trim() !== '') {
            setNewPass(txt);
        }
    };

    const updateNewPassConfirm = (txt) => {
        if (!isNullOrUndefined(txt) && txt.trim() !== '') {
            setNewPassConfirm(txt);
        }
    };

    const updateProfile = () => {
        new AccountApi().accountUpdateUser(
            props.user,
            function (_, data, response) {
                if (response.ok) {
                    window.location.reload();
                }
            }
        );
    };

    const changePassword = () => {
        if (
            !isNullOrUndefined(oldPass) &&
            oldPass.trim() !== '' &&
            !isNullOrUndefined(newPass) &&
            newPass.trim() !== '' &&
            !isNullOrUndefined(newPassConfirm) &&
            newPassConfirm.trim() !== ''
        ) {
            new AccountApi().accountChangePassword(
                props.user.id,
                {
                    oldPassword: oldPass,
                    newPassword: newPass,
                    confirm: newPassConfirm,
                },
                function (_, data, response) {
                    if (response.ok) {
                        window.location.reload();
                    } else {
                        swal({
                            text: t('pages_profile_profilepage_cannotchange'),
                            icon: 'error',
                        });
                    }
                }
            );
        }
    };

    const setCookie = (cookieName, cookieValue) => {
        const expires = 'expires=2147483647';
        document.cookie =
            cookieName + '=' + cookieValue + '; ' + expires + '; path=/';
    };

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const languages = [
        { value: 'en-US', description: 'English' },
        { value: 'de-DE', description: 'Deutsch' },
        { value: 'es-ES', description: 'Spanish' },
        { value: 'nl', description: 'Dutch' },
    ];
    const aquacloudVersions = [
        { value: 'v1', description: 'v1' },
        { value: 'v2', description: 'v2' },
    ];

    const isMatch = document.cookie.match(new RegExp('(^| )aqv=([^;]+)'));
    const aqv = isMatch ? isMatch[2] : 'v2';
    return (
        <div style={{ marginLeft: '5%', width: '85%' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                }}>
                <Container
                    className={styles.container}
                    style={{
                        textAlign: 'center',
                        height: 'inherit',
                        width: isMobile ? '100%' : '25%',
                    }}>
                    <div
                        className={styles.avatar}
                        style={{ marginTop: isMobile ? '0px' : '60px' }}>
                        {props.user?.email[0]?.toUpperCase()}
                    </div>
                    <h5 style={{ marginTop: '20px' }}>
                        {props.user?.userName}
                    </h5>
                    <p>{props.user?.email}</p>
                </Container>
                <Container
                    className={styles.container}
                    style={{
                        height: '100%',
                        marginLeft: isMobile ? '0px' : '20px',
                        marginTop: isMobile ? '20px' : '0px',
                    }}>
                    <p
                        style={{
                            marginTop: '20px',
                            fontWeight: 'bold',
                            fontSize: '16px',
                        }}>
                        {t('pages_profile_profilepage_profile')}
                    </p>
                    <hr className={styles.hrStyle} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            width: '100%',
                        }}>
                        <div style={{ width: isMobile ? '100%' : '50%' }}>
                            <TextEdit
                                placeholder={props.user?.userName}
                                value={props.user?.userName}
                                label={t('pages_profile_profilepage_username')}
                                onChange={(txt) => changeUserName(txt)}
                            />
                        </div>
                        <div
                            style={{
                                width: isMobile ? '100%' : '50%',
                                marginLeft: isMobile ? '0px' : '20px',
                                marginTop: isMobile ? '20px' : '0px',
                            }}>
                            <TextEdit
                                placeholder={props.user?.email}
                                value={props.user?.email}
                                label={t('pages_profile_profilepage_email')}
                                onChange={(txt) => changeEmail(txt)}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            width: '100%',
                            marginTop: '20px',
                        }}>
                        <div style={{ width: isMobile ? '100%' : '50%' }}>
                            <p
                                style={{
                                    color: '#616161',
                                    marginLeft: '0.75rem',
                                    fontSize: '1rem',
                                }}>
                                {t('pages_profile_profilepage_tz')}
                            </p>
                            <TimezoneSelect
                                style={{ marginTop: '-15px' }}
                                value={props.user?.timeZone ?? 'Europe/London'}
                                onChange={(tz) => changeTimezone(tz)}
                            />
                        </div>
                        <div
                            style={{
                                width: isMobile ? '100%' : '50%',
                                marginLeft: isMobile ? '0px' : '20px',
                                marginTop: isMobile ? '20px' : '0px',
                            }}>
                            <SingleSelect
                                width="100%"
                                title={t('pages_profile_profilepage_lang')}
                                value={props.user?.language}
                                data={languages}
                                onChange={(lang) => changeLanguage(lang)}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            width: '100%',
                            marginTop: '20px',
                        }}>
                        <div style={{ width: isMobile ? '100%' : '50%' }}>
                            <SingleSelect
                                width="100%"
                                title="Default AquaCloud version"
                                value={aqv}
                                data={aquacloudVersions}
                                onChange={(version) => changeAqVersion(version)}
                            />
                        </div>
                    </div>
                    <hr className={styles.hrStyle} />
                    <div className="row" style={{ marginTop: '15px' }}>
                        <div
                            className={`col-md-12 d-flex align-items-center ${
                                isMobile
                                    ? 'justify-content-center'
                                    : 'justify-content-end'
                            }`}>
                            <RoundedButton
                                tooltip={t('pages_profile_profilepage_save')}
                                width="200px"
                                color="primary"
                                text={t(
                                    'pages_profile_profilepage_savechanges'
                                )}
                                onClick={() => updateProfile()}
                            />
                        </div>
                    </div>
                </Container>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                }}>
                <Container
                    className={styles.container}
                    style={{ height: '100%', marginTop: '20px' }}>
                    <p
                        style={{
                            marginTop: '20px',
                            fontWeight: 'bold',
                            fontSize: '16px',
                        }}>
                        {t('pages_profile_profilepage_change')}
                    </p>
                    <hr className={styles.hrStyle} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            width: '100%',
                            marginTop: '20px',
                        }}>
                        <div style={{ width: isMobile ? '100%' : '33%' }}>
                            <TextEdit
                                type="password"
                                label={t('pages_profile_profilepage_old')}
                                onChange={(txt) => updateOldPass(txt)}
                            />
                        </div>
                        <div
                            style={{
                                width: isMobile ? '100%' : '33%',
                                marginLeft: isMobile ? '0px' : '20px',
                                marginTop: isMobile ? '20px' : '0px',
                            }}>
                            <TextEdit
                                type="password"
                                label={t('pages_profile_profilepage_new')}
                                onChange={(txt) => updateNewPass(txt)}
                            />
                        </div>
                        <div
                            style={{
                                width: isMobile ? '100%' : '33%',
                                marginLeft: isMobile ? '0px' : '20px',
                                marginTop: isMobile ? '20px' : '0px',
                            }}>
                            <TextEdit
                                type="password"
                                label={t('pages_profile_profilepage_confirm')}
                                onChange={(txt) => updateNewPassConfirm(txt)}
                            />
                        </div>
                    </div>
                    <hr className={styles.hrStyle} />
                    <div className="row" style={{ marginTop: '15px' }}>
                        <div
                            className={`col-md-12 d-flex align-items-center ${
                                isMobile
                                    ? 'justify-content-center'
                                    : 'justify-content-end'
                            }`}>
                            <RoundedButton
                                tooltip="Save profile changes"
                                width="200px"
                                color="primary"
                                text={t(
                                    'pages_profile_profilepage_savechanges'
                                )}
                                onClick={() => changePassword()}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}
