import { makeStyles } from '@material-ui/core';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((props) => ({
    div: {
        position: 'relative',
        display: 'inline-flex',
        verticalAlign: 'middle',
    },
    btn: {
        backgroundColor: 'var(--white,#fff)',
        borderColor: (props) => props.color ?? '#303030',
        borderRadius: '100px',
        borderWidth: '1px',
        borderStyle: 'solid',
        width: '22px',
        height: '22px',
        margin: '0',
        padding: '0',
        position: 'relative',
        verticalAlign: 'middle',
        display: 'inline-flex',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: (props) =>
                props.backgroundColor ?? 'var(--white,#fff)',
            cursor: 'pointer',
        },
    },
    svg: {
        pointerEvents: 'none',
    },
    icon: {
        width: '15px !important',
        position: 'absolute',
        top: '50%',
        left: '50%',
        height: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'block',
        zIndex: 2,
        overflow: 'visible',
        color: (props) => props.color ?? '',
    },
}));

export default function JobStatusIndicator(props) {
    const styles = useStyles(props.statusStyle);
    const { t } = useTranslation();

    let icon = null;
    if (props.status === 'created') {
        icon = <CircleIcon className={styles.icon} />;
    } else if (props.status === 'pending') {
    } else if (props.status === 'running') {
        icon = <DataUsageIcon className={styles.icon} />;
    } else if (props.status === 'failed') {
        icon = <ClearIcon className={styles.icon} />;
    } else if (props.status === 'success') {
        icon = <CheckIcon className={styles.icon} />;
    } else if (props.status === 'canceled') {
        icon = <BlockIcon className={styles.icon} />;
    } else if (props.status === 'skipped') {
        icon = <DoubleArrowIcon className={styles.icon} />;
    } else if (props.status === 'manual') {
        icon = <PlayArrowIcon className={styles.icon} />;
    }
    //icon = <SettingsIcon className={styles.icon} />;

    return (
        <Tooltip placement="top" title={`Status: ${props.status}`}>
            <div className={styles.div}>
                <button className={styles.btn}>
                    <span className={styles.svg}>{icon}</span>
                </button>
            </div>
        </Tooltip>
    );
}
