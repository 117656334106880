import { CustomBreadcrumbs, FilesTable, MoreSettingsMenu } from '@components';
import { MenuItem } from '@material-ui/core';
import { getProperRoute, isNullOrUndefined } from '@utils/helpers/app.helpers';
import GroupTitle from '@views/groups/group-entry/GroupTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function GatewayGroupPage(props) {
    const { groupId, deviceId } = useParams();

    const { t } = useTranslation();

    const group = props.groups?.filter(
        (g) => g.id === parseInt(groupId, 10)
    )[0];
    const gateway = group?.gateways?.filter((g) => g.uuid === deviceId)[0];

    let parentGroup = props.groups?.filter((g) => g.id === group?.id)[0];
    const fullGroups = [];
    while (!isNullOrUndefined(parentGroup)) {
        fullGroups.push({
            link: getProperRoute(`groups/${parentGroup.id}/settings`),
            text: parentGroup.name,
        });
        parentGroup = props.groups?.filter(
            (g) => g.id === parentGroup.parent?.id
        )[0];
    }
    fullGroups.reverse();

    return isNullOrUndefined(gateway) ? (
        <></>
    ) : (
        <>
            <CustomBreadcrumbs
                breadcrumbs={[
                    ...fullGroups,
                    {
                        link: getProperRoute(`groups/${groupId}/settings`),
                        text: t('pages_groups_gatewaygrouppage_settings'),
                    },
                    {
                        link: getProperRoute('/'),
                        text: t('pages_groups_gatewaygrouppage_devices'),
                    },
                ]}
            />
            <hr /> <br />
            <GroupTitle group={group} />
            <hr /> <br />
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: getProperRoute(`groups/${groupId}/devices`),
                            text: t('pages_groups_gatewaygrouppage_gws'),
                        },
                        { link: getProperRoute('/'), text: gateway.name },
                    ]}
                />
                <MoreSettingsMenu>
                    <MenuItem onClick={() => {}}>
                        {t('pages_groups_gatewaygrouppage_remove')}
                    </MenuItem>
                </MoreSettingsMenu>
            </div>
            <br />
            <FilesTable
                columns={[
                    t('pages_groups_creategroupmodal_name'),
                    t('pages_groups_gatewaygrouppage_value'),
                ]}
                rows={[
                    [
                        { link: null, text: 'ID', value: 'ID' },
                        {
                            link: null,
                            text: gateway.description,
                            value: gateway.description,
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_groups_creategroupmodal_name'),
                            value: t('pages_groups_creategroupmodal_name'),
                        },
                        { link: null, text: gateway.name, value: gateway.name },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_groups_gatewaygrouppage_wlan'),
                            value: t('pages_groups_gatewaygrouppage_wlan'),
                        },
                        {
                            link: null,
                            text: gateway.gatewayStatus?.WLAN ?? '',
                            value: gateway.gatewayStatus?.WLAN ?? '',
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_groups_gatewaygrouppage_eth'),
                            value: t('pages_groups_gatewaygrouppage_eth'),
                        },
                        {
                            link: null,
                            text: gateway.gatewayStatus?.ETH ?? '',
                            value: gateway.gatewayStatus?.ETH ?? '',
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_groups_gatewaygrouppage_vpn'),
                            value: t('pages_groups_gatewaygrouppage_vpn'),
                        },
                        {
                            link: null,
                            text:
                                gateway.gatewayStatus?.vpnStatus ??
                                t('pages_devices_devicepage_inactive'),
                            value:
                                gateway.gatewayStatus?.vpnStatus ??
                                t('pages_devices_devicepage_inactive'),
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_groups_gatewaygrouppage_uptime'),
                            value: t('pages_groups_gatewaygrouppage_uptime'),
                        },
                        {
                            link: null,
                            text: gateway.upTime ?? '',
                            value: gateway.upTime ?? '',
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_groups_gatewaygrouppage_cpu'),
                            value: t('pages_groups_gatewaygrouppage_cpu'),
                        },
                        {
                            link: null,
                            text: gateway.gatewayStatus?.cpuUsage ?? '',
                            value: gateway.gatewayStatus?.cpuUsage ?? '',
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_groups_gatewaygrouppage_sw'),
                            value: t('pages_groups_gatewaygrouppage_sw'),
                        },
                        {
                            link: null,
                            text: gateway.gatewayStatus?.swVersion ?? '',
                            value: gateway.gatewayStatus?.swVersion ?? '',
                        },
                    ],
                ]}
            />
        </>
    );
}
