import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getProperRoute } from '@utils/helpers/app.helpers';
import React from 'react';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#000',
        '&:hover': {
            color: '#000',
        },
    },
    subTitle: {
        color: 'gray',
    },
    grow: {
        flexGrow: 1,
        width: '100%',
        margin: 'auto',
    },
}));

export default function GatewayTableEntry(props) {
    const styles = useStyles(props);
    const { groupId, id } = useParams();

    return (
        <>
            <div className="row" style={{ marginTop: '20px' }}>
                <div className="col-md-12 d-flex align-items-center justify-content-start">
                    <a
                        className={styles.title}
                        href={getProperRoute(
                            props.gw?.site?.type === 'filter'
                                ? `groups/${groupId}/sites/filter/${id}/gateways/${props.gw?.uuid}`
                                : `groups/${groupId}/sites/${id}/gateways/${props.gw?.uuid}`
                        )}>
                        {props.gw?.name}
                    </a>
                </div>
            </div>
            <Grid className={styles.grow}>
                <Typography className={styles.subTitle}>
                    {props.gw?.description}
                </Typography>
            </Grid>
        </>
    );
}
