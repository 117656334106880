import { GroupApi } from '@aurum/nucleus-client-api';
import GatewayApi from '@aurum/nucleus-client-api/dist/api/GatewayApi';
import TemperatureApi from '@aurum/nucleus-client-api/dist/api/TemperatureApi';
import { MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { capitalize } from '@utils/helpers/text.helpers';
import { dateToString } from '@utils/helpers/timestamp.helpers';
import { CustomBreadcrumbs, FilesTable, MoreSettingsMenu } from '@components';
import RemoveDeviceModal from '@views/devices/remove-devices/RemoveDeviceModal';
import RenameDeviceModal from '@views/devices/rename-devices/RenameDeviceModal';

export default function DevicePage(props) {
    const [device, setDevice] = useState(null);
    const [groups, setGroups] = useState([]);
    const [renameDeviceOpenModal, setRenameDeviceOpenModal] = useState(false);
    const [removeDeviceOpenModal, setRemoveDeviceOpenModal] = useState(false);
    const { id } = useParams();

    const { t } = useTranslation();

    useEffect(() => {
        if (props.gateway) {
            new GatewayApi().gatewayGetGateway(
                id,
                function (_, data, response) {
                    if (response.ok) {
                        if (isNullOrUndefined(data.uuid)) {
                            window.location.href = getProperRoute(`devices`);
                        } else {
                            setDevice(data);
                        }
                    } else {
                        window.location.href = getProperRoute(`devices`);
                    }
                }
            );
        } else if (props.sensor) {
            new TemperatureApi().temperatureGetSensors(function (
                _,
                data,
                response
            ) {
                if (response.ok) {
                    const sensor =
                        data?.filter((s) => s.uuid === id)[0] ?? null;
                    if (isNullOrUndefined(sensor)) {
                        window.location.href = getProperRoute(`devices`);
                    }
                    setDevice(sensor);
                } else {
                    window.location.href = getProperRoute(`devices`);
                }
            });
        }
        new GroupApi().groupGetGroups(function (_, data, response) {
            if (response.ok) {
                setGroups(data);
            }
        });
    }, []);

    const onRenameDevice = (name) => {
        if (isNullOrUndefined(name) || name?.trim() === '') {
            return;
        }

        if (props.gateway) {
            device.name = name;
            new GatewayApi().gatewayUpdateGateway(
                device.uuid,
                device,
                function (_, data, response) {
                    setRenameDeviceOpenModal(false);
                }
            );
        } else if (props.sensor) {
            device.name = name;
            new TemperatureApi().temperatureUpdateSensor(
                device.uuid,
                device,
                function (_, data, response) {
                    setRenameDeviceOpenModal(false);
                }
            );
        }
    };

    const onRemoveDevice = () => {
        if (props.gateway) {
            new GatewayApi().gatewayRemoveGateway(
                device.uuid,
                function (_, data, response) {
                    window.location.href = getProperRoute(`devices`);
                }
            );
        } else if (props.sensor) {
            new TemperatureApi().temperatureRemoveSensor(
                device.uuid,
                function (_, data, response) {
                    window.location.href = getProperRoute(`devices`);
                }
            );
        }
    };

    let deviceRows = [];
    if (props.gateway) {
        deviceRows = [
            [
                {
                    link: null,
                    text: device?.uuid,
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            UUID: &nbsp;
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: 'revert',
                                }}>
                                {device?.uuid}
                            </p>
                        </div>
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: device?.description,
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            {t('pages_devices_devicepage_acid')}: &nbsp;
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: 'revert',
                                }}>
                                {device?.description}
                            </p>
                        </div>
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: device?.name,
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            {t('pages_devices_devicepage_name')}: &nbsp;
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: 'revert',
                                }}>
                                {device?.name}
                            </p>
                        </div>
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: device?.connected ? t('swal_yes') : t('swal_no'),
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            {t('pages_devices_devicepage_connected')}: &nbsp;
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: 'revert',
                                }}>
                                {device?.connected
                                    ? t('swal_yes')
                                    : t('swal_no')}
                            </p>
                        </div>
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: device?.upTime ?? '-',
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            {t('pages_devices_devicepage_uptime')}: &nbsp;
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: 'revert',
                                }}>
                                {device?.upTime ?? '-'}
                            </p>
                        </div>
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: isNullOrUndefined(device?.lastActiveTime)
                        ? t('pages_devices_devicepage_never')
                        : dateToString(
                              device.lastActiveTime,
                              props.user?.timeZone
                          ),
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            {t('pages_devices_devicepage_lastactive')}: &nbsp;
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: 'revert',
                                }}>
                                {isNullOrUndefined(device?.lastActiveTime)
                                    ? t('pages_devices_devicepage_never')
                                    : dateToString(
                                          device.lastActiveTime,
                                          props.user?.timeZone
                                      )}
                            </p>
                        </div>
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: isNullOrUndefined(device?.groupId)
                        ? '-'
                        : groups?.filter((g) => g.id === device.groupId)[0]
                              ?.name,
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            {t('pages_devices_devicepage_assignedto')}: &nbsp;
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: 'revert',
                                }}>
                                {isNullOrUndefined(device?.groupId) ? (
                                    '-'
                                ) : (
                                    <a
                                        href={getProperRoute(
                                            `groups/${device.groupId}/settings`
                                        )}>
                                        {
                                            groups?.filter(
                                                (g) => g.id === device.groupId
                                            )[0]?.name
                                        }
                                    </a>
                                )}
                            </p>
                        </div>
                    ),
                },
            ],
            [
                {
                    link: null,
                    text:
                        isNullOrUndefined(device?.site) ||
                        device?.site?.id === -1
                            ? '-'
                            : device.site.name,
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            {t('pages_devices_devicepage_project')}: &nbsp;
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: 'revert',
                                }}>
                                {isNullOrUndefined(device?.site) ||
                                device?.site?.id === -1 ? (
                                    '-'
                                ) : (
                                    <a
                                        href={getProperRoute(
                                            `groups/${device.site.groupId}/sites/${device.site.id}/settings`
                                        )}>
                                        {device.site.name}
                                    </a>
                                )}
                            </p>
                        </div>
                    ),
                },
            ],
        ];
    } else if (props.sensor) {
        deviceRows = [
            [
                {
                    link: null,
                    text: device?.uuid,
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            UUID: &nbsp;
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: 'revert',
                                }}>
                                {device?.uuid}
                            </p>
                        </div>
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: device?.description,
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            {t('pages_devices_devicepage_acid')}: &nbsp;
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: 'revert',
                                }}>
                                {device?.description}
                            </p>
                        </div>
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: device?.name,
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            {t('pages_devices_devicepage_name')}: &nbsp;
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: 'revert',
                                }}>
                                {device?.name}
                            </p>
                        </div>
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: device?.type,
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            {t('pages_devices_devicepage_type')}: &nbsp;
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: 'revert',
                                }}>
                                {capitalize(device?.type)}
                            </p>
                        </div>
                    ),
                },
            ],
            [
                {
                    link: null,
                    text:
                        device?.status === 2
                            ? t('pages_devices_devicepage_active')
                            : device?.status === 1
                            ? t('pages_devices_devicepage_inactive')
                            : t('pages_devices_devicepage_unassigned'),
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            {t('pages_devices_devicepage_status')}: &nbsp;
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: 'revert',
                                }}>
                                {device?.status === 2
                                    ? t('pages_devices_devicepage_active')
                                    : device?.status === 1
                                    ? t('pages_devices_devicepage_inactive')
                                    : t('pages_devices_devicepage_unassigned')}
                            </p>
                        </div>
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: isNullOrUndefined(device?.measurementPoint)
                        ? '-'
                        : device.measurementPoint.description,
                    value: (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                            {t('pages_devices_devicepage_mp')}: &nbsp;
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: 'revert',
                                }}>
                                {isNullOrUndefined(device?.measurementPoint)
                                    ? '-'
                                    : device.measurementPoint.description}
                            </p>
                        </div>
                    ),
                },
            ],
        ];
    }

    return (
        <div style={{ marginLeft: '5%', width: '85%' }}>
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: getProperRoute(`devices`),
                            text: t('pages_devices_devicepage_devices'),
                        },
                        { link: getProperRoute('/'), text: device?.name },
                    ]}
                />
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'analyst')}>
                    {userIsAtLeast(props.user, 'analyst') ? (
                        <>
                            <MenuItem
                                onClick={() => setRenameDeviceOpenModal(true)}>
                                {t('pages_devices_devicepage_rename')}
                            </MenuItem>
                            {userIsAtLeast(props.user, 'admin') ? (
                                <MenuItem
                                    onClick={() =>
                                        setRemoveDeviceOpenModal(true)
                                    }>
                                    {t('pages_devices_devicepage_remove')}
                                </MenuItem>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <hr />
            <br />
            <div className="row">
                <div className="col-md-12">
                    <div style={{ width: '100%' }}>
                        <FilesTable
                            columns={[t('pages_devices_devicepage_properties')]}
                            rows={deviceRows}
                        />
                    </div>
                </div>
            </div>
            <RenameDeviceModal
                name={device?.name}
                open={renameDeviceOpenModal}
                handleClose={() => setRenameDeviceOpenModal(false)}
                confirm={(name) => onRenameDevice(name)}
            />
            <RemoveDeviceModal
                device={device}
                open={removeDeviceOpenModal}
                handleClose={() => setRemoveDeviceOpenModal(false)}
                confirm={() => onRemoveDevice()}
            />
        </div>
    );
}
