import GraphApi from '@aurum/nucleus-client-api/dist/api/GraphApi';
import { CircleButton, TemperatureAppGraphContainer } from '@components';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { getProperRoute, isNullOrUndefined } from '@utils/helpers/app.helpers';
import { useWindowSize } from '@utils/hooks/useWindowSize';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function ViewGraph(props) {
    const [graph, setGraph] = useState(null);
    const [width, height] = useWindowSize();
    const { groupId, id, dbId, graphId } = useParams();

    const { t } = useTranslation();

    useEffect(() => {
        new GraphApi().graphGetGraph(
            parseInt(id, 10),
            parseInt(graphId, 10),
            function (_, data, response) {
                if (response.ok) {
                    setGraph(response.body);
                }
            }
        );
    }, []);

    return isNullOrUndefined(graph) ? (
        <> </>
    ) : (
        <>
            <div className="row" style={{ marginBottom: '20px' }}>
                <div className="col-md-1">
                    <CircleButton
                        onClick={() =>
                            (window.location.href = getProperRoute(
                                `groups/${groupId}/sites/${id}/dashboards/${dbId}`
                            ))
                        }
                        tooltip={t('pages_viewgraph_viewgraph_back')}
                        icon={<ArrowBack />}
                    />
                </div>
            </div>
            <TemperatureAppGraphContainer
                user={props.user}
                view
                graph={graph}
                height={height * 0.7 + 'px'}
                width={width * 0.75 + 'px'}
                groups={props.groups}
                mps={props.mps}
            />
        </>
    );
}
