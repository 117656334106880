import { customTheme } from '@/App';
import i18n from '@/i18n';
import { AccountApi, GroupApi, SiteApi } from '@aurum/nucleus-client-api';
import {
    Navbar,
    SidebarAdmin,
    SidebarAquaFilterSide,
    SidebarGroup,
    SidebarSite,
} from '@components';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Layout, {
    getContent,
    getHeader,
    getSidebarTrigger,
    Root,
} from '@mui-treasury/layout';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import i18next from 'i18next';
import React, { cloneElement, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Header = getHeader(styled);
const SidebarTrigger = getSidebarTrigger(styled);
const Content = getContent(styled);
const scheme = Layout();

scheme.configureHeader((builder) => {
    builder
        .registerConfig('xs', {
            position: 'fixed',
            initialHeight: 56,
        })
        .registerConfig('md', {
            position: 'fixed',
            clipped: true,
            initialHeight: 64,
        });
});

scheme.configureEdgeSidebar((builder) => {
    builder
        .create('primarySidebar', {
            anchor: 'left',
        })
        .registerTemporaryConfig('xs', {
            width: 256,
            collapsible: false,
        })
        .registerPermanentConfig('md', {
            width: 256,
            collapsible: false,
        });
});

scheme.enableAutoCollapse('primarySidebar', 'md');

const useStyles = makeStyles(() => ({
    header: {
        background: customTheme.palette.primary.main,
        zIndex: '999999 !important',
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
            fontSize: 18,
            color: '#888',
            paddingLeft: 0,
            marginRight: 4,
        },
    },
    content: {
        paddingLeft: '0px',
        paddingRight: '0px',
        width: (props) => (props.active ? '80%' : '100%'),
    },
    icon: {
        marginRight: '5px',
        width: 22,
        height: 22,
        alignSelf: 'center',
    },
}));

export function AppLayout(props) {
    const [site, setSite] = useState(null);
    const [groups, setGroups] = useState(null);
    const [user, setUser] = useState(null);

    const styles = useStyles(props);
    const params = useParams();
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

    useEffect(() => {
        const _ = undefined;
        if (props.site) {
            new SiteApi().siteGetSite(
                parseInt(params.groupId, 10),
                parseInt(params.id, 10),
                function (_, data, response) {
                    if (response.ok) {
                        setSite(data);
                    }
                }
            );
        }
        new GroupApi().groupGetGroups(function (_, data, response) {
            if (response.ok) {
                setGroups(data);
            }
        });
        new AccountApi().accountGetLoggedUser(function (_, data, response) {
            if (response.ok) {
                const curLang =
                    i18next.language || window.localStorage.i18nextLng;
                if (
                    !isNullOrUndefined(
                        data.language && curLang !== data.language
                    )
                ) {
                    i18n.changeLanguage(data.language);
                }
                setUser(data);
            }
        });
    }, []);

    if (!props.active) {
        scheme.configureEdgeSidebar((builder) => {
            builder.hide('primarySidebar', ['xl', 'lg', 'md', 'sm', 'xs']);
        });
    }

    let children = props.children;
    if (Array.isArray(children)) {
        children = props.children.filter((c) => typeof c !== 'string')[0];
    }

    let sideBar = <></>;
    if (props.site && props.filter) {
        sideBar = <SidebarAquaFilterSide site={site} active={props.active} />;
    } else if (props.site && !props.filter) {
        sideBar = <SidebarSite site={site} active={props.active} />;
    } else if (props.group) {
        sideBar = <SidebarGroup groups={groups} active={props.active} />;
    } else if (props.admin) {
        sideBar = <SidebarAdmin active={props.active} />;
    }

    return (
        <Root scheme={scheme}>
            <CssBaseline />
            <Header className={styles.header}>
                <Toolbar>
                    <SidebarTrigger sidebarId={'primarySidebar'} />
                    <Navbar user={user} groups={groups} />
                </Toolbar>
            </Header>
            {sideBar}
            <Content
                style={{
                    width: `${
                        isMobile ? '95%' : props.active ? '80%' : '100%'
                    }`,
                }}
                className={styles.content}>
                <div style={{ marginLeft: '5%', marginTop: '3%' }}>
                    {isNullOrUndefined(children) ? (
                        <></>
                    ) : (
                        cloneElement(children, {
                            site: site,
                            groups: groups,
                            user: user,
                        })
                    )}
                </div>
            </Content>
        </Root>
    );
}
