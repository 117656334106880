import { getProperRoute } from '@utils/helpers/app.helpers';
import GraphCard from '@views/sites/dashboards/graphs/graph-card/GraphCard';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

export default function VisualizationTab(props) {
    const [indexSelected, setIndexSelected] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        setIndexSelected(props.graph?.type ?? 0);
    }, [props.graph]);

    const cardClicked = (ind) => {
        props.graph.type = ind;
        setIndexSelected(ind);
        props.graphChanged && props.graphChanged(props.graph);
    };

    const isMobile = useMediaQuery({ query: '(max-width: 700px)' });
    const max1300 = useMediaQuery({ query: '(max-width: 1300px)' });
    const max1000 = useMediaQuery({ query: '(max-width: 1000px)' });

    const getCardWidth = () => {
        if (isMobile) return '100%';
        if (max1000) return '50%';
        if (max1300) return '33%';
        return '25';
    };

    return (
        <>
            <div style={{ marginTop: '50px' }}>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        width: '100%',
                    }}>
                    <div
                        style={{
                            width: getCardWidth(),
                            marginBottom: '10px',
                        }}>
                        <GraphCard
                            selected={indexSelected === 0}
                            title={t('pages_addgraph_visualizationtab_line')}
                            chart={getProperRoute('line-chart.png')}
                            index={0}
                            onClicked={(ind) => cardClicked(ind)}
                        />
                    </div>
                    <div
                        style={{
                            width: getCardWidth(),
                            marginBottom: '10px',
                        }}>
                        <GraphCard
                            selected={indexSelected === 1}
                            title={t('pages_addgraph_visualizationtab_bar')}
                            chart={getProperRoute('bar-chart.png')}
                            index={1}
                            onClicked={(ind) => cardClicked(ind)}
                        />
                    </div>
                    <div
                        style={{
                            width: getCardWidth(),
                            marginBottom: '10px',
                        }}>
                        <GraphCard
                            selected={indexSelected === 2}
                            title={t('pages_addgraph_visualizationtab_hbar')}
                            chart={getProperRoute('bar-chart.png')}
                            index={2}
                            onClicked={(ind) => cardClicked(ind)}
                        />
                    </div>
                    <div
                        style={{
                            width: getCardWidth(),
                            marginBottom: '10px',
                        }}>
                        <GraphCard
                            selected={indexSelected === 5}
                            title={'Metric'}
                            chart={getProperRoute('gauge.png')}
                            index={5}
                            onClicked={(ind) => cardClicked(ind)}
                        />
                    </div>
                    <div
                        style={{ width: getCardWidth(), marginBottom: '10px' }}>
                        <GraphCard
                            selected={indexSelected === 6}
                            title={t('pages_addgraph_visualizationtab_table')}
                            chart={getProperRoute('table.png')}
                            index={6}
                            onClicked={(ind) => cardClicked(ind)}
                        />
                    </div>
                    {/* <div style={{ width: isMobile ? '100%' : max1000 ? '50%' : max1300 ? '33%' : '25%', marginBottom: '10px' }}>
                        <GraphCard selected={indexSelected === 4} title={t('pages_addgraph_visualizationtab_box')} chart={getProperRoute("box-plot.png")} index={4} onClicked={(ind) => cardClicked(ind)} />
                    </div>
                    <div style={{ width: isMobile ? '100%' : max1000 ? '50%' : max1300 ? '33%' : '25%', marginBottom: '10px' }}>
                        <GraphCard selected={indexSelected === 6} title={t('pages_addgraph_visualizationtab_table')} chart={getProperRoute("table.png")} index={6} onClicked={(ind) => cardClicked(ind)} />
                    </div> */}
                </div>
            </div>
        </>
    );
}
