import { MeasurementPointApi } from '@aurum/nucleus-client-api';
import { MultipleSelect, SingleSelect, TextEdit } from '@components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function SettingsTab(props) {
    const [mps, setMps] = useState([]);
    const { id } = useParams();

    const { t } = useTranslation();

    useEffect(() => {
        new MeasurementPointApi().measurementPointGetMeasurementPoints(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setMps(data);
                }
            }
        );
    }, []);

    const nameChanged = (name) => {
        props.graph.name = name;
        props.graphChanged && props.graphChanged(props.graph);
    };

    const typeChanged = (type) => {
        props.graph.measurement = type;
        props.graphChanged && props.graphChanged(props.graph);
    };

    const mpsChanged = (val) => {
        props.graph.measurementPoints = [];
        val?.forEach((i) => {
            props.graph.measurementPoints.push({ id: i });
        });
        props.graphChanged && props.graphChanged(props.graph);
    };

    const paramsChanged = (val) => {
        props.graph.configOptions.parameters = [];
        val?.forEach((i) => {
            props.graph.configOptions.parameters.push(i);
        });
        props.graphChanged && props.graphChanged(props.graph);
    };

    const mpsData = [];
    mps?.forEach((mp) => {
        mpsData.push({ value: mp.id, description: mp.description });
    });
    if (
        mpsData.length === 0 &&
        props.graph?.measurementPoints?.map((mp) => mp.id).length > 0
    ) {
        return <></>;
    }

    const parametersData = [
        { value: 'AIR_INPUT', description: 'AIR_INPUT' },
        { value: 'ERROR_CODE', description: 'ERROR_CODE' },
        { value: 'FILTER_ACTIVE', description: 'FILTER_ACTIVE' },
        { value: 'FLOW_F1', description: 'FLOW_F1' },
        { value: 'FLOW_F2', description: 'FLOW_F2' },
        { value: 'FLOW_F3', description: 'FLOW_F3' },
        { value: 'FLOW_F4', description: 'FLOW_F4' },
        { value: 'INLET_PRES', description: 'INLET_PRES' },
        { value: 'PERMEAT_PRES', description: 'PERMEAT_PRES' },
        { value: 'PROG_STEP', description: 'PROG_STEP' },
        { value: 'TEMP_F1', description: 'TEMP_F1' },
        { value: 'TEMP_F2', description: 'TEMP_F2' },
        { value: 'TEMP_F3', description: 'TEMP_F3' },
        { value: 'TEMP_F4', description: 'TEMP_F4' },
    ];

    let measurementsObj;
    if (props.graph?.measurement === 'filter') {
        measurementsObj = (
            <MultipleSelect
                title="Parameters"
                width="40%"
                onChange={paramsChanged}
                value={props.graph?.parameters}
                data={parametersData}
            />
        );
    } else {
        measurementsObj = (
            <MultipleSelect
                title={t('pages_devices_devicepage_mp')}
                width="40%"
                onChange={mpsChanged}
                value={props.graph?.measurementPoints?.map((mp) => mp.id)}
                data={mpsData}
            />
        );
    }

    return (
        <>
            <div style={{ marginTop: '50px' }}>
                <TextEdit
                    width="40%"
                    onChange={nameChanged}
                    label={t('pages_addgraph_settingstab_name')}
                    placeholder={t('pages_addgraph_settingstab_placeholder')}
                    value={props.graph?.name ?? ''}
                />
            </div>
            {props.secured ? (
                <></>
            ) : (
                <>
                    <div style={{ marginTop: '25px' }}>
                        <SingleSelect
                            title={t('pages_addgraph_settingstab_type')}
                            width="40%"
                            onChange={typeChanged}
                            value={props.graph?.measurement ?? 'temperature'}
                            data={[
                                {
                                    value: 'temperature',
                                    description: t(
                                        'pages_addgraph_settingstab_temperature'
                                    ),
                                },
                                {
                                    value: 'chloride',
                                    description: t(
                                        'pages_addgraph_settingstab_chlorine'
                                    ),
                                },
                                {
                                    value: 'vdd',
                                    description: t(
                                        'pages_addgraph_settingstab_battery'
                                    ),
                                },
                                {
                                    value: 'flowrate',
                                    description: t(
                                        'pages_addgraph_settingstab_flowrate'
                                    ),
                                },
                                {
                                    value: 'digitalInput',
                                    description: t(
                                        'pages_addgraph_settingstab_digitalInput'
                                    ),
                                },
                                {
                                    value: 'filter',
                                    description: 'Filter',
                                },
                            ]}
                        />
                    </div>
                    <div style={{ marginTop: '25px' }}>{measurementsObj}</div>
                </>
            )}
        </>
    );
}
