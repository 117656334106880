import { FilterApi } from '@aurum/nucleus-client-api';
import DashboardApi from '@aurum/nucleus-client-api/dist/api/DashboardApi';
import { AquaFilterGraphContainer } from '@components';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { useWindowSize } from '@utils/hooks/useWindowSize';
import React, { useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

const Wrapper = React.forwardRef((props, ref) => {
    let newChildren = React.Children.map(props.children, function (child) {
        return React.cloneElement(child, { forceRender: props.forceRender });
    });
    return (
        <div ref={ref} {...props}>
            {newChildren}
        </div>
    );
});

export function AquaFilterAppDashboard(props) {
    const [width] = useWindowSize();
    const [graphData, setGraphData] = useState([]);
    const [forceRender, setForceRender] = useState(0);
    const [lastRefresh, setLastRefresh] = useState(-1);

    const ref = React.createRef();
    const { groupId, id, dbId } = useParams();

    const onLayoutChange = (layout) => {
        saveLayout(layout);
    };

    useEffect(() => {
        if (props.refreshCounter === lastRefresh) {
            return;
        }
        setLastRefresh(props.refreshCounter);

        let from = props.from;
        let to = props.to;
        from.setMilliseconds(0);
        to.setMilliseconds(0);

        new FilterApi().filterGetFilterValues(
            parseInt(id, 10),
            {
                windowPeriod: props.windowPeriod,
                startTimestamp: from,
                endTimestamp: to,
            },
            function (_, data, response) {
                fetchingProgress(-1);
                setGraphData(data);
            }
        );
    }, [
        props.refreshCounter,
        props.from,
        props.to,
        props.windowPeriod,
        props.mps,
    ]);

    const fetchingProgress = (counter, total) => {
        props.fetchingProgress && props.fetchingProgress(counter, total);
    };

    const saveLayout = (value) => {
        if (!isNullOrUndefined(props.dashboard)) {
            if (isNullOrUndefined(props.dashboard.configOptions)) {
                props.dashboard.configOptions = {};
            }

            let resized = false;
            let moved = false;
            props.dashboard.configOptions.layout?.forEach((l, ind) => {
                if (l.h !== value[ind].h || l.w !== value[ind].w) {
                    resized = true;
                }
                if (l.x !== value[ind].x || l.y !== value[ind].y) {
                    moved = true;
                }
            });
            props.dashboard.configOptions.layout = value;
            props.dashboard.configOptions.layout?.forEach((l) => {
                if (l.h === 1 && l.w === 1) {
                    l.h = 12;
                    l.w = 12;
                }
            });
            if (resized) {
                setForceRender(forceRender + 1);
            }
            if (moved || resized) {
                new DashboardApi().dashboardUpdateDashboard(
                    parseInt(id, 10),
                    parseInt(dbId, 10),
                    props.dashboard,
                    function (_, data, response) {}
                );
            }
        }
    };

    let index = 0;
    const graphs = [];
    props.dashboard?.graphs?.forEach((graph) => {
        graphs.push(
            <Wrapper ref={ref} key={`${++index}`} forceRender={forceRender}>
                <AquaFilterGraphContainer
                    user={props.user}
                    data={graphData?.filter((g) =>
                        graph.configOptions?.parameters?.includes(g.measurement)
                    )}
                    protected={props.dashboard.configOptions?.secured}
                    graph={graph}
                    groups={props.groups}
                />
            </Wrapper>
        );
    });

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return isNullOrUndefined(props.dashboard) ? (
        <></>
    ) : (
        <GridLayout
            className="layout"
            onResizeStop={onLayoutChange}
            onDragStop={onLayoutChange}
            layout={props.dashboard?.configOptions?.layout}
            minH={275}
            minW={275}
            cols={12}
            rowHeight={30}
            width={isMobile ? width * 0.8 + 30 : width * 0.8}
            draggableHandle=".DraggableDashboard">
            {graphs}
        </GridLayout>
    );
}
