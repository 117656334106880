import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';

const useStyles = makeStyles(() => ({
    link: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&:hover': {
            color: 'gray',
        },
    },
    linkableSpan: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    linkableIcon: {
        cursor: 'pointer',
        width: '16px',
        height: '16px',
        '&:hover': {
            color: 'black',
        },
    },
}));

export function CustomBreadcrumbs(props) {
    const styles = useStyles();

    const links = [];
    props.breadcrumbs?.forEach((element, idx) => {
        // last element is not a link, but a plain text
        if (!isNullOrUndefined(element.onClick)) {
            links.push(
                <span
                    onClick={() => element.onClick(element.id)}
                    className={styles.linkableSpan}
                    color="inherit">
                    {' '}
                    {element.icon} {element.text}{' '}
                </span>
            );
        } else {
            if (idx === props.breadcrumbs.length - 1) {
                links.push(
                    <Typography color="textPrimary">
                        {element.icon} {element.text}
                    </Typography>
                );
            } else {
                links.push(
                    <Link
                        className={styles.link}
                        color="inherit"
                        href={element.link}>
                        {' '}
                        {element.icon} {element.text}{' '}
                    </Link>
                );
            }
        }
    });
    if (props.addmore) {
        links.push(
            <DoubleArrowIcon
                className={styles.linkableIcon}
                onClick={props.onAddClick && props.onAddClick()}
            />
        );
    }
    const isTablet = useMediaQuery({ query: '(max-width: 850px)' });

    return (
        <div
            data-cy={props.datacy}
            style={{ width: 'inherit', justifySelf: 'flex-start' }}>
            <Breadcrumbs
                maxItems={isTablet ? 2 : 20}
                separator={<NavigateNextIcon fontSize="small" />}
                arial-label="Breadcrumb">
                {links}
            </Breadcrumbs>
        </div>
    );
}
