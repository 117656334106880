import { GraphApi } from '@aurum/nucleus-client-api';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ImageIcon from '@mui/icons-material/Image';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getProperRoute, isNullOrUndefined } from '@utils/helpers/app.helpers';
import { MenuDropdown } from '@components';

import './graphstyles.css';

export function GraphContainerHeader(props) {
    const { groupId, id, dbId } = useParams();

    const { t } = useTranslation();

    const onRemoveGraph = () => {
        swal({
            title: t('swal_sure'),
            text: t('swal_cannot_recover_resource'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult && !isNullOrUndefined(props.graph)) {
                new GraphApi().graphDeleteGraph(
                    parseInt(id, 10),
                    props.graph.id,
                    function (_, data, response) {
                        if (response.ok) {
                            window.location.reload();
                        }
                    }
                );
            }
        });
    };

    const triggerShowLegend = () => {
        props.onTriggerShowLegend && props.onTriggerShowLegend();
    };

    const triggerDownloadPng = (type) => {
        props.onDownloadPng && props.onDownloadPng(type);
    };

    const triggerDownloadCsv = () => {
        props.onDownloadCsv && props.onDownloadCsv(props.data);
    };

    const options = [
        <div clickable={false}>
            {' '}
            <FileDownloadIcon
                style={{
                    width: '24px',
                    height: '24px',
                    color: 'black',
                    marginRight: '20px',
                }}
            />{' '}
            Export as
            <MenuDropdown
                hover={true}
                options={[
                    <div onClick={() => triggerDownloadPng('png')}>
                        <ImageIcon
                            style={{
                                width: '24px',
                                height: '24px',
                                color: 'black',
                                marginRight: '20px',
                            }}
                        />{' '}
                        PNG{' '}
                    </div>,
                    <div onClick={() => triggerDownloadPng('svg')}>
                        <ImageIcon
                            style={{
                                width: '24px',
                                height: '24px',
                                color: 'black',
                                marginRight: '20px',
                            }}
                        />{' '}
                        SVG{' '}
                    </div>,
                    <div onClick={() => triggerDownloadCsv()}>
                        <DescriptionIcon
                            style={{
                                width: '24px',
                                height: '24px',
                                color: 'black',
                                marginRight: '20px',
                            }}
                        />{' '}
                        CSV{' '}
                    </div>,
                ]}
            />
        </div>,
        <div onClick={() => triggerShowLegend()}>
            {' '}
            <LegendToggleIcon
                style={{
                    width: '24px',
                    height: '24px',
                    color: 'black',
                    marginRight: '20px',
                }}
            />{' '}
            Show Legend
        </div>,
        // <div onClick={() => window.location.href = getProperRoute(`groups/${groupId}/sites/${id}/dashboards/${dbId}/graphs/${props.graph?.id}/view`)}> <VisibilityOutlinedIcon style={{ width: '24px', height: '24px', color: 'black', marginRight: '20px' }} /> {t('common_graphcontainer_view')}</ div>,
        <div
            onClick={() =>
                (window.location.href = getProperRoute(
                    `groups/${groupId}/sites/${id}/dashboards/${dbId}/graphs/edit/${props.graph?.id}`
                ))
            }>
            {' '}
            <EditOutlinedIcon
                style={{
                    width: '24px',
                    height: '24px',
                    color: 'black',
                    marginRight: '20px',
                }}
            />{' '}
            {t('common_graphcontainer_edit')}
        </div>,
        <div clickable={false}>
            {' '}
            <MergeTypeIcon
                style={{
                    width: '24px',
                    height: '24px',
                    color: 'black',
                    marginRight: '20px',
                }}
            />{' '}
            {t('common_graphcontainer_aggregation')}
            <MenuDropdown
                hover={true}
                options={[
                    <div>
                        <BubbleChartIcon
                            style={{
                                width: '24px',
                                height: '24px',
                                color: 'black',
                                marginRight: '20px',
                            }}
                        />{' '}
                        {t('common_graphcontainer_average')}
                    </div>,
                    <div>
                        <BubbleChartIcon
                            style={{
                                width: '24px',
                                height: '24px',
                                color: 'black',
                                marginRight: '20px',
                            }}
                        />{' '}
                        {t('common_graphcontainer_median')}
                    </div>,
                    <div>
                        <BubbleChartIcon
                            style={{
                                width: '24px',
                                height: '24px',
                                color: 'black',
                                marginRight: '20px',
                            }}
                        />{' '}
                        {t('common_graphcontainer_max')}
                    </div>,
                    <div>
                        <BubbleChartIcon
                            style={{
                                width: '24px',
                                height: '24px',
                                color: 'black',
                                marginRight: '20px',
                            }}
                        />{' '}
                        {t('common_graphcontainer_min')}
                    </div>,
                ]}
            />
        </div>,
        <div onClick={() => onRemoveGraph()}>
            {' '}
            <DeleteOutlineOutlinedIcon
                style={{
                    width: '24px',
                    height: '24px',
                    color: 'black',
                    marginRight: '20px',
                }}
            />{' '}
            {t('common_graphcontainer_remove')}
        </div>,
    ];

    let unit = undefined;
    if (props.graph?.measurement === 'temperature') {
        unit = '°C';
    } else if (
        props.graph?.measurement === 'chloride' ||
        props.graph?.measurement === 'chlorine'
    ) {
        unit = 'ppm';
    } else if (props.graph?.measurement === 'flowrate') {
        unit = 'l/m';
    } else if (props.graph?.measurement === 'vdd') {
        unit = 'mV';
    }

    return (
        <CardHeader
            className="DraggableDashboard"
            title={
                <div className="row" style={{ justifyContent: 'center' }}>
                    <Typography>{`${props.graph?.name ?? 'Default'} ${
                        isNullOrUndefined(unit) ? '' : `[${unit}]`
                    }`}</Typography>
                    {props.view ? (
                        <></>
                    ) : (
                        <MenuDropdown hover={false} options={options} />
                    )}
                </div>
            }
        />
    );
}
